import { useEffect } from "react";
import { MultiSelect } from "primereact/multiselect";

import * as S from "./styled";

export const LazyLoadSelect = ({
  options,
  className,
  selectedOptions = [],
  setSelectedOptions,
  placeholder,
  description,
  label,
  panelHeaderTemplate,
  resetFilterOnHide,
  selectedIndex,
  subjectValue,
  ...props
}) => {
  const handleSelectionChange = (e) => {
    e.originalEvent.stopPropagation();
    setSelectedOptions(e.value);
  };

  const highlightFilteredItems = () => {
    document.querySelectorAll(".p-multiselect-item").forEach((item) => {
      if (item.querySelector("span").textContent === subjectValue) {
        item.classList.add("filteredItem");
      }
    });
  };

  useEffect(() => {
    const handleScroll = (e) => {
      const target = e.target;

      if (target instanceof Element && !target.closest(".p-multiselect-panel")) {
        e.stopPropagation();
      }
    };

    document.addEventListener("scroll", handleScroll, true);
    return () => {
      document.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  useEffect(() => {
    highlightFilteredItems();

    const observer = new MutationObserver(() => {
      highlightFilteredItems();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [options]);

  return (
    <S.Container optionslength={options.length} selectedindex={selectedIndex + 1}>
      {label && (
        <S.Label>
          <S.Span>
            {label}&nbsp;
            <S.Description>{description ? `(${description})` : null}</S.Description>
          </S.Span>
        </S.Label>
      )}
      <S.Span className="relative p-float-label w-full md:w-14rem">
        {selectedOptions?.length ? <S.FilterCount>{selectedOptions.length}</S.FilterCount> : null}
        <MultiSelect
          filter
          appendTo="self"
          className={`${className} multi-select w-full md:w-14rem`}
          inputId="dd-city"
          maxSelectedLabels={3}
          optionLabel="value"
          options={options}
          panelHeaderTemplate={panelHeaderTemplate}
          placeholder={placeholder}
          resetFilterOnHide={resetFilterOnHide}
          value={Array.isArray(selectedOptions) ? selectedOptions : []}
          virtualScrollerOptions={options.length ? { itemSize: 43 } : null}
          onChange={handleSelectionChange}
          {...props}
        />
      </S.Span>
    </S.Container>
  );
};
