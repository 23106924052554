import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { singleArchivedSelectors } from "store/admin-slices/single-archived-slice/selectors";
import { DOCUMENT_TYPE } from "utils/constants";
import { getCountDetails } from "utils/helpers";

import { Document } from "./Document";
import * as S from "./styled";

export const Documents = ({ leads }) => {
  const { singleArchived } = useSelector(singleArchivedSelectors);

  const [email, setEmail] = useState("");
  const [agentCode, setAgentCode] = useState("");

  const { selectedPropertyId } = useParams();

  const [searchParams] = useSearchParams();

  const handleEmailChange = (value) => setEmail(value);

  const handleAgentCodeChange = (value) => setAgentCode(value);

  useEffect(() => {
    const property = singleArchived.properties.find((prop) => prop.id === Number(selectedPropertyId));

    handleEmailChange(getCountDetails(property?.county)?.email);
    handleAgentCodeChange(getCountDetails(property?.county)?.agent);
  }, [selectedPropertyId]);

  useEffect(() => {
    if (searchParams.get("is_dev")) {
      setEmail("");
    }
  }, [searchParams]);

  return (
    <S.Container>
      <S.ContentWrapper>
        <S.Header>Documents</S.Header>
        <S.Content>
          <S.DocumentsWrapper>
            <S.DocumentsContent>
              {singleArchived.documents
                ?.filter((document) => document.type === DOCUMENT_TYPE.AOA)
                ?.map((doc) => (
                  <Document key={doc.id} agentCode={agentCode} doc={doc} email={email} leads={leads} />
                ))}
            </S.DocumentsContent>
          </S.DocumentsWrapper>
          {!leads && (
            <S.SettingsWrapper>
              <S.SettingsContent>
                <S.SettingName>Service Agreement</S.SettingName>
                <S.Link
                  href={
                    singleArchived.documents?.filter((document) => document.type === DOCUMENT_TYPE.SA)?.[0]?.sa
                      ?.preview_url
                  }
                  target="_blank"
                >
                  View Service Agreement
                </S.Link>
              </S.SettingsContent>
            </S.SettingsWrapper>
          )}
        </S.Content>
      </S.ContentWrapper>
    </S.Container>
  );
};
