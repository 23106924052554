import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const Title = styled.h3`
  font-size: 24px;
  color: ${COLORS.deepDark};
  margin: 10px;
`;

export const PaginatorWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-content: center;
  justify-content: center;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const Paragraph = styled.p``;
