import { BREAKPOINTS } from "assets/styles/breakpoints";
import styled from "styled-components/macro";

export const Description = styled.div`
  color: black;
  position: absolute;
  z-index: 2;
  top: 30px;
  display: flex;
  width: 80px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media screen and (${BREAKPOINTS.md}) {
    width: 90px;
    font-size: 14px;
  }

  @media screen and (${BREAKPOINTS.xl}) {
    width: 100px;
    font-size: 16px;
  }
`;

export const StepperWrapper = styled.div`
  padding-block: 1rem;
  width: ${({ width }) => width};

  &.showDescription {
    padding-inline: 2rem;

    @media screen and (${BREAKPOINTS.md}) {
      padding-inline: 3rem;
    }
  }

  .last-child {
    right: -2.5rem;
  }

  .first-child {
    left: -2.5rem;
  }
`;

export const TooltipContent = styled.div`
  width: 200px;
  cursor: pointer;
`;

export const Div = styled.div`
  display: none;
`;
