import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import * as S from "./styled";

export const Table = ({ tableData = [], frozenValue = [], onSelectionChange, className, ...rest }) => {
  const handleSelectionChange = (e) => {
    e.originalEvent.stopPropagation();
    onSelectionChange?.(e);
  };

  return (
    <S.Container>
      <DataTable {...rest} frozenValue={frozenValue} onSelectionChange={handleSelectionChange}>
        {tableData?.map((data, index) => (
          <Column
            key={data.header + index}
            bodyClassName="column-body"
            className="column"
            headerClassName="column-header"
            sortable={false}
            {...data}
          />
        ))}
      </DataTable>
    </S.Container>
  );
};
