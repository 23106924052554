import { COLORS } from "assets/styles/colors";
import styled from "styled-components/macro";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100vh;
  background-color: ${COLORS.overlay_200};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  position: relative;
  padding: 20px;
  min-width: 400px;
  min-height: 200px;
  background-color: ${COLORS.white};
`;

export const IconBlock = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

export const AutoSaveBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  padding-block: 10px;

  input:checked {
    background-image: none;
    border: none;
  }

  input:focus {
    --tw-ring-color: none;
  }
`;

export const Block = styled.div`
  width: 80%;
  margin: auto;

  button {
    width: 100%;
  }
`;
