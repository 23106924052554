import { Link } from "react-router-dom";
import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

export const Container = styled.div`
  background-color: ${COLORS.primary};
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;

  @media screen and (${BREAKPOINTS.md}) {
    height: 100vh;
    width: 312px;
    position: fixed;
    padding-block: 16px;
    top: 0;
    left: 0;
    z-index: unset;
  }
  @media screen and (${BREAKPOINTS.lg}) {
    padding-block: 32px;
  }
`;

export const NavContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  @media screen and (${BREAKPOINTS.md}) {
    justify-content: center;
    margin-bottom: 72px;
  }
`;

export const AppLogo = styled.img`
  width: 168px;
  height: 38px;
  object-fit: contain;

  @media screen and (${BREAKPOINTS.md}) {
    width: 168px;
    height: 38px;
  }
`;

export const NavLink = styled(Link)`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  color: ${COLORS.white};
  font-weight: 500;
  font-size: 16px;
  gap: 8px;
  line-height: 24px;

  svg {
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }

  &.logout {
    svg {
      width: 20px;
      path {
        stroke: ${COLORS.white};
        stroke-width: 1.2;
      }
    }
  }

  @media screen and (${BREAKPOINTS.md}) {
    display: flex;
    align-items: center;
    color: ${COLORS.white};
    font-weight: 500;
    font-size: 18px;
    gap: 8px;
    line-height: 24px;
  }

  @media screen and (${BREAKPOINTS.lg}) {
    font-size: 18px;
  }
`;

export const NotificationIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  gap: 8px;
  line-height: 24px;
  cursor: pointer;
  margin-left: 10px;

  svg {
    margin-right: 6px;
    fill: ${COLORS.white};
    width: 24px;
    height: 24px;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionsContent = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
  height: 100%;

  &.open {
    max-height: 100vh;
    transition: max-height 0.5s ease-in;
    overflow: hidden;
  }

  @media screen and (${BREAKPOINTS.md}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100vh;
    padding-inline: 24px;
  }
`;

export const NotificationCount = styled.div`
  position: absolute;
  top: 0;
  left: 12px;
  background-color: ${COLORS.red};
  color: ${COLORS.white};
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;

  @media screen and (${BREAKPOINTS.md}) {
    top: 0;
    right: -5px;
  }
`;

export const BurgerMenuIcon = styled.div`
  width: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 24px;
  display: block;
  position: relative;
  bottom: 0;

  svg path {
    stroke: white;
  }
  svg {
    width: 20px;
    height: 20px;
    position: absolute;
  }

  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${COLORS.red};
    position: absolute;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    left: 12px;
  }

  @media screen and (${BREAKPOINTS.md}) {
    display: none;
  }
`;

export const BottomOptions = styled.div`
  padding: 16px;

  @media screen and (${BREAKPOINTS.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: unset;
  }

  @media screen and (${BREAKPOINTS.lg}) {
    gap: 24px;
  }
`;

export const MainOptions = styled.div`
  padding: 16px;

  @media screen and (${BREAKPOINTS.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: unset;
  }

  @media screen and (${BREAKPOINTS.lg}) {
    gap: 6px;
  }
`;

export const NavLinkWrapper = styled.div`
  padding: 6px 16px;

  &.active {
    background-color: ${COLORS.white_170};
    border-radius: 4px;
  }

  @media screen and (${BREAKPOINTS.md}) {
    width: 100%;
    padding: 6px 16px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    svg {
      width: 24px;
      height: 24px;
    }

    &.active {
      background-color: ${COLORS.white_170};
      width: 100%;
      border-radius: 4px;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (${BREAKPOINTS.md}) {
    flex-direction: row;
  }
`;
