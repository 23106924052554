import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

export const InvoicesScrollBlock = styled.div`
  width: 100%;
`;

export const InvoicesLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const LoaderBox = styled.div`
  display: flex;
  justify-content: center;
  min-height: 80vh;
`;

export const InvoiceWidgetWrapper = styled.div``;

export const InvoiceLink = styled.div`
  display: block;
  width: 100%;
`;

export const InvoiceText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 18px;
  }
`;

export const InvoicesStatus = styled.a`
  color: ${({ status }) => (status === "open" ? COLORS.green : COLORS.overlay_300)};
`;

export const InvoiceBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const InvoiceContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 10px;
  background: ${COLORS.overlay_060};
  color: ${COLORS.deepDark};
  border-radius: 10px;
  cursor: pointer;
  padding: 16px;

  @media screen and (${BREAKPOINTS.md}) {
    padding: 40px;
    column-gap: 20px;

    &:hover {
      box-shadow: 0 5px 14px 0 ${COLORS.green};
    }
  }

  @media screen and (${BREAKPOINTS.lg}) {
    padding: 24px;
    column-gap: 20px;
  }
`;

export const InvoicesIconBlock = styled.div`
  background-color: ${COLORS.green};
  border-radius: 5px;
  padding: 2px;
  width: 26px;
  height: 26px;

  svg {
    width: 22px;
    height: 22px;
  }
`;

export const InvoiceDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  max-height: ${({ isVisible }) => (isVisible ? "500px" : "0")};
  transition: max-height 0.3s ease;
`;

export const InvoiceDetailText = styled.div`
  display: flex;
  column-gap: 6px;
  font-size: 14px;
  margin-bottom: 8px;
`;

export const InvoiceItems = styled.div`
  margin-top: 16px;
`;

export const InvoiceItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 8px 8px 0;
  border-bottom: 1px solid ${COLORS.overlay_30};
`;

export const ItemDescription = styled.div`
  font-size: 14px;
`;

export const ItemAmount = styled.div`
  font-size: 14px;
`;

export const InvoicesMessageContent = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: ${COLORS.white};
  padding: 6px 6px;
  color: ${COLORS.deepDark};
  border-radius: 10px;
  border: 1px solid ${COLORS.green};

  @media screen and (${BREAKPOINTS.sm}) {
    padding: 14px;
  }

  @media screen and (${BREAKPOINTS.md}) {
    height: 20px;
    padding: 34px 40px;
  }

  @media screen and (${BREAKPOINTS.lg}) {
    height: 60px;
    padding: 34px 24px;
  }

  @media screen and (${BREAKPOINTS.xl}) {
    height: 80px;
    padding: 40px;
  }
`;

export const InvoicesText = styled.div`
  font-size: 16px;
  color: ${COLORS.deepDark};
`;

export const Value = styled.p`
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  &.status {
    color: ${({ status }) => (status === "open" ? COLORS.green : COLORS.overlay_300)};
  }
`;

export const InvoiceTotal = styled.p`
  font-size: 14px;
`;

export const InvoiceWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 6px;
`;
