import { NavLink as RouterNavLink } from "react-router-dom";
import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  position: absolute;
  background-color: ${COLORS.grey};
  height: 100vh;

  &:hover {
    .menu-title {
      max-width: 100vw;
      overflow: auto;
      transition: max-width 0.7s ease-in;
      overflow-x: hidden;
    }

    .link {
      transition: ease 0.7s;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  height: 100vh;
  padding: 50px 15px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  .selected-link {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
    svg {
      fill: ${COLORS.white};
    }
  }

  .hover-link {
    &:hover {
      background-color: ${COLORS.darkgrey};
    }
  }
`;

export const NavLink = styled(RouterNavLink)`
  display: flex;
  padding: 10px 20px;
  border-radius: 4px;
  transition: ease 0.7s;
`;

export const MenuContent = styled.ul`
  margin-block: 40px;
`;

export const MenuWrapper = styled.li``;

export const MenuTitle = styled.div`
  max-width: 0;
  transition: max-width 0.7s ease-out;
  overflow: hidden;
  text-indent: 30px;
  white-space: nowrap;
`;

export const Box = styled.div``;

export const Logo = styled.img`
  width: 180px;
  margin: auto;
`;
