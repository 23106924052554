export const ReferralIcon = (props) => (
  <svg height="48" viewBox="0 0 48 48" width="48" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
    <path
      d="M17.9,21.1c1.3,2.6,0.8,5.8-1.5,7.8c-2.7,2.4-6.8,2.1-9.2-0.6s-2.1-6.8,0.6-9.2c1-0.9,2.3-1.4,3.6-1.6"
      fill="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="3"
      {...props}
    ></path>
    <path
      d="M42.5,12.7c-0.3,3.3-3.1,5.8-6.5,5.8c-3.6,0-6.5-2.9-6.5-6.5s2.9-6.5,6.5-6.5c1.6,0,3.1,0.6,4.2,1.5"
      fill="none"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="3"
    ></path>
    <path
      d="M36.4,29c1.6,0.1,3.2,0.9,4.4,2.2c2.3,2.7,2,6.8-0.7,9.2s-6.8,2-9.2-0.7c-1.7-1.9-2-4.6-1-6.8"
      fill="none"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="3"
    ></path>
    <line
      fill="none"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="3"
      x1="17.9"
      x2="30.2"
      y1="21.1"
      y2="14.9"
    ></line>
    <line
      fill="none"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="3"
      x1="18.2"
      x2="29.8"
      y1="27.1"
      y2="32.9"
    ></line>
  </svg>
);
