import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const OwnersSinglePageContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 10px;

  .back-button {
    margin-bottom: 20px;
  }

  // datatable styles
  .column {
    background-color: ${COLORS.white}!important;
    letter-spacing: 0.5px;
    border-bottom: 2px solid ${COLORS.overlay_150} !important;
  }

  .column-body {
    padding: 6px 16px !important;
    text-align: center !important;
    border: none !important;
    background-color: ${COLORS.white};
    height: 50px !important;
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentHeader = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
`;

export const SinglePageContentShadow = styled.div`
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 3px 1px -2px ${COLORS.overlay_200}, 0 2px 2px 0 ${COLORS.overlay_150}, 0 1px 5px 0 ${COLORS.overlay_150};
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-block: 24px;
  gap: 24px;
  width: 100%;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  &.property_notes {
    width: 50%;
  }
`;

export const StepperContainer = styled.div`
  background-color: ${COLORS.grey};
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  color: ${COLORS.deepDark};
  padding-inline: 16px;
  padding-block: 20px 100px;
  align-self: center;
  width: 100%;
  overflow-x: scroll;
`;
