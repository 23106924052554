import { IMAGES } from "assets/images";
import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components/macro";

export const SignInContainer = styled.section`
  min-height: calc(100vh - 90px);
  background-image: url(${IMAGES.home_1});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
`;

export const SignInContent = styled.div`
  background-color: ${COLORS.white};
  padding: 20px 20px 10px;
  border-radius: 10px;

  @media screen and (${BREAKPOINTS.md}) {
    padding: 40px 80px 40px;
    min-width: 700px;
  }
`;

export const StepperWrapper = styled.div`
  margin-bottom: 40px;
`;

export const StepContainer = styled.form``;

export const StepHeader = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 20px;

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 2rem;
  }
`;

export const Description = styled.div`
  text-align: center;
  font-size: 1.25rem;
  margin-top: 40px;

  &.no-margin {
    margin: 0;
  }
`;

export const InputWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`;

export const ButtonWrapper = styled.div`
  padding-block: 40px;
  display: flex;
  justify-content: center;

  .submit-button {
    font-size: 16px;
  }
`;

export const ErrorMessageBlock = styled.div`
  color: ${COLORS.red};
  text-align: center;
  font-size: 1.25rem;
`;
