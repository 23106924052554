import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Can } from "@casl/react";
import { Loader, PrimaryButton } from "common";
import { usePermissions } from "hooks/usePermissions";
import { adminRoutes } from "router/routes";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { singleArchivedSelectors } from "store/admin-slices/single-archived-slice/selectors";
import { singleArchivedActions } from "store/admin-slices/single-archived-slice/slice";
import { isLoading, PERMISSIONS_LIST } from "utils/constants";

import { Invoices } from "components/Invoices";

import { ReferredBy } from "./Referrals/ReferredBy";
import { ReferredTo } from "./Referrals/ReferredTo";
import { TrackerTable } from "./ArchivedTrackerTable";
import { ClientDetails } from "./ClientDetails";
import { Discount } from "./Discount";
import { Documents } from "./Documents";
import { Flags } from "./Flags";
import { Notes } from "./Notes";
import { Properties } from "./Properties";
import * as S from "./styled";
import { UnArchiveArchived } from "./UnArchiveArchived";

export const ArchivedSinglePage = ({ leads }) => {
  const { getSingleArchivedStatus, singleArchived, getReferralsStatus, referrals } =
    useSelector(singleArchivedSelectors);
  const { userInfo } = useSelector(adminSignInSelectors);

  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const ability = usePermissions(userInfo.permissions);

  const isPageLoading =
    isLoading(getSingleArchivedStatus) || !singleArchived || isLoading(getReferralsStatus) || !referrals;

  const goBack = () => {
    if (leads) {
      navigate(`${adminRoutes.prefix}/${adminRoutes.leads}`);
    } else {
      navigate(`${adminRoutes.prefix}/${adminRoutes.archived}`);
    }
  };

  useEffect(() => {
    dispatch(singleArchivedActions.getSingleArchived({ id }));
    dispatch(singleArchivedActions.getReferrals(id));
    dispatch(singleArchivedActions.getReferredBy(id));
    return () => dispatch(singleArchivedActions.resetAllData());
  }, [id]);

  if (isPageLoading) {
    return (
      <S.LoaderWrapper>
        <Loader size={70} />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.OwnersSinglePageContainer>
      <PrimaryButton className="back-button" eventHandler={goBack} label="Back" />
      <Properties leads={leads} />
      <S.Flex>
        <ClientDetails />
        {singleArchived?.documents?.length ? <Documents leads={leads} /> : null}
      </S.Flex>
      {!leads && (
        <>
          <ReferredTo />
          <ReferredBy />
          <Discount />
        </>
      )}
      <S.Flex>
        <Flags />
        {!leads && <Notes />}
      </S.Flex>
      <Can ability={ability} I={PERMISSIONS_LIST.archiveOwner}>
        <S.Flex>
          <UnArchiveArchived />
          <Invoices invoices={singleArchived?.invoices} />
        </S.Flex>
      </Can>

      <Can ability={ability} I={PERMISSIONS_LIST.ownerActionTracker}>
        <TrackerTable />
      </Can>
    </S.OwnersSinglePageContainer>
  );
};
