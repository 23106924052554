import { NavLink } from "react-router-dom";
import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

export const LocationContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${COLORS.primary};
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (${BREAKPOINTS.lg}) {
    padding: 18px;
    gap: 16px;
  }
`;

export const LocationTitleSection = styled.div`
  min-height: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 40%;
`;

export const LocationTitle = styled.div`
  width: 80px;
  height: 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
`;

export const LocationIcon = styled.div`
  width: 24px;
  height: 30px;

  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: black;
      stroke-width: 1.2;
    }
  }
`;

export const LocationSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const LocationInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media screen and (${BREAKPOINTS.lg}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const Label = styled.p`
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  font-weight: 400;
  width: 40%;
`;

export const Values = styled.b`
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  font-weight: 600;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (${BREAKPOINTS.lg}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const Link = styled.a`
  width: fit-content;
  padding: 4px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border: 1px solid ${COLORS.primary};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: black;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    background-color: ${COLORS.green};
    color: white;
  }
`;

export const AddressLink = styled(NavLink)`
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;

  @media screen and (${BREAKPOINTS.xl}) {
    font-size: 16px;
  }

  svg {
    fill: ${COLORS.green};
    width: 18px;
    height: 18px;
  }

  &:hover {
    font-weight: 600;
  }
`;

export const LocationAddress = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (${BREAKPOINTS.md}) {
    flex-direction: row;
  }
`;
