import { Fragment } from "react";
import { Link, Page, Text, View } from "@react-pdf/renderer";
import { COLORS } from "assets/styles/colors";
import { TEXAS_PTP_LINK } from "utils/constants";
import { v4 as uuid } from "uuid";

import { styles } from "./AoAStyles";
import { CheckboxContainer } from "./CheckboxContainer";
import { StepHeader } from "./StepHeader";
import { UnderlineView } from "./UnderlineView";

export const AoAPage = ({ properties, owner, firstProp }) => {
  properties = Array.from({ length: 4 }, (_, index) => properties[index]);

  return (
    <Page size="A4" style={styles.document}>
      <View style={styles.docHeader}>
        <Text style={styles.docHeaderText}>Texas Comptroller of Public Accounts</Text>
        <Text style={[styles.docHeaderText, styles.docHeaderText2]}>Form 50-162</Text>
      </View>
      <Text style={styles.docTitle}>Appointment of Agent for Property Tax Matters</Text>
      <Text style={styles.description}>
        This form is for use by a property owner in designating a lessee or other person to act as the owner’s agent in
        property tax matters. You should read all applicable law and rules carefully, including Tax Code Section 1.111
        and Comptroller Rule 9.3044. This designation will not take effect until filed with the appropriate appraisal
        district. Once effective, this designation will be in effect until the earlier of (1) the date of a written
        revocation filed with the appraisal district by the owner or the owner’s designated agent, or (2) the expiration
        date, if any, designated below.
      </Text>
      <Text style={[styles.description, { marginVertical: 8, fontWeight: "bold" }]}>
        In some cases, you may want to contact your appraisal district or other local taxing units for free information
        and/or forms concerning your case before designating an agent.
      </Text>
      <View style={styles.flexRow}>
        <UnderlineView
          flexGrow
          label="Appraisal District Name"
          value={properties[0]?.county?.toLowerCase() === "fortbend" ? "Fort Bend" : properties[0]?.county}
        />
        <UnderlineView label="Date Received (appraisal district use only)" />
      </View>
      <StepHeader label="STEP 1: Owner’s Name and Address:" />
      <View style={styles.flexRow}>
        <UnderlineView flexGrow label="Name" value={firstProp?.county_owner_name} />
        <UnderlineView label="Telephone Number (include area code)" value={owner?.phone_number} />
      </View>
      <View style={styles.flexRow}>
        <UnderlineView flexGrow label="Address" value={firstProp?.county_owner_address || firstProp?.searchAddress} />
      </View>
      <View style={styles.flexRow}>
        <UnderlineView
          flexGrow
          label="City, State, Zip Code"
          value={`${firstProp?.county_owner_city || ""} ${firstProp?.county_owner_state || ""} ${
            firstProp?.county_owner_zip || ""
          }`}
        />
      </View>
      <StepHeader
        label="STEP 2: Identify the Property for Which Authority is Granted. Identify all property for which you are granting the agent authority
          and, unless granting authority for all property listed for you, provide at least one of the property identi!ers listed below (appraisal
          district account number, physical or situs address, or legal description). A chief appraiser may, if necessary to identify the property,
          request additional information. In lieu of listing property below, you may attach a list of all property to which this appointment
          applies, denoting the total number of additional pages attached in the lower right-hand corner below"
      />
      <CheckboxContainer
        labels={[
          { content: "all property listed for me at the above address" },
          { content: "the property(ies) listed below:", checked: true },
        ]}
      />
      {properties.map((prop) => (
        <Fragment key={uuid()}>
          <View style={styles.flexRow}>
            <UnderlineView label="Appraisal District Account Number" value={prop?.account_number} />
            <UnderlineView flexGrow label="Physical or Situs Address of Property" value={prop?.street_address} />
          </View>
          <View style={styles.flexRow}>
            <UnderlineView flexGrow label="Legal Description" />
          </View>
        </Fragment>
      ))}
      <View style={{ paddingVertical: 10 }}>
        <Text style={[{ width: "65%" }, styles.description]}>
          If you have additional property for which authority is granted, attach additional sheets providing the
          appraisal district account number, physical or situs address, or legal description for each property.
        </Text>
        <Text style={styles.description}>
          Identify here the number of additional sheets attached:
          .............................................................................................................................................................
        </Text>
      </View>
      <View style={styles.docHeader}>
        <Text style={styles.docHeaderText}></Text>
        <Text style={[styles.docHeaderText, styles.docHeaderText2]}></Text>
      </View>
      <View style={[styles.flexRow, { justifyContent: "space-between" }]}>
        <View style={{ maxWidth: "62%" }}>
          <Text style={[styles.footerText, { color: COLORS.blue }]}>
            The Property Tax Assistance Division at the Texas Comptroller of Public Accounts provides property tax
            information and resources for taxpayers, local taxing entities, appraisal districts and appraisal review
            boards.
          </Text>
        </View>
        <View
          style={[
            {
              alignItems: "flex-end",
              flexDirection: "column",
              display: "flex",
            },
          ]}
        >
          <Text style={[styles.footerText, { color: COLORS.blue }]}>For more information, visit our website:</Text>
          <Link src={TEXAS_PTP_LINK} style={[styles.footerText, { fontSize: 8 }]}>
            comptroller.texas.gov/taxes/property-tax
          </Link>
          <Text style={styles.footerText}>50-162 • 12-16/13</Text>
        </View>
      </View>
    </Page>
  );
};
