import { toast } from "react-toastify";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import html2canvas from "html2canvas";
import * as referralCodes from "referral-codes";

import { COUNTY_DETAILS, LOADING_STATUSES } from "./constants";

dayjs.extend(customParseFormat);

export const setStorage = (key, value) => {
  if (typeof value === "string") {
    localStorage.setItem(key, value);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (err) {
    return localStorage.getItem(key);
  }
};

export const isValidFileType = (files) => {
  if (!files || files.length === 0) {
    return false;
  }
  const validExtensions = ["jpg", "jpeg", "png", "xlsx", "pdf", "csv", "doc", "docx", "txt"];

  for (const file of Array.from(files)) {
    const fileName = file.name.split(".");
    const fileExtension = fileName[fileName.length - 1].toLowerCase();

    if (!validExtensions.includes(fileExtension)) {
      return false;
    }
  }
  return true;
};

export const removeStorage = (key) => localStorage.removeItem(key);

export const isEmpty = (object) => {
  if (Array.isArray(object)) {
    return !object.length;
  }

  if (typeof object === "object" && !Array.isArray(object) && object !== null) {
    return !Object.keys(object).length;
  }

  if (typeof object === "string") {
    return !object.length;
  }

  return true;
};

export const getCountDetails = (details = "") => {
  return COUNTY_DETAILS[details.replace(/\s/g, "").toLowerCase()] || COUNTY_DETAILS.default;
};

export const generateAddressLink = (item = {}) => {
  if (item.county.toLowerCase() === "harris") {
    return getCountDetails(item.county)?.link?.url;
  }

  if (item.county.toLowerCase() === "montgomery" && item.account_number) {
    const accountNumber = item.account_number.startsWith("R") ? item.account_number.substring(1) : item.account_number;
    return getCountDetails(item.county)?.link?.url + accountNumber + getCountDetails(item.county)?.link?.info;
  }

  if (item.county) {
    if (item.account_number) {
      return getCountDetails(item.county)?.link?.url + item.account_number + getCountDetails(item.county)?.link?.info;
    } else {
      return getCountDetails(item.county)?.link?.search_url;
    }
  }
};

export const preloadImage = (src) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = img.onabort = () => {
      reject(src);
    };
    img.src = src;
  });

export const snakeToCamel = (snakeString) => {
  let camelString = "";
  let isSnake = false;
  for (let i = 0; i < snakeString.length; i++) {
    if (snakeString[i] === "_") {
      isSnake = true;
    } else {
      if (isSnake) {
        camelString += snakeString[i].toUpperCase();
        isSnake = false;
      } else {
        camelString += snakeString[i];
      }
    }
  }

  return camelString;
};

export const getPaginationDescription = (paginationData, lastPage) => {
  return `${(paginationData.page - 1) * paginationData.rows + 1} - 
            ${
              paginationData.rows * paginationData.page >= lastPage
                ? lastPage
                : paginationData.rows * paginationData.page
            } of ${lastPage}`;
};

export const downloadFileByLink = (status, href, name = "properties.csv") => {
  if (status === LOADING_STATUSES.succeeded) {
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }
};

export const generateReferralCode = () => {
  return referralCodes.generate({
    length: 7,
    count: 3,
    charset: "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  })[0];
};

export const toggleClassOnRoot = () => document.getElementById("root").classList.toggle("prevent-scroll-desktop");

export const numberToDollar = (value) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  return formatter.format(value);
};

export const toastify = (type, message, className, timeout = 5000) => {
  const toastId = toast[type](message, {
    position: "top-right",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    className: className,
    autoClose: false,
  });

  setTimeout(() => {
    toast.dismiss(toastId);
  }, timeout);
};

export const getFileType = (filename) => filename.split(".").pop().toLowerCase();

export const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "red";
    case "reviewed":
      return "gray";
    case "worked":
      return "green";
    default:
      return "green";
  }
};

export const getImageFromTypedSignature = async ({ typedSignRef, signature, signType }) => {
  const canvasElement = typedSignRef.current;
  const textElementHeight = 60;
  const additionalHeight = 10;
  const totalImageHeight = textElementHeight + additionalHeight;

  const options = {
    height: totalImageHeight,
  };

  let result = signature;

  if (signType === "type") {
    const canvas = await html2canvas(canvasElement, options);
    result = canvas.toDataURL("image/png");
  }

  return result;
};

export function transformToClassicDate(date) {
  if (!date) return "";

  const parsedDate = dayjs(date, "DD-MM-YY");
  const month = parsedDate.date();
  const day = parsedDate.format("MMMM");

  if (!day) return "";

  let suffix = "th";

  if (day % 10 === 1 && day !== 11) suffix = "st";
  else if (day % 10 === 2 && day !== 12) suffix = "nd";
  else if (day % 10 === 3 && day !== 13) suffix = "rd";

  if (month === "Invalid Date") return "";

  return `${day} ${month}${suffix}`;
}
