import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stepper from "common/Stepper";
import { useScrollTo } from "hooks";
import { Step1 } from "pages/client/AddPropertyPage/step-1";
import { Step2 } from "pages/client/AddPropertyPage/step-2";
import { ownerPortalPropertySelector } from "store/owner-portal-property-slice/selectors";
import { ownerPortalPropertyActions } from "store/owner-portal-property-slice/slice";

import * as S from "./styled";

export const AddPropertyPage = () => {
  const { addPropertyStep, scrollTooErrorMessage } = useSelector(ownerPortalPropertySelector);

  const ref = useRef(null);

  const dispatch = useDispatch();
  useScrollTo(ref, [scrollTooErrorMessage]);

  const stepObj = {
    0: <Step1 />,
    1: <Step2 />,
  };

  useEffect(() => {
    return () => dispatch(ownerPortalPropertyActions.resetAllData());
  }, []);

  return (
    <S.StyledAddPropertyContainer ref={ref} className="w-full">
      <S.StyledAddPropertyStepArea>
        <>
          <Stepper maxStep={2} step={addPropertyStep} />
          {stepObj[addPropertyStep]}
        </>
      </S.StyledAddPropertyStepArea>
    </S.StyledAddPropertyContainer>
  );
};
