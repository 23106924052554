import { createAsyncThunk } from "@reduxjs/toolkit";
import { smsEligibilityApi } from "store/admin-slices/sms-eligibility-slice/services";
import { SLICE_NAME } from "utils/constants";

export const getSmsEligibilityData = createAsyncThunk(
  `${SLICE_NAME.smsEligibilitySlice}/getSmsEligibilityData`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await smsEligibilityApi.getSmsEligibility(params);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportSmsEligibilityData = createAsyncThunk(
  `${SLICE_NAME.smsEligibilitySlice}/exportSmsEligibilityData`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await smsEligibilityApi.exportSmsEligibility(params);
      const href = URL.createObjectURL(res?.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
