import { COLORS } from "assets/styles/colors";
import { ContentHeader, SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .delete-modal {
    color: ${COLORS.red};
  }
`;

export const Content = styled(SinglePageContentShadow)`
  height: 100%;
`;

export const ContentTitle = styled(ContentHeader)``;

export const FlagsContent = styled.div`
  padding-block: 10px;
`;

export const Flag = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  height: 48px;

  .content-input {
    width: 200px;
  }

  svg {
    width: 15px;
    fill: ${COLORS.dimgrey};
    cursor: pointer;

    &.plus-icon {
      width: 18px;
    }
  }
`;

export const FlagItem = styled.div`
  min-width: 210px;
  max-width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid ${COLORS.overlay_200};
`;

export const AddFlagForm = styled.form`
  margin-block: 10px;
`;

export const IconWrapper = styled.button`
  background-color: transparent;
  border: none;
`;
