import { isValidFileType } from "utils/helpers";
import * as yup from "yup";

export const ownerPortalSubmitEvidenceSchema = yup.object().shape({
  first_name: yup.string().required("The field is required."),
  last_name: yup.string().required("The field is required."),
  client_email: yup.string().email("E-mail must be valid").required("The field is required."),
  address: yup.string().required("The field is required"),
  description: yup.string().required("Message is required").max(255, "Maximum 255 characters"),
  files: yup.mixed().test("is-valid-type", "Not a valid file type", (value) => isValidFileType(value)),
});
