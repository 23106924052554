import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "common";
import { getReferrals } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { isLoading, YEARS_FILTER } from "utils/constants";

import { Footer } from "./Footer";
import * as S from "./styled";

export const ReferredTo = () => {
  const { referrals, getReferralsStatus, ownerReferralCode, account } = useSelector(clientSelectors);

  const selectedYear = YEARS_FILTER.map((item) => item.value);

  const dispatch = useDispatch();

  const tableData = [
    { header: "Name", field: "full_name" },
    { header: "Email", field: "email" },
    {
      header: "Status",
      body: (item) => <S.Box className={item?.status !== "Approved" ? "red" : ""}>{item?.status}</S.Box>,
    },
    { header: "Code", body: () => ownerReferralCode },
    { header: "Date", field: "updated_at" },
    { header: "Percent", body: (item) => (item?.status !== "Approved" ? "0" : "10%") },
  ];

  const filteredReferrals = referrals?.filter(
    (item) => selectedYear.some((year) => item?.updated_at?.includes(year)) && item?.status === "Approved"
  ).length;

  useEffect(() => {
    if (account) {
      dispatch(getReferrals(account?.id));
    }
  }, [account]);

  return (
    <S.Container>
      <S.Content>
        <S.FilterBar>
          <S.ContentTitle>Referred to</S.ContentTitle>
          <S.FilterButtons></S.FilterButtons>
        </S.FilterBar>
        <Table
          dataKey="id"
          footer={<Footer referralsLength={filteredReferrals} />}
          loading={isLoading(getReferralsStatus)}
          tableData={tableData}
          value={referrals?.filter((item) => selectedYear.some((year) => item?.updated_at?.includes(year)))}
        />
      </S.Content>
    </S.Container>
  );
};
