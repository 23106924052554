import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icons } from "assets/svgs";
import { Loader, PrimaryButton, PrimaryInput } from "common";
import { clientRoutes, routes } from "router/routes";
import { updateAccountInfo } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { setEmailUpdatedOrNot, setUpdateAccountInfoStatus } from "store/client-slice/slice";
import { isLoading, LOADING_STATUSES } from "utils/constants";
import { removeStorage } from "utils/helpers";

import * as S from "./styled";

export const AccountPage = () => {
  const { getMeStatus, account, updateAccountInfoStatus } = useSelector(clientSelectors);

  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({
    email: account?.email || "",
    phone_number: account?.phone_number || "",
    mailing_address: `${account?.county_owner_address || ""}, ${account?.county_owner_city || ""}, ${
      account?.county_owner_state || ""
    } ${account?.county_owner_zip || ""}`,
  });

  const initialEmail = account?.email;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleNavigateToContactUs = () => {
    navigate(`${clientRoutes.prefix}${clientRoutes.contactUs}`);
  };

  const handleEditToggle = () => {
    if (isEditing) {
      handleSave();
    }
    setIsEditing((prev) => !prev);
  };

  const handleChange = (field, value) => {
    setEditableData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = () => {
    const params = editableData;
    dispatch(updateAccountInfo({ params, id: account?.id }));
  };

  const formatPhoneNumber = (phone_number) => {
    const cleaned = ("" + phone_number).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `${match[1]}-${match[2]}-${match[3]}` : phone_number;
  };

  useEffect(() => {
    if (account) {
      setEditableData({
        email: account?.email || "",
        phone_number: account?.phone_number || "",
        mailing_address: `${account?.county_owner_address || ""}, ${account?.county_owner_city || ""}, ${
          account?.county_owner_state || ""
        } ${account?.county_owner_zip || ""}`,
      });
    }
  }, [account]);

  useEffect(() => {
    if (updateAccountInfoStatus === LOADING_STATUSES.succeeded) {
      if (initialEmail !== editableData.email) {
        removeStorage("owner_token");
        dispatch(setEmailUpdatedOrNot(true));
        navigate(routes.signin);
      }
      dispatch(setUpdateAccountInfoStatus(LOADING_STATUSES.idle));
    }
  }, [updateAccountInfoStatus, editableData.email, initialEmail, dispatch]);

  if (isLoading(getMeStatus)) {
    return (
      <S.LoaderBox>
        <Loader size={80} />
      </S.LoaderBox>
    );
  }

  return (
    <S.AccountBlock>
      <S.AccountContainer>
        <S.EditButtonBlock>
          <S.AccountTitleWrapper>
            <Icons.UserIcon />
            <S.AccountInfoTitle>Full Name</S.AccountInfoTitle>
          </S.AccountTitleWrapper>
          <PrimaryButton
            className="edit-save-button"
            eventHandler={handleEditToggle}
            label={isEditing ? "Save" : "Edit"}
            loading={isLoading(updateAccountInfoStatus)}
          />
        </S.EditButtonBlock>

        <S.AccountInfo>
          <S.InfoBlock>{account?.first_name + " " + account?.last_name}</S.InfoBlock>
        </S.AccountInfo>
      </S.AccountContainer>

      <S.AccountContainer>
        <S.AccountTitleWrapper>
          <Icons.EmailIcon />
          <S.AccountInfoTitle>Email</S.AccountInfoTitle>
        </S.AccountTitleWrapper>
        <S.AccountInfo>
          <S.InfoBlock>
            {isEditing ? (
              <PrimaryInput
                defaultValue={editableData.email}
                wrapperClassName="mailing-address-input"
                onChange={(e) => handleChange("email", e.target.value)}
              />
            ) : (
              editableData.email
            )}
          </S.InfoBlock>
        </S.AccountInfo>
      </S.AccountContainer>

      <S.AccountContainer>
        <S.AccountTitleWrapper>
          <Icons.PhoneIcon />
          <S.AccountInfoTitle>Phone Number</S.AccountInfoTitle>
        </S.AccountTitleWrapper>
        <S.AccountInfo>
          <S.InfoBlock>
            {isEditing ? (
              <PrimaryInput
                defaultValue={formatPhoneNumber(editableData.phone_number)}
                wrapperClassName="mailing-address-input"
                onChange={(e) => handleChange("phone_number", e.target.value)}
              />
            ) : (
              formatPhoneNumber(editableData.phone_number)
            )}
          </S.InfoBlock>
        </S.AccountInfo>
      </S.AccountContainer>

      <S.AccountContainer>
        <S.AccountTitleWrapper>
          <Icons.LocationIconFilled />
          <S.AccountInfoTitle>Mailing Address</S.AccountInfoTitle>
        </S.AccountTitleWrapper>
        <S.AccountInfo>
          <S.InfoBlock>
            {isEditing ? (
              <PrimaryInput
                defaultValue={editableData.mailing_address}
                wrapperClassName="mailing-address-input"
                onChange={(e) => handleChange("mailing_address", e.target.value)}
              />
            ) : (
              editableData.mailing_address
            )}
          </S.InfoBlock>
        </S.AccountInfo>
      </S.AccountContainer>

      <PrimaryButton className="contact-us-button" eventHandler={handleNavigateToContactUs} label="CONTACT US" />

      <S.AccountContainer>
        <S.AccountInfoHeading>If you have any questions please contact us.</S.AccountInfoHeading>
      </S.AccountContainer>
    </S.AccountBlock>
  );
};
