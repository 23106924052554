import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { COLORS } from "assets/styles/colors";
import { Icons } from "assets/svgs";
import { PrimaryButton, Select, Table } from "common";
import { Filter } from "pages/admin/components/Filter";
import { Paginator } from "primereact/paginator";
import { evidenceSelectors } from "store/admin-slices/evidence-slice/selectors";
import { evidenceActions } from "store/admin-slices/evidence-slice/slice";
import { singleEvidenceSelectors } from "store/admin-slices/single-evidence-slice/selectors";
import { singleEvidenceActions } from "store/admin-slices/single-evidence-slice/slice";
import { EVIDENCE_STATUSES, isLoading, LOADING_STATUSES, RESIDENT_TYPES } from "utils/constants";
import { downloadFileByLink, getPaginationDescription, getStorage } from "utils/helpers";

import * as S from "./styled";

const SORT_ICONS = {
  "1": <Icons.ArrowUpSortIcon className="rotate-180 scale-x-[-1]" style={{ width: 18, height: 18 }} />,
  "0": <Icons.ArrowUpDownIcon style={{ width: 18, height: 18 }} />,
  "-1": <Icons.ArrowUpSortIcon style={{ width: 18, height: 18 }} />,
};

export const EvidenceTable = () => {
  const {
    residentType,
    tableData,
    sales,
    equities,
    savedComps,
    downloadCompsCsvHref,
    getEvidencesEquitiesStatus,
    getEvidencesSalesStatus,
    saveColumnsInfoStatus,
    resetCompsStatus,
    exportCompsCsvStatus,
    subjectProperty,
    sortData,
    resetFiltersStatus,
    getSaveCompsStatus,
    selectedProperties,
    savedFilters,
    status,
    getEvidanceStatusStatus,
    columnsInfo,
    leftBarValues,
    selects,
    getSortDataStatus,
  } = useSelector(singleEvidenceSelectors);
  const { printPropertyStatus, printPropertyDownloadHref } = useSelector(evidenceSelectors);

  const [selectedEquities, setSelectedEquities] = useState([]);
  const [selectedEquitiesIds, setSelectedEquitiesIds] = useState([]);
  const [selectedSales, setSelectedSales] = useState([]);
  const [selectedSalesIds, setSelectedSalesIds] = useState([]);
  const [paginationData, setPaginationData] = useState({
    first: 0,
    rows: 100,
    page: 1,
  });
  const visibleColumns = tableData.filter((col) => col.isShow);
  const propertyIds = getStorage("property_ids");
  const propertyCounty = leftBarValues?.county || "";
  const property_id = useParams().propertyId;
  const debounceRef = useRef(null);

  const dragItem = useRef();
  const dragOverItem = useRef();

  const { propertyId } = useParams();

  const dispatch = useDispatch();

  const location = useLocation();

  const currentIndex = propertyIds.findIndex((id) => String(id) === String(propertyId));
  const isFirst = currentIndex === 0;
  const isLast = currentIndex === propertyIds.length - 1;

  const filterSelectedData = (data = []) => {
    if (residentType === RESIDENT_TYPES.equity) {
      setSelectedEquities(data.filter((item) => savedComps.includes(item.property_id)));
    } else if (residentType === RESIDENT_TYPES.sales) {
      setSelectedSales(data.filter((item) => savedComps.includes(item.property_id)));
    }
  };

  const changeResidentType = (type) =>
    dispatch(singleEvidenceActions.selectResidentType({ type, county: propertyCounty.toLowerCase() }));

  useEffect(() => {
    changeResidentType(RESIDENT_TYPES.equity);
  }, [propertyCounty]);

  const handleSaveClick = () => {
    dispatch(
      singleEvidenceActions.saveFilters({
        is_sale: Number(residentType === RESIDENT_TYPES.sales),
        filters: { ...savedFilters },
        property_id: propertyId,
      })
    );
    dispatch(
      singleEvidenceActions.saveColumnsInfo({
        is_sales: Number(residentType === RESIDENT_TYPES.sales),
        sequence: tableData.map((col) => ({
          field: col.field,
          width: col.width,
          order_column: sortData.field === col.field ? sortData.field : undefined,
          direction: sortData.field === col.field ? String(sortData.direction) : undefined,
        })),
        county: propertyCounty,
      })
    );
  };

  const onSort = (e) => {
    dispatch(singleEvidenceActions.setSortData({ field: e.sortField, direction: e.sortOrder }));
  };

  const handleExportClick = () => {
    const params = {
      propertyId,
      property_ids: selectedProperties.map((item) => item.property_id),
      sales: Number(residentType === RESIDENT_TYPES.sales),
    };
    dispatch(singleEvidenceActions.exportCompsCsv(params));
  };

  const handlePrintClick = () => {
    dispatch(evidenceActions.printProperty(propertyId));
  };

  const handleDragStart = (e, index) => {
    dragItem.current = index;
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    dragOverItem.current = index;
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const newOrder = [...visibleColumns];
    const draggedItem = newOrder.splice(dragItem.current, 1)[0];

    newOrder.splice(dragOverItem.current, 0, draggedItem);
    dragItem.current = null;
    dragOverItem.current = null;
  };

  const draggableColumns = visibleColumns.map((col, index) => {
    const isColSorted = sortData.field === col.field;
    return {
      ...col,
      sortable: false,
      headerStyle: {
        ...(col.headerStyle ?? {}),
        background: isColSorted && COLORS.light_green
      },
      header: (
        <S.DraggableColumn
          draggable
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            onSort({
              sortOrder: isColSorted && sortData.direction === 1 ? -1 : 1,
              sortField: col.field
            });
          }}
          onDragOver={(e) => handleDragOver(e, index)}
          onDragStart={(e) => handleDragStart(e, index)}
          onDrop={(e) => handleDrop(e, index)}
        >
          <p>{col.header}</p>
          {isColSorted ? SORT_ICONS[sortData.direction] : SORT_ICONS[0]}
        </S.DraggableColumn>
      ),
    };
  });

  const onSelectionChange = (event) => {
    if (residentType === RESIDENT_TYPES.equity && event.type === "all") {
      setSelectedEquities(event.value?.length ? event.value.map((item) => item) : []);
    } else if (residentType === RESIDENT_TYPES.sales && event.type === "all") {
      setSelectedSales(event.value?.length ? event.value.map((item) => item) : []);
    }
  };

  const rowSelect = (event, isSelected) => {
    if (residentType === RESIDENT_TYPES.equity) {
      setSelectedEquities((prevent) =>
        isSelected ? [...prevent, event.data] : prevent.filter((item) => item !== event.data)
      );
    } else if (residentType === RESIDENT_TYPES.sales) {
      setSelectedSales((prevent) =>
        isSelected ? [...prevent, event.data] : prevent.filter((item) => item !== event.data)
      );
    }
  };

  useEffect(() => {
    if (getSaveCompsStatus !== LOADING_STATUSES.succeeded) return;

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      dispatch(
        singleEvidenceActions.saveComps({
          propertyId,
          sales: Number(residentType === RESIDENT_TYPES.sales),
          property_ids:
            residentType === RESIDENT_TYPES.equity
              ? selectedEquitiesIds
              : selectedSalesIds,
        })
      );
    }, 300);

    return () => clearTimeout(debounceRef.current);
  }, [propertyId, residentType, selectedEquitiesIds, selectedSalesIds, dispatch]);

  const isRowSelectable = (event) => {
    return event.data?.property_id !== subjectProperty?.property_id;
  };

  const paginatedData = useMemo(() => {
    if (residentType === RESIDENT_TYPES.equity) {

      const selectedIds = selectedEquities.map(eq => eq.property_id);

      if (sortData.field) {
        return [
          ...selectedEquities,
          ...equities
            .filter(eq => !selectedIds.includes(eq.property_id))
            .sort((a, b) => (a[sortData.field] > b[sortData.field] ? 1 : -1) * sortData.direction)
            .slice(paginationData.first, paginationData.first + paginationData.rows - selectedEquities.length)];
      }

      return [
        ...selectedEquities,
        ...equities
          .filter(eq => !selectedIds.includes(eq.property_id))
          .slice(paginationData.first, paginationData.first + paginationData.rows - selectedEquities.length)
      ];
    } else if (residentType === RESIDENT_TYPES.sales) {
      const selectedIds = selectedSales.map(eq => eq.property_id);

      if (sortData.field) {
        return [
          ...selectedSales,
          ...sales
            .filter(eq => !selectedIds.includes(eq.property_id))
            .sort((a, b) => (a[sortData.field] > b[sortData.field] ? 1 : -1) * sortData.direction)
            .slice(paginationData.first, paginationData.first + paginationData.rows - selectedSales.length)];
      }

      return [...selectedSales, ...sales.filter(eq => !selectedIds.includes(eq.property_id)).slice(paginationData.first, paginationData.first + paginationData.rows - selectedSales.length)];
    }

    return [];
  }, [residentType, equities, sales, sortData, paginationData, selectedEquities, selectedSales]);

  const onPageChange = ({ first, rows, page }) => {
    setPaginationData({
      first,
      rows,
      page: page + 1,
    });
  };

  const handlePropertyNavigation = (direction) => {
    const currentIndex = propertyIds.findIndex((id) => String(id) === String(propertyId));

    if (currentIndex === -1) return;

    let newIndex;

    if (direction === "prev" && currentIndex > 0) {
      newIndex = currentIndex - 1;
    } else if (direction === "next" && currentIndex < propertyIds.length - 1) {
      newIndex = currentIndex + 1;
    } else {
      return;
    }

    const newPropertyId = propertyIds[newIndex];
    const pathParts = location.pathname.split("/");
    pathParts[pathParts.length - 1] = newPropertyId;
    window.location.href = pathParts.join("/");
  };

  const handleReset = () => {
    const filters = {};

    for (const [key, value] of Object.entries(savedFilters)) {
      if (Array.isArray(value)) {
        const select = selects.find(item => item.description === key);
        filters[key] = select?.is_default_selected ? (subjectProperty[key] ? [subjectProperty[key]] : []) : [];
      } else if (typeof value === "object") {
        filters[key] = { start: null, end: null };
      } else {
        filters[key] = null;
      }
    }

    dispatch(
      singleEvidenceActions.resetFilters({
        is_sale: Number(residentType === RESIDENT_TYPES.sales),
        filters,
        property_id: propertyId,
      })
    );

    dispatch(
      singleEvidenceActions.resetComps({
        propertyId,
        sales: Number(residentType === RESIDENT_TYPES.sales),
        property_ids: [],
      })
    );
  };

  useEffect(() => {
    downloadFileByLink(exportCompsCsvStatus, downloadCompsCsvHref, "comps.csv");
  }, [downloadCompsCsvHref]);

  useEffect(() => {
    downloadFileByLink(
      printPropertyStatus,
      printPropertyDownloadHref,
      `${location.state?.accountNumber}_${new Date().toISOString().split("T")[0]}_${propertyCounty}.pdf`
    );
  }, [printPropertyDownloadHref]);

  useEffect(() => {
    const sales = residentType === RESIDENT_TYPES.equity ? 0 : 1;
    dispatch(singleEvidenceActions.getSaveComps({ sales, propertyId }));
  }, [residentType, propertyId]);

  useEffect(() => {
    if (getSaveCompsStatus === LOADING_STATUSES.succeeded) {
      if (residentType === RESIDENT_TYPES.sales) {
        filterSelectedData(sales);
      }

      if (residentType === RESIDENT_TYPES.equity) {
        filterSelectedData(equities);
      }
    }
  }, [sales, equities, getSaveCompsStatus]);

  useEffect(() => {
    const selectedItems = residentType === RESIDENT_TYPES.equity ? selectedEquities : selectedSales;
    const setSelectedIds = residentType === RESIDENT_TYPES.equity ? setSelectedEquitiesIds : setSelectedSalesIds;

    setSelectedIds(selectedItems.map((item) => item.property_id));
  }, [selectedEquities.length, selectedSales.length, residentType]);

  useEffect(() => {
    if (printPropertyStatus === LOADING_STATUSES.succeeded) {
      dispatch(evidenceActions.resetPrintProperty());
    }
  }, [printPropertyDownloadHref, printPropertyStatus]);

  useEffect(() => {
    return () => dispatch(singleEvidenceActions.resetAllData());
  }, []);

  useEffect(() => {
    dispatch(singleEvidenceActions.getEvidenceStatus({ property_id: propertyId }));
  }, [propertyId]);

  useEffect(() => {
    if (propertyCounty) {
      dispatch(
        singleEvidenceActions.getSubjectProperty({
          propertyId,
          is_sales: residentType === RESIDENT_TYPES.equity ? 0 : 1,
        })
      );
    }
  }, [propertyCounty]);

  useEffect(() => {
    if (residentType === RESIDENT_TYPES.equity) {
      if (
        equities.length &&
        savedComps.length === 0 &&
        getSaveCompsStatus === LOADING_STATUSES.succeeded &&
        paginationData.page === 1
      ) {
        const firstNineItems = equities.slice(0, 9);
        setSelectedEquities(firstNineItems);
      }
    } else if (residentType === RESIDENT_TYPES.sales) {
      if (
        sales.length &&
        savedComps.length === 0 &&
        getSaveCompsStatus === LOADING_STATUSES.succeeded &&
        paginationData.page === 1
      ) {
        const firstNineItemsSales = sales.slice(0, 9);
        setSelectedSales(firstNineItemsSales);
      }
    }
  }, [equities, sales, getSaveCompsStatus, savedComps.length]);

  useEffect(() => {
    const isSale = residentType === RESIDENT_TYPES.sales ? 1 : 0;

    dispatch(
      singleEvidenceActions.getLeftBarValues({
        propertyId,
        is_sale: isSale,
        property_ids: [],
      })
    );
  }, [residentType]);

  useEffect(() => {
    if (
      getEvidencesSalesStatus === LOADING_STATUSES.succeeded ||
      getEvidencesEquitiesStatus === LOADING_STATUSES.succeeded
    ) {
      if (residentType === RESIDENT_TYPES.equity && selectedEquitiesIds.length !== 0) {
        dispatch(
          singleEvidenceActions.getLeftBarValues({
            propertyId,
            is_sale: 0,
            property_ids: selectedEquitiesIds,
          })
        );
      }
      if (residentType === RESIDENT_TYPES.sales && selectedSalesIds.length !== 0) {
        dispatch(
          singleEvidenceActions.getLeftBarValues({
            propertyId,
            is_sale: 1,
            property_ids: selectedSalesIds,
          })
        );
      }
    }
  }, [
    selectedEquitiesIds?.length,
    selectedSalesIds?.length,
    getEvidencesEquitiesStatus,
    getEvidencesSalesStatus,
    residentType,
  ]);

  useEffect(() => {
    if (propertyCounty) {
      dispatch(
        singleEvidenceActions.getColumnsInfo({
          is_sales: Number(residentType === RESIDENT_TYPES.sales),
          county: propertyCounty,
        })
      );
    }
  }, [residentType, propertyCounty]);

  useEffect(() => {
    if (columnsInfo?.length) {
      const fieldOrder = columnsInfo.map((c) => c.field);
      const newTableData = tableData
        .map((col) => {
          const width = columnsInfo.find((c) => c.field === col.field)?.width;
          return {
            ...col,
            width,
            bodyStyle: {
              width,
              maxWidth: width,
            },
            headerStyle: {
              ...(col.headerStyle ?? {}),
              width,
              maxWidth: width,
            },
          };
        })
        .sort((a, b) => fieldOrder.indexOf(a.field) - fieldOrder.indexOf(b.field));
      dispatch(singleEvidenceActions.updateColumnOrder(newTableData));
    }
  }, [columnsInfo]);

  useEffect(() => {
    if (resetFiltersStatus === LOADING_STATUSES.succeeded) {
      dispatch(
        singleEvidenceActions.getCompsFilters({
          property_id,
          is_sales: residentType === RESIDENT_TYPES.equity ? 0 : 1,
        })
      );
    }
  }, [resetFiltersStatus]);

  useEffect(() => {
    if (resetCompsStatus === LOADING_STATUSES.succeeded) {
      dispatch(singleEvidenceActions.getSaveComps({ sales: residentType === RESIDENT_TYPES.equity ? 0 : 1, propertyId }));
    }
  }, [resetCompsStatus]);

  const onColReorder = ({ dragIndex, dropIndex }) => {
    if (!dragIndex || !dropIndex) {
      return;
    }

    const newOrder = [...tableData];

    const field = visibleColumns[dragIndex - 1].field;
    const fieldIndex = tableData.findIndex((col) => col.field === field);
    const [movedColumn] = newOrder.splice(fieldIndex, 1);

    const nextField = visibleColumns[dropIndex - 1].field;
    const nextFieldIndex = tableData.findIndex((col) => col.field === nextField);

    newOrder.splice(nextFieldIndex, 0, movedColumn);

    dispatch(singleEvidenceActions.updateColumnOrder(newOrder));
  };

  const onColumnResizeEnd = ({ column, element, delta }) => {
    const PADDING = 8;
    const field = column.props.field;
    const innerDiv = element.querySelector("div");
    const intendedWidth = element.offsetWidth;
    const innerDivWidth = innerDiv.offsetWidth + PADDING * 2;
    const newWidth = innerDiv && intendedWidth < innerDivWidth ? innerDivWidth : intendedWidth;

    const newColumnsInfo = tableData.map((col) => {
      if (col.field === field) {
        return {
          ...col,
          width: newWidth,
          bodyStyle: {
            width: `${newWidth}px`,
            maxWidth: `${newWidth}px`,
            minWidth: `${innerDivWidth}px`,
          },
          headerStyle: {
            ...(col.headerStyle ?? {}),
            width: `${newWidth}px`,
            maxWidth: `${newWidth}px`,
            minWidth: `${innerDivWidth}px`,
          },
        };
      }
      return col;
    });

    dispatch(singleEvidenceActions.updateColumnOrder(newColumnsInfo));
  };

  return (
    <S.EvidenceTableContainer>
      <S.Container>
        <S.ResidentialContainer>
          <Filter>
            <S.FilterRightButtons>
              <S.FiltersButtonsBlock>
                <PrimaryButton
                  eventHandler={() => changeResidentType(RESIDENT_TYPES.equity)}
                  label="Equity"
                  mode={residentType === RESIDENT_TYPES.equity ? "green" : "gray"}
                />
                <PrimaryButton
                  eventHandler={() => changeResidentType(RESIDENT_TYPES.sales)}
                  label="Sales"
                  mode={residentType === RESIDENT_TYPES.sales ? "green" : "gray"}
                />
              </S.FiltersButtonsBlock>
              <S.FilterBlock>
                <S.FiltersButtonsBlock className="[&>div:first-child>button]:!py-1 [&>div:first-child>button]:!m-0 [&>div:first-child>button]:h-full">
                  <PrimaryButton
                    eventHandler={handleReset}
                    label="Reset"
                    loading={isLoading(resetCompsStatus)}
                    mode="gray"
                  />
                  <PrimaryButton
                    eventHandler={handleSaveClick}
                    label="Save"
                    loading={isLoading(saveColumnsInfoStatus)}
                    mode="gray"
                  />
                  <PrimaryButton
                    eventHandler={handleExportClick}
                    label="Export"
                    loading={isLoading(exportCompsCsvStatus)}
                    mode="gray"
                  />
                  <PrimaryButton
                    eventHandler={handlePrintClick}
                    label="Print"
                    loading={isLoading(printPropertyStatus)}
                    mode="gray"
                  />
                  <PrimaryButton
                    disabled={isFirst}
                    eventHandler={() => handlePropertyNavigation("prev")}
                    iconStart={<Icons.ArrowRightIcon style={{ rotate: "180deg" }} />}
                    mode="gray"
                  />
                  <PrimaryButton
                    disabled={isLast}
                    eventHandler={() => handlePropertyNavigation("next")}
                    iconStart={<Icons.ArrowRightIcon />}
                    mode="gray"
                  />
                </S.FiltersButtonsBlock>
                {!isLoading(getEvidanceStatusStatus) && (
                  <Select
                    className="[&>div]:hidden"
                    itemKey="label"
                    items={EVIDENCE_STATUSES}
                    value={EVIDENCE_STATUSES.find((s) => s.value === status)?.label}
                    onChange={(newStatus) =>
                      dispatch(
                        singleEvidenceActions.setEvidenceStatus({
                          property_id: propertyId,
                          status: EVIDENCE_STATUSES.find((status) => status.label === newStatus).value,
                        })
                      )
                    }
                  />
                )}
              </S.FilterBlock>
            </S.FilterRightButtons>
          </Filter>
        </S.ResidentialContainer>
        <S.Box className="flex justify-center gap-6 p-2">
          {residentType === RESIDENT_TYPES.equity ? equities.length : sales.length} Comps Available
          <S.Span>&#8226;</S.Span>
          <S.Span>
            {residentType === RESIDENT_TYPES.equity ? selectedEquities.length : selectedSales.length} Comps selected
          </S.Span>
        </S.Box>
      </S.Container>

      <S.TableContainer>
        <Table
          key={residentType}
          reorderableColumns
          resizableColumns
          scrollable
          showGridlines
          columnResizeMode="expand"
          frozenValue={subjectProperty && [subjectProperty]}
          isDataSelectable={isRowSelectable}
          loading={
            (residentType === RESIDENT_TYPES.equity
              ? getEvidencesEquitiesStatus !== LOADING_STATUSES.succeeded
              : getEvidencesSalesStatus !== LOADING_STATUSES.succeeded) ||
            visibleColumns.length === 0 ||
            getSortDataStatus !== LOADING_STATUSES.succeeded
          }
          scrollHeight="calc(100vh - 280px)"
          selection={residentType === RESIDENT_TYPES.equity ? selectedEquities : selectedSales}
          tableData={[
            {
              headerClassName: "column-header",
              selectionMode: "multiple",
            },
            ...draggableColumns,
          ]}
          tableStyle={{ width: "max-content", minWidth: "100%" }}
          value={paginatedData}
          onColReorder={onColReorder}
          onColumnResizeEnd={onColumnResizeEnd}
          onRowSelect={(e) => rowSelect(e, true)}
          onRowUnselect={(e) => rowSelect(e, false)}
          onSelectionChange={onSelectionChange}
        />
      </S.TableContainer>
      <S.PaginatorWrapper>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[100, 200, 500, 1000]}
          totalRecords={residentType === RESIDENT_TYPES.equity ? equities.length : sales.length}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">
          {getPaginationDescription(
            paginationData,
            residentType === RESIDENT_TYPES.equity ? equities.length : sales.length
          )}
        </S.Box>
      </S.PaginatorWrapper>
    </S.EvidenceTableContainer>
  );
};
