import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const SlidebarContainer = styled.div`
  max-width: 300px;
  height: fit-content;
  background-color: ${COLORS.overlay_060};
  display: flex;
  flex-direction: column;
  opacity: 1;
  width: 100%;

  .multi-select {
    width: 94% !important;

    .p-multiselect-panel {
      top: 100% !important;
      margin-top: 5px;
      min-height: 300px !important;
      width: 220px !important;
      z-index: 12 !important;
      overflow-y: auto;
      overflow-x: scroll;

      .p-multiselect-close {
        display: none !important;
      }

      .p-multiselect-items {
        padding: 0;
      }

      .p-multiselect-items .p-multiselect-item {
        display: flex;
        align-items: center;
        padding: 6px 12px;
        height: 38px !important;
        white-space: nowrap;
        margin-top: 2px;

        span {
          font-size: 14px;
          text-transform: capitalize;
        }
      }

      .p-virtualscroller {
        height: 240px !important;
      }

      .p-inputtext {
        padding: 4px;
      }
    }

    .p-multiselect-trigger {
      display: flex;
      transition: transform 0.3s ease;
    }

    .p-multiselect-label {
      display: flex !important;
      justify-content: space-between;
      padding-inline: 16px;
      text-transform: capitalize;
      font-size: 14px;
    }
    .p-checkbox {
      align-items: center;

      .p-checkbox-box {
        width: 18px;
        height: 18px;

        .p-checkbox-icon {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .back-button {
    position: sticky !important;
    top: 0;
    overflow: unset !important;
    z-index: 10;
    height: 30px;
  }

  .p-float-label {
    label {
      font-size: 14px !important;
    }
  }

  .p-multiselect .p-multiselect-label.p-placeholder {
    opacity: 1;
  }
  .p-multiselect-panel .p-multiselect-header {
    background: none !important;
    border: none !important;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    font-size: 12px;
    padding-inline: 20px;
  }
  .p-multiselect-panel .p-virtualscroller-spacer {
    visibility: hidden;
  }
`;

export const StickyContainer = styled.div`
  width: 100%;
  position: sticky;
  padding-top: 8px;
  top: 0;
  z-index: 10;
  background-color: ${COLORS.grey};
  opacity: 1;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const SiteBarFixedContainer = styled.div`
  position: relative;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  row-gap: 16px;
  overflow-y: auto;
  width: 100%;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-inline: 4px;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const HeadingText = styled.h2`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 4px;

  button {
    padding: 6px 8px;
  }
`;

export const InputsBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-transform: capitalize;
`;

export const DateTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  align-items: center;
  text-transform: capitalize;
`;

export const Description = styled.span`
  font-size: 14px;
  font-style: normal;
`;

export const Span = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
`;

export const FilterInput = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  div {
    min-width: 0;
  }
`;

export const Border = styled.div`
  border: 1px solid ${COLORS.darkgrey};
`;

export const PropertyParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  padding-inline: 6px;
`;

export const PropertyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Label = styled.h2`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const Value = styled.h2`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: end;
  word-break: break-word;
`;

export const MultiSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-top: 2px;
  padding-left: 6px;

  .p-multiselect-label {
    display: block !important ;
    text-align: center;
  }
`;

export const Block = styled.div`
  gap: 8px;
  display: flex;

  &.block {
    display: block;
    width: 100%;
  }

  input {
    border: 1px solid;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    padding-block: unset;
    width: 100%;
  }

  div {
    min-width: unset;
    width: 96% !important;
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
`;

export const DatesContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TemplateContainer = styled.div`
  width: 100%;
  min-height: 10px;
  display: flex;
  flex-direction: column;
  padding-inline: 8px;
`;

export const TemplateContent = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  gap: 8px;
  cursor: pointer;

  .checkbox {
    width: 18px;
    height: 18px;
    border: 2px solid #ced4da;
    border-radius: 6px;
    color: #437f55;
    outline: none;
    transition: 0.2s;
  }

  .checkbox:checked {
    border-color: #437f55 !important;
    background-color: #437f55;
  }

  .checkbox:hover {
    border-color: #437f55 !important;
  }

  .checkbox:focus {
    outline: none;
    box-shadow: unset;
  }
`;

export const TemplateTitle = styled.p`
  text-transform: capitalize;
  font-size: 14px;
  margin: 6px auto;
  color: ${COLORS.dimgrey};
  width: 100%;
  padding-inline: 26px;
`;

export const Paragraph = styled.span`
  font-size: 12px;
  font-weight: 100;
  color: ${COLORS.dimgrey};
  text-transform: capitalize;
`;

export const Box = styled.div`
  font-size: 14px;
`;

export const SubjectSpan = styled.span`
  text-transform: none;
  white-space: nowrap;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const SubjectBorder = styled.span`
  margin-top: 6px;
  border: 1px solid ${COLORS.darkgrey};
  border-bottom: 1px solid ${COLORS.overlay_30};
`;

export const SelectedSubjectContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-inline: 4px;
`;

export const DateHeader = styled.div`
  display: flex;
  column-gap: 6px;
  align-items: center;
`;
