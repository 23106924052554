import { Navigate } from "react-router-dom";
import { AdminLayout } from "layout/AdminLayout";
import { ClientLayout } from "layout/ClientLayout";
import { UserLayout } from "layout/UserLayout";
import { AgentsPage } from "pages/admin/AgentsPage";
import { AgentSinglePage } from "pages/admin/AgentsSinglePage";
import { ArchivedPage } from "pages/admin/ArchivedPage";
import { ArchivedSinglePage } from "pages/admin/ArchivedSinglePage";
import { ClientEvidencePage } from "pages/admin/ClientEvidencePage";
import { ClientEvidenceSinglePage } from "pages/admin/ClientEvidenceSinglePage";
// import { DashboardPage } from "pages/admin/DashboardPage";
import { DashboardSinglePage } from "pages/admin/DashboardSinglePage";
import { DocumentsPage } from "pages/admin/DocumentsPage";
import { DocumentsSinglePage } from "pages/admin/DocumentsSiglePage";
import { EvidencePage } from "pages/admin/EvidencePage";
import { EvidenceSinglePage } from "pages/admin/EvidenceSinglePage";
// import { EvidencePage } from "pages/admin/EvidencePage";
// import { EvidenceSinglePage } from "pages/admin/EvidenceSinglePage";
import { HearingDatesPages } from "pages/admin/HearingDatesPages";
import { AdminHomePage } from "pages/admin/HomePage";
import { LeadsPage } from "pages/admin/LeadsPage";
import { OwnersListPage } from "pages/admin/OwnersListPage";
import { OwnersSinglePage } from "pages/admin/OwnersSinglePage";
import { ProtestedAccountsPage } from "pages/admin/ProtestedAccountsPage";
import { AdminSignInPage } from "pages/admin/SigninPage";
import { SmsEligibilityPage } from "pages/admin/SmsEligibilityPage";
import { AccountPage } from "pages/client/AccountPage";
import { AddPropertyPage } from "pages/client/AddPropertyPage";
import { AppealsPage } from "pages/client/AppealsPage";
import { ClientReferralPage } from "pages/client/ClientReferralPage";
import { ContactUsPage } from "pages/client/ContactUsPage";
import { HomePage } from "pages/client/HomePage";
import { InvoicesPage } from "pages/client/InvoicesPage";
import { OwnerPortalEvidencePageWrapper } from "pages/client/OwnerPortalEvidencePage";
import { OwnerPortalRenewDocuments } from "pages/client/OwnerPortalRenewDocuments";
import { PrivacyPolicy } from "pages/client/PrivacyPolicyPage";
import { PropertiesPage } from "pages/client/PropertiesPage";
import { ReferralPage } from "pages/client/ReferralPage";
import { SigninPage } from "pages/client/SigninPage";
import { SignupPage } from "pages/client/SignupPage";
import { SubmitEvidencePage } from "pages/client/SubmitEvidencePage";
import { PERMISSIONS_LIST } from "utils/constants";

export const routes = {
  home: "/",
  signup: "/signup",
  signin: "/signin",
  notFound: "/NotFound",
  referrals: "/referrals",
  submitEvidence: "/submit-evidence",
  docusignRedirect: "/callback/docusign",
  privacyPolicy: "/privacy-policy",
};

export const adminRoutes = {
  admin: "admin/*",
  prefix: "/admin",
  signIn: "/admin/login",
  home: "home",
  dashboard: "dashboard",
  dashboardSinglePage: "dashboard/:id",
  owners: "owners",
  ownersSinglePage: "owners/:id/:selectedPropertyId",
  leads: "leads",
  evidence: "evidence",
  evidenceSinglePage: "evidence/:propertyId",
  documents: "documents",
  documentsSinglePage: "documents/:id",
  leadsSinglePage: "leads/:id",
  protestedAccounts: "protestedaccounts",
  hearingDates: "hearingdates",
  archived: "archived",
  archivedSinglePage: "archived/:id/:selectedPropertyId",
  clientEvidence: "client-evidence",
  clientEvidenceSinglePage: "client-evidence/:id",
  agents: "agents",
  agentSinglePage: "agents/:id",
  smsEligibility: "sms-eligibility",
};

export const clientRoutes = {
  prefix: "/client/",
  client: "client/*",
  account: "account",
  properties: "properties",
  propertySinglePage: "properties/:id",
  appeals: "appeals",
  invoices: "invoices",
  addProperty: "add-property",
  submitEvidence: "submit-evidence",
  submitEvidenceId: "submit-evidence/:id",
  contactUs: "contact-us",
  renewDocuments: "renew-documents",
  renewDocumentsById: "renew-documents/:id",
  referrals: "referrals",
};

export const appRoutes = [
  {
    layout: <UserLayout />,
    path: "",
    children: [
      {
        component: <HomePage />,
        path: routes.home,
      },
      {
        component: <SignupPage />,
        path: routes.signup,
      },
      {
        component: <ReferralPage />,
        path: routes.referrals,
      },
      {
        component: <SubmitEvidencePage />,
        path: routes.submitEvidence,
      },
      {
        component: <SigninPage />,
        path: routes.signin,
      },
      {
        component: <AdminSignInPage />,
        path: adminRoutes.signIn,
      },
      {
        component: <PrivacyPolicy />,
        path: routes.privacyPolicy,
      },
    ],
  },
  {
    layout: <ClientLayout />,
    path: clientRoutes.client,
    children: [
      {
        component: <AppealsPage />,
        path: clientRoutes.appeals,
      },
      {
        component: <AccountPage />,
        path: clientRoutes.account,
      },
      {
        component: <InvoicesPage />,
        path: clientRoutes.invoices,
      },
      {
        component: <PropertiesPage />,
        path: clientRoutes.properties,
      },
      {
        component: <AddPropertyPage />,
        path: clientRoutes.addProperty,
      },
      {
        component: <OwnerPortalEvidencePageWrapper />,
        path: clientRoutes.submitEvidenceId,
      },
      {
        component: <ContactUsPage />,
        path: clientRoutes.contactUs,
      },
      {
        component: <OwnerPortalRenewDocuments />,
        path: clientRoutes.renewDocumentsById,
      },
      {
        component: <Navigate to={clientRoutes.appeals} />,
        path: "",
        index: true,
      },
      {
        component: <ClientReferralPage />,
        path: clientRoutes.referrals,
      },
    ],
  },
  {
    layout: <AdminLayout />,
    path: adminRoutes.admin,
    children: [
      {
        component: <AdminHomePage />,
        path: adminRoutes.home,
        permissions: [PERMISSIONS_LIST.accessHome],
      },
      // {
      //   component: <DashboardPage />,
      //   path: adminRoutes.dashboard,
      //   permissions: [PERMISSIONS_LIST.accessDashboard],
      // },
      {
        component: <DashboardSinglePage />,
        path: adminRoutes.dashboardSinglePage,
      },
      {
        component: <OwnersListPage />,
        path: adminRoutes.owners,
        permissions: [PERMISSIONS_LIST.accessOwners],
      },
      {
        component: <EvidencePage />,
        path: adminRoutes.evidence,
        permissions: [PERMISSIONS_LIST.accessEvidence],
      },
      {
        component: <EvidenceSinglePage />,
        path: adminRoutes.evidenceSinglePage,
        permissions: [PERMISSIONS_LIST.accessEvidence],
      },
      {
        component: <OwnersSinglePage />,
        path: adminRoutes.ownersSinglePage,
        permissions: [PERMISSIONS_LIST.accessOwners],
      },
      {
        component: <OwnersSinglePage leads />,
        path: adminRoutes.leadsSinglePage,
        permissions: [PERMISSIONS_LIST.accessLeads],
      },
      {
        component: <LeadsPage />,
        path: adminRoutes.leads,
        permissions: [PERMISSIONS_LIST.accessLeads],
      },
      {
        component: <DocumentsPage />,
        path: adminRoutes.documents,
        permissions: [PERMISSIONS_LIST.accessDocuments],
      },
      {
        component: <DocumentsSinglePage />,
        path: adminRoutes.documentsSinglePage,
        permissions: [PERMISSIONS_LIST.accessDocuments],
      },
      {
        component: <ProtestedAccountsPage />,
        path: adminRoutes.protestedAccounts,
        permissions: [PERMISSIONS_LIST.accessProtestedAccounts],
      },
      {
        component: <HearingDatesPages />,
        path: adminRoutes.hearingDates,
        permissions: [PERMISSIONS_LIST.accessHearingDates],
      },
      {
        component: <ArchivedPage />,
        path: adminRoutes.archived,
        permissions: [PERMISSIONS_LIST.accessArchived],
      },
      {
        component: <ArchivedSinglePage />,
        path: adminRoutes.archivedSinglePage,
        permissions: [PERMISSIONS_LIST.accessArchived],
      },
      {
        component: <ClientEvidencePage />,
        path: adminRoutes.clientEvidence,
        permissions: [PERMISSIONS_LIST.accessClientEvidence],
      },
      {
        component: <ClientEvidenceSinglePage />,
        path: adminRoutes.clientEvidenceSinglePage,
        permissions: [PERMISSIONS_LIST.accessClientEvidence],
      },
      {
        component: <AgentsPage />,
        path: adminRoutes.agents,
        permissions: [PERMISSIONS_LIST.accessAgents],
      },
      {
        component: <AgentSinglePage />,
        path: adminRoutes.agentSinglePage,
        permissions: [
          PERMISSIONS_LIST.agentActionTracker,
          PERMISSIONS_LIST.downloadActionCSV,
          PERMISSIONS_LIST.deleteUsers,
          PERMISSIONS_LIST.updateUsers,
        ],
      },
      {
        component: <SmsEligibilityPage />,
        path: adminRoutes.smsEligibility,
        permissions: [PERMISSIONS_LIST.accessSmsEligibility],
      },
    ],
  },
];
