import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Icons } from "assets/svgs";
import { Loader, PrimaryButton } from "common";
import { getInvoices } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { LOADING_STATUSES } from "utils/constants";

import * as S from "./styled";

export const InvoicesPage = () => {
  const { getInvoicesStatus, invoices } = useSelector(clientSelectors);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    dispatch(getInvoices());
  }, [dispatch]);

  useEffect(() => {
    if (location.state?.selectedInvoiceId) {
      setSelectedInvoiceId(location.state.selectedInvoiceId);
    }
  }, [location.state]);

  const handleInvoiceClick = (invoiceId) => {
    setSelectedInvoiceId(invoiceId === selectedInvoiceId ? null : invoiceId);
  };

  const handlePaymentLinkClick = (url) => {
    window.open(url, "_blank");
  };

  if (getInvoicesStatus !== LOADING_STATUSES.succeeded) {
    return (
      <S.LoaderBox>
        <Loader size={80} />
      </S.LoaderBox>
    );
  }

  return (
    <S.InvoicesScrollBlock>
      <S.InvoicesLinkWrapper>
        {invoices.length ? (
          invoices?.map((invoice) => (
            <S.InvoiceWidgetWrapper key={invoice.id}>
              <S.InvoiceLink className="text-green-700 text-base" onClick={() => handleInvoiceClick(invoice.id)}>
                <S.InvoiceContent isSelected={selectedInvoiceId === invoice.id}>
                  <S.InvoiceWrapper>
                    <S.InvoiceBlock>
                      <S.InvoicesIconBlock>
                        <Icons.InvoicesIcon />
                      </S.InvoicesIconBlock>
                      <S.InvoiceText>{invoice.name}</S.InvoiceText>
                    </S.InvoiceBlock>
                    {selectedInvoiceId !== invoice.id && (
                      <S.InvoicesStatus
                        href={invoice.payment_link}
                        status={invoice.status}
                        target="_blank"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (invoice.status === "open") {
                            e.preventDefault();
                            handlePaymentLinkClick(invoice.payment_link);
                          }
                        }}
                      >
                        {invoice.status === "open" ? "Pay" : "View receipt"}
                      </S.InvoicesStatus>
                    )}
                  </S.InvoiceWrapper>
                  <S.InvoiceDetails isVisible={selectedInvoiceId === invoice.id}>
                    <S.InvoiceDetailText className="mt-4">
                      Name: <S.Value>{invoice.name}</S.Value>
                    </S.InvoiceDetailText>
                    <S.InvoiceDetailText>
                      Status:
                      <S.Value className="status" status={invoice.status}>
                        {invoice.status}
                      </S.Value>
                    </S.InvoiceDetailText>

                    <S.InvoiceItems>
                      {invoice.items?.map((item, index) => (
                        <S.InvoiceItem key={index}>
                          <S.ItemDescription>{item.description}</S.ItemDescription>
                          <S.ItemAmount>
                            {item.amount.toLocaleString()} {item.currency.toUpperCase()}
                          </S.ItemAmount>
                        </S.InvoiceItem>
                      ))}
                      <S.InvoiceItem>
                        Total <S.InvoiceTotal>${invoice.total.toLocaleString()}</S.InvoiceTotal>
                      </S.InvoiceItem>
                    </S.InvoiceItems>
                    <S.ButtonContent className="flex justify-end w-full">
                      {invoice.status === "open" ? (
                        <PrimaryButton
                          label="Pay"
                          onClick={() => handlePaymentLinkClick(invoice.payment_link)}
                        ></PrimaryButton>
                      ) : <PrimaryButton
                        label="View receipt"
                        onClick={() => handlePaymentLinkClick(invoice.payment_link)}
                      ></PrimaryButton>}
                    </S.ButtonContent>
                  </S.InvoiceDetails>
                </S.InvoiceContent>
              </S.InvoiceLink>
            </S.InvoiceWidgetWrapper>
          ))
        ) : (
          <S.InvoicesMessageContent>
            <S.InvoicesText>You don't have any invoices yet.</S.InvoicesText>
          </S.InvoicesMessageContent>
        )}
      </S.InvoicesLinkWrapper>
    </S.InvoicesScrollBlock>
  );
};
