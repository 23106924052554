import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components/macro";

export const StyledAddPropertyContainer = styled.div`
  min-height: calc(100vh - 75px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledAddPropertyStepArea = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.primary};
  border-radius: 10px;
  background-color: ${COLORS.white};
  padding-inline: 16px;
  max-width: 600px;
  width: 100%;

  @media screen and (${BREAKPOINTS.md}) {
    padding-top: 20px;
  }

  @media screen and (${BREAKPOINTS.xl}) {
    padding-inline: 32px;
  }
`;

export const SubTitle = styled.p`
  font-weight: 600;
  margin-block: 10px;
  font-size: 20px;
`;

export const SubTitleMessage = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
`;

export const PropertyTitle = styled(SubTitle)`
  margin-block: 32px;
`;

export const Description = styled.p`
  font-size: 14px;
  margin-block: 4px;
`;

export const PropertyDescription = styled(Description)`
  margin-bottom: 32px;
`;

export const Box = styled.div`
  font-size: 14px;
  @media screen and (${BREAKPOINTS.md}) {
    font-size: 14px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: 16px;
  row-gap: 10px;
  align-items: center;

  button {
    width: 100%;
  }

  @media screen and (${BREAKPOINTS.md}) {
    flex-direction: row;
    column-gap: 10px;

    button {
      width: unset;
    }
  }
`;

export const SearchBoxContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 44px;
  width: 100%;
  background-color: ${COLORS.white};
  box-shadow: 0 3px 2px -2px ${COLORS.overlay_200}, 0 2px 2px 0 ${COLORS.overlay_150}, 0 1px 5px 0 ${COLORS.overlay_150};
`;

export const LoaderBox = styled.div`
  padding: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchBox = styled.div`
  cursor: default;
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px;
  border: 1px solid ${COLORS.grey};
  flex: 1 1 auto;
  font-size: 10px;
  min-height: 14px;
  min-width: 1px;

  &:hover {
    background-color: ${COLORS.overlay_060};
  }

  .street-address {
    font-weight: bold;
    font-size: 12px;
  }

  svg {
    width: 18px;
    fill: ${COLORS.darkgrey};
  }
`;

export const Form = styled.form``;

export const ErrorContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: 1px solid ${COLORS.red};
  border-radius: 10px;
  margin-block: 6px;
`;

export const Step2Header = styled.div`
  border: 1px solid ${COLORS.green};
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

export const Ul = styled.ul`
  list-style-type: none;
  margin-left: 20px;
`;

export const Li = styled.li`
  &::before {
    content: "";
    padding: 3px;
    margin-right: 10px;
    border-radius: 100%;
    background-color: ${COLORS.green};
    display: inline-block;
    margin-left: -1em;
  }
`;

export const PropertyInFormationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Span = styled.span``;

export const ViewDoc = styled.div`
  font-size: 16px;
  display: none;
  font-weight: bold;
  color: ${COLORS.green};
  text-decoration: underline;
  text-underline-offset: 8px;
  text-underline-color: ${COLORS.green};

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 20px;
    display: block;
  }
`;

export const preview = styled.span`
  cursor: pointer;
`;
export const DownloadDoc = styled(ViewDoc)`
  display: block;

  @media screen and (${BREAKPOINTS.md}) {
    display: none;
  }
`;

export const PdfLink = styled.a``;

export const NoResultBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  padding: 6px;
`;

export const NoResult = styled.div``;

export const InfoBlock = styled.div`
  width: 100px;

  @media screen and (${BREAKPOINTS.md}) {
    width: 30%;
  }
`;

export const Block = styled.div``;

export const PropertyInformation = styled.div`
  &.show {
    border-radius: 4px;
    padding: 22px;
    box-shadow: 0 2px 20px 3px #00000033, 0 2px 2px 0 #00000026, 0 1px 5px 0 #00000026;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const OwnerInformation = styled(PropertyInformation)``;

export const HeaderInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    height: max-content;
  }
`;
