import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

export const EvidenceContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EvidenceSection = styled.div`
  max-width: 600px;
  width: 100%;
  min-height: 390px;
  gap: 32px;
  padding: 16px;
  border: 1px solid ${COLORS.primary};
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  .submit-button {
    background-color: ${COLORS.primary};
  }

  @media screen and (${BREAKPOINTS.xl}) {
    min-height: 542px;
    padding: 32px;
    gap: 40px;
  }
`;

export const EvidenceContent = styled.div`
  width: 100%;
  min-height: 370px;
  gap: 20px;
  display: flex;
  flex-direction: column;

  .autocomplete {
    width: 100%;
  }


  @media screen and (${BREAKPOINTS.xl}) {
    gap: 24px;
    min-height: 310px;
  }
`;

export const EvidenceTitle = styled.div`
  min-height: 30px;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;

  @media screen and (${BREAKPOINTS.lg}) {
    height: 30px;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const UploadFileSection = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media screen and (${BREAKPOINTS.lg}) {
    justify-content: space-between;
  }

  @media screen and (${BREAKPOINTS.xl}) {
    height: 40px;
    justify-content: space-between;
  }
`;

export const UploadTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  min-width: 110px;
`;

export const FileInput = styled.input`
  display: none;
`;
export const UploadButton = styled.div`
  position: relative;
  display: flex;
  width: 150px;
  height: 40px;
  font-size: 14px;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  gap: 8px;
  padding: 6px 8px;
  border-radius: 4px;
  border: 1px solid ${COLORS.green};
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;

  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 10px #000;
  }

  @media screen and (${BREAKPOINTS.md}) {
    width: 164px;
    padding: 12px 16px;
  }
`;

export const UploadedFilesContainer = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const EvidenceFiles = styled.div`
  width: 100%;
`;
export const UploadFilesTitles = styled.div`
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
`;

export const SubmitEvidenceForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 18px;
  margin-top: 30px;

  .disable {
    pointer-events: none;
    opacity: 0.5;
    margin: auto;
    min-width: 280px;
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .not-disabled {
    margin: auto;
    min-width: 280px;
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload-button {
    display: flex;
    column-gap: 10px;
    align-items: center;
    max-width: 400px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid ${COLORS.green};
    background-color: ${COLORS.white};
  }

  .submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 230px;
    width: 100%;
  }

  .input-wrapper {
    & > div {
      min-width: unset !important;
    }
  }

  @media screen and (${BREAKPOINTS.md}) {
    gap: 10px;
  }
`;

export const FileUploadButton = styled.label`
  .invalid {
    color: ${COLORS.red};
    border: 1px solid ${COLORS.red};

    svg {
      path {
        stroke: ${COLORS.red};
      }
    }
  }
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 280px;
  gap: 10px;
  border-radius: 4px;
  max-height: 44px;
  border: 1px solid ${COLORS.green};
  padding: 10px;

  .close-button {
    cursor: pointer;
  }
`;
export const FileText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${COLORS.deepDark};
  text-align: center;
`;

export const UploadedFiles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media screen and (${BREAKPOINTS.xl}) {
    flex-direction: row;
  }

  .evidence-type {
    width: 100%;

    @media screen and (${BREAKPOINTS.md}) {
      width: 350px;
    }
  }
`;

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (${BREAKPOINTS.xl}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const LoaderWrapper = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
`;
