import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.overlay_300};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const Content = styled.div`
  width: 90%;
  padding: 20px 30px;
  background-color: ${COLORS.white};
  border-radius: 4px;
  display: grid;
  flex-direction: column;
  gap: 20px;

  @media screen and (${BREAKPOINTS.md}) {
    padding: 40px 64px;
    width: 580px;
    margin-left: 312px;
  }

  &.first-animation {
    animation: 0.3s first-animation;

    @keyframes first-animation {
      from {
        transform: scale(0.4);
      }
      to {
        transform: scale(1);
      }
    }
  }

  &.second-animation {
    animation: 0.2s second-animation;
    animation-fill-mode: both;

    @keyframes second-animation {
      from {
        transform: scale(1.05);
      }
      to {
        transform: scale(1);
      }
    }
  }
`;

export const BoxCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconWrapper = styled.div`
  border-radius: 50%;
  background-color: ${COLORS.green};
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Description = styled.div`
  text-align: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 75%;
`;
