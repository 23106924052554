import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink, Outlet, useLocation } from "react-router-dom";
import { Can } from "@casl/react";
import { Icons } from "assets/svgs";
import { usePermissions } from "hooks/usePermissions";
import { adminRoutes } from "router/routes";
import { getUsersInfo } from "store/admin-slices/signin-slice/actions";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { adminSignInActions } from "store/admin-slices/signin-slice/slice";
import { LOADING_STATUSES, PERMISSIONS_LIST } from "utils/constants";
import { getStorage } from "utils/helpers";

import NavigationBar from "components/Admin/NavigationBar";
import { AdminNotification } from "components/AdminNotification";

import {
  AdminHeader,
  AdminLayoutContainer,
  AdminNavigationBar,
  AvatarContainer,
  AvatarIconContainer,
  NotificationCount,
  NotificationIcon,
  RightSectionContainer,
  UserName,
} from "./styled";

export const AdminLayout = () => {
  const { adminSignInResponse, userInfo, adminNotifications, readAllNotificationsStatus } =
    useSelector(adminSignInSelectors);

  const [showSidebar, setShowSidebar] = useState(false);

  const ability = usePermissions(userInfo?.permissions);

  const dispatch = useDispatch();

  const location = useLocation();

  const handleNotificationClick = () => {
    setShowSidebar(true);
  };

  const handleCloseSidebar = () => {
    setShowSidebar(false);
  };

  useEffect(() => {
    if (getStorage("credentials") && !adminSignInResponse?.access_token) {
      dispatch(getUsersInfo());
    }
  }, []);

  useEffect(() => {
    if (ability.can(PERMISSIONS_LIST.seeNotifications)) {
      dispatch(adminSignInActions.getNotifications());
    }
  }, [ability.can(PERMISSIONS_LIST.seeNotifications)]);

  useEffect(() => {
    if (readAllNotificationsStatus === LOADING_STATUSES.succeeded && ability.can(PERMISSIONS_LIST.seeNotifications)) {
      dispatch(adminSignInActions.getNotifications());
    }
  }, [readAllNotificationsStatus, ability.can(PERMISSIONS_LIST.seeNotifications)]);

  if (!userInfo && getStorage("credentials")) {
    return null;
  }

  if (!userInfo) {
    return <Navigate state={{ from: location }} to={adminRoutes.signIn} />;
  }

  return (
    <AdminLayoutContainer>
      <AdminNavigationBar>
        <NavigationBar />
      </AdminNavigationBar>
      <RightSectionContainer>
        <AdminHeader>
          <NavLink to="/">PTP</NavLink>
          <AvatarContainer>
            <Can ability={ability} I={PERMISSIONS_LIST.seeNotifications}>
              <NotificationIcon onClick={handleNotificationClick}>
                <Icons.NotificationIcon />
                {adminNotifications.filter((notification) => !notification.read_at).length > 0 && (
                  <NotificationCount>
                    {adminNotifications.filter((notification) => !notification.read_at).length}
                  </NotificationCount>
                )}
              </NotificationIcon>
            </Can>
            <UserName>{userInfo.name}</UserName>
            <AvatarIconContainer>
              <Icons.AvatarIcon />
            </AvatarIconContainer>
          </AvatarContainer>
        </AdminHeader>
        <Outlet />
      </RightSectionContainer>
      <AdminNotification notifications={adminNotifications} showSidebar={showSidebar} onClose={handleCloseSidebar} />
    </AdminLayoutContainer>
  );
};
