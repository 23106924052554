import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { COLORS } from "assets/styles/colors";
import { Icons } from "assets/svgs";
import { PrimaryButton } from "common";
import { clientRoutes } from "router/routes";
import { clientSelectors } from "store/client-slice/selectors";
import { ownerPortalPropertyActions } from "store/owner-portal-property-slice/slice";
import { toggleClassOnRoot } from "utils/helpers";

import * as S from "./styled";

export const SuccessModal = ({id}) => {
  const { account } = useSelector(clientSelectors);

  const [firstAnimation, setFirstAnimation] = useState(true);
  const [secondAnimation, setSecondAnimation] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAnimate = () => setSecondAnimation(true);

  const handleAnimationEnd = () => {
    setFirstAnimation(false);
    setSecondAnimation(false);
  };

  window.dataLayer.push({
    event: "user_email",
    email: account?.email,
  });

  const handleExit = () => {
    toggleClassOnRoot();
    dispatch(ownerPortalPropertyActions.setIsOpenSuccessModal(false));
    navigate(`${clientRoutes.prefix}${clientRoutes.properties}?id=${id}&should-scroll=true`);
  };

  return (
    <S.Container onClick={handleAnimate}>
      <S.Content
        className={`${secondAnimation ? "second-animation" : ""} ${firstAnimation ? "first-animation" : ""}`}
        onAnimationEnd={handleAnimationEnd}
        onClick={(e) => e.stopPropagation()}
      >
        <S.BoxCenter>
          <S.IconWrapper>
            <Icons.CheckIcon size={45} />
          </S.IconWrapper>
        </S.BoxCenter>
        <S.BoxCenter>
          <S.Description>You have successfully signed the document!</S.Description>
        </S.BoxCenter>
        <S.BoxCenter>
            <PrimaryButton
              eventHandler={handleExit}
              label="exit"
              styles={{
                backgroundColor: COLORS.white,
                color: COLORS.dark,
                border: `1px solid ${COLORS.dark}`,
                height: 50,
              }}
            />
        </S.BoxCenter>
      </S.Content>
    </S.Container>
  );
};
