import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton, Table } from "common";
import { adminRoutes } from "router/routes";
import { singleArchivedSelectors } from "store/admin-slices/single-archived-slice/selectors";
import { isLoading } from "utils/constants";
import { generateAddressLink } from "utils/helpers";

import { Template } from "components/Admin/TableRowCustomTemplate";

import * as S from "./styled";

export const Properties = () => {
  const { singleArchived, updateAccountNumberStatus, updateStreetAddressStatus } = useSelector(singleArchivedSelectors);

  const [clickedRowId, setCheckedRowId] = useState(null);

  const { selectedPropertyId } = useParams();
  const navigate = useNavigate();

  const sortedProperties = [...singleArchived.properties].sort((propA, propB) =>
    propA.is_archived === 1 ? 1 : propB.is_archived === 1 ? -1 : 0
  );

  const selectedProperty = singleArchived.properties.find((prop) => prop.id === Number(selectedPropertyId));

  const rowClassName = (row) =>
    row.id === selectedProperty?.id && row.is_archived !== 1
      ? "selected"
      : clickedRowId === row.id
      ? "checked-row"
      : row.is_archived === 1
      ? "archived"
      : "";
  const handleRowCheck = (id) => {
    setCheckedRowId(id);
  };

  const handleSelectProperty = (item) => {
    navigate(`${adminRoutes.prefix}/${adminRoutes.archived}/${singleArchived.id}/${item.id}`);
  };

  const columnDataArr = useMemo(
    () => [
      { field: "county", header: "County" },
      {
        header: "County link",
        body: (item) => (
          <S.Link href={generateAddressLink(item)} target="_blank" onClick={() => handleRowCheck(item.id)}>
            View On CAD Website
          </S.Link>
        ),
      },
      {
        field: "street_address",
        header: "Street Address",
        body: (item) => (
          <S.StreetAddressContainer>
            <>
              <S.StreetEllipsis title={item.street_address}>{item.street_address}</S.StreetEllipsis>
            </>
          </S.StreetAddressContainer>
        ),
      },
      {
        field: "account_number",
        header: "Account Number",
        body: (item) => (
          <S.AccountNumberContainer>
            <S.AccountNumber>{item.account_number}</S.AccountNumber>
          </S.AccountNumberContainer>
        ),
      },
      { field: "current_year_market_value", header: "LY value" },
      { field: "previous_year_market_value", header: "CY value" },
      {
        sortable: true,
        body: Template.AuthColumn,
        header: "Auth?",
        field: "authed_or_not",
      },
      {
        header: " ",
        body: (item) => (
          <S.RemoveContainer>
            <PrimaryButton
              className="select-button"
              eventHandler={() => handleSelectProperty(item)}
              label="select this property"
            />
          </S.RemoveContainer>
        ),
      },
    ],
    []
  );

  if (!singleArchived.properties.length) {
    return null;
  }

  return (
    <S.Container>
      <S.Content>
        <S.ContentHeader>Properties</S.ContentHeader>
        <Table
          paginator
          dataKey="id"
          loading={isLoading(updateAccountNumberStatus) || isLoading(updateStreetAddressStatus)}
          rowClassName={rowClassName}
          rows={10}
          tableData={columnDataArr}
          value={sortedProperties}
        />
      </S.Content>
    </S.Container>
  );
};
