import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Can } from "@casl/react";
import { COLORS } from "assets/styles/colors";
import { Icons } from "assets/svgs";
import { Loader, PrimaryButton, PrimaryInput, Table } from "common";
import { usePermissions } from "hooks/usePermissions";
import { adminRoutes } from "router/routes";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";
import { isLoading, LOADING_STATUSES, PERMISSIONS_LIST } from "utils/constants";
import { generateAddressLink } from "utils/helpers";

import { Template } from "components/Admin/TableRowCustomTemplate";
import { ArchiveModal } from "components/ArchiveModal";
import { Modal } from "components/Modal";

import * as S from "./styled";

export const Properties = ({ leads }) => {
  const {
    singleOwner,
    deletePropertyStatus,
    archivePropertyStatus,
    isDeletePropertyModal,
    propertyId,
    updateAccountNumberStatus,
    updateStreetAddressStatus,
  } = useSelector(singleOwnerSelectors);
  const { userInfo } = useSelector(adminSignInSelectors);

  const [clickedRowId, setCheckedRowId] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isArchived, setIsArchived] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const [accountNumbers, setAccountNumbers] = useState({});
  const [editingStreetAddressId, setEditingStreetAddressId] = useState(null);
  const [streetAddresses, setStreetAddresses] = useState({});
  const [lastUpdatedAccountNumbers, setLastUpdatedAccountNumbers] = useState({});
  const [lastUpdatedStreetAddresses, setLastUpdatedStreetAddresses] = useState({});

  const navigate = useNavigate();

  const { selectedPropertyId } = useParams();

  const dispatch = useDispatch();

  const ability = usePermissions(userInfo.permissions);

  const sortedProperties = [...singleOwner.properties].sort((propA, propB) =>
    propA.is_archived === 1 ? 1 : propB.is_archived === 1 ? -1 : 0
  );

  const selectedProperty = singleOwner.properties.find((prop) => prop.id === Number(selectedPropertyId));

  const isPendingDeleteProperty = (id) => isLoading(deletePropertyStatus) && selectedRowId === id;

  const rowClassName = (row) =>
    row.id === selectedProperty?.id && row.is_archived !== 1
      ? "selected"
      : clickedRowId === row.id
      ? "checked-row"
      : row.is_archived === 1
      ? "archived"
      : "";

  const handleSelectProperty = (item) => {
    dispatch(singleOwnerActions.setSelectedPropertyId(item.id));
    navigate(`${adminRoutes.prefix}/${adminRoutes.owners}/${singleOwner.id}/${item.id}`);
  };

  const handleRemovePropertyClick = () => {
    setSelectedRowId(propertyId);
    const data = {
      owner_id: singleOwner.id,
      property_id: propertyId,
      is_manually: 0,
    };

    dispatch(singleOwnerActions.deleteProperty(data));
  };

  const handleRowCheck = (id) => {
    setCheckedRowId(id);
  };

  const handleOpenModal = (id, isArchived) => {
    setIsOpenModal(true);
    dispatch(singleOwnerActions.setPropertyId(id));
    setIsArchived(isArchived);
  };

  const handleOpenDeleteModal = (id) => {
    dispatch(singleOwnerActions.setPropertyId(id));
    dispatch(singleOwnerActions.setIsDeletePropertyModal(true));
  };

  const handleArchiveProperty = (params) => {
    dispatch(singleOwnerActions.archiveProperty(params));
  };

  const handleOpenEditInput = (id) => {
    setEditingRowId(id);

    if (!accountNumbers[id]) {
      setAccountNumbers((prevAccountNumbers) => ({
        ...prevAccountNumbers,
        [id]: singleOwner.properties.find((property) => property.id === id).account_number,
      }));
    }
  };

  const handleOpenEditStreetAddress = (id) => {
    setEditingStreetAddressId(id);

    if (!streetAddresses[id]) {
      setStreetAddresses((prevStreetAddresses) => ({
        ...prevStreetAddresses,
        [id]: singleOwner.properties.find((property) => property.id === id).street_address,
      }));
    }
  };

  const handleSaveAccountNumber = (id) => {
    setEditingRowId(null);
    if (!accountNumbers[id]?.trim()) {
      setAccountNumbers((prevAccountNumbers) => ({
        ...prevAccountNumbers,
        [id]: lastUpdatedAccountNumbers[id],
      }));
      return;
    }

    const params = {
      account_number: accountNumbers[id],
      property_id: id,
    };

    dispatch(singleOwnerActions.updateAccountNumber(params));
    setEditingRowId(null);
    setLastUpdatedAccountNumbers((prev) => ({
      ...prev,
      [id]: accountNumbers[id],
    }));
  };

  const handleSaveStreetAddress = (id) => {
    setEditingStreetAddressId(null);
    if (!streetAddresses[id]?.trim()) {
      setStreetAddresses((prevStreetAddresses) => ({
        ...prevStreetAddresses,
        [id]: lastUpdatedStreetAddresses[id],
      }));
      return;
    }

    const params = {
      street_address: streetAddresses[id],
      property_id: id,
    };

    dispatch(singleOwnerActions.updateStreetAddress(params));
    setEditingStreetAddressId(null);
    setLastUpdatedStreetAddresses((prev) => ({
      ...prev,
      [id]: streetAddresses[id],
    }));
  };

  const columnDataArr = useMemo(
    () => [
      { field: "county", header: "County" },
      {
        header: "County link",
        body: (item) => (
          <S.Link href={generateAddressLink(item)} target="_blank" onClick={() => handleRowCheck(item.id)}>
            View On CAD Website
          </S.Link>
        ),
      },
      {
        field: "street_address",
        header: "Street Address",
        body: (item) => (
          <S.StreetAddressContainer>
            {editingStreetAddressId === item.id ? (
              <>
                <S.StreetEllipsis>
                  <PrimaryInput
                    value={streetAddresses[item.id] || ""}
                    onChange={(e) => setStreetAddresses({ ...streetAddresses, [item.id]: e.target.value })}
                  />
                  {!streetAddresses[item.id]?.trim() && (
                    <S.StreetAddressErrorMessage>Field is required</S.StreetAddressErrorMessage>
                  )}
                </S.StreetEllipsis>
                {streetAddresses[item.id]?.trim() ? (
                  <Icons.CheckIcon color={COLORS.green} onClick={() => handleSaveStreetAddress(item.id)} />
                ) : (
                  <Icons.CloseIcon color={COLORS.red} onClick={() => handleSaveStreetAddress(item.id)} />
                )}
              </>
            ) : (
              <>
                <S.StreetAddress title={streetAddresses[item.id] || item.street_address}>
                  <p className="max-w-[224px] whitespace-nowrap text-ellipsis overflow-hidden block">
                    {streetAddresses[item.id] || item.street_address}
                  </p>
                </S.StreetAddress>
                <Icons.EditIcon onClick={() => handleOpenEditStreetAddress(item.id)} />
              </>
            )}
          </S.StreetAddressContainer>
        ),
      },
      {
        field: "account_number",
        header: "Account Number",
        body: (item) => (
          <S.AccountNumberContainer>
            {editingRowId === item.id ? (
              <>
                <S.AccountNumberInputContainer>
                  <PrimaryInput
                    value={accountNumbers[item.id] || ""}
                    onChange={(e) => setAccountNumbers({ ...accountNumbers, [item.id]: e.target.value })}
                  />
                  {!accountNumbers[item.id]?.trim() && (
                    <S.AccountNumberErrorMessage>Field is required</S.AccountNumberErrorMessage>
                  )}
                </S.AccountNumberInputContainer>
                {accountNumbers[item.id]?.trim() ? (
                  <Icons.CheckIcon color={COLORS.green} onClick={() => handleSaveAccountNumber(item.id)} />
                ) : (
                  <Icons.CloseIcon color={COLORS.red} onClick={() => handleSaveAccountNumber(item.id)} />
                )}
              </>
            ) : (
              <>
                <S.AccountNumber>{accountNumbers[item.id] || item.account_number}</S.AccountNumber>
                <Icons.EditIcon onClick={() => handleOpenEditInput(item.id)} />
              </>
            )}
          </S.AccountNumberContainer>
        ),
      },
      { field: "previous_year_market_value", header: "LY value" },
      { field: "current_year_market_value", header: "CY value" },
      {
        sortable: true,
        body: Template.AuthColumn,
        header: "Auth?",
        field: "authed_or_not",
      },
      {
        header: (
          <Can ability={ability} I={PERMISSIONS_LIST.archiveSingleProperty}>
            {(allowed) => (allowed ? "Archive" : null)}
          </Can>
        ),
        body: (item) => (
          <Can ability={ability} I={PERMISSIONS_LIST.archiveSingleProperty}>
            {(allowed) =>
              allowed ? (
                <S.ArchiveContainer>
                  {item.is_archived === 0 ? (
                    <Icons.ArchivedIcon onClick={() => handleOpenModal(item.id, item.is_archived)} />
                  ) : (
                    <Icons.UnArchivedIcon onClick={() => handleOpenModal(item.id, item.is_archived)} />
                  )}
                </S.ArchiveContainer>
              ) : null
            }
          </Can>
        ),
      },
      {
        header: "Remove",
        body: (item) => (
          <S.RemoveContainer>
            <S.RemoveButtonContainer>
              {isPendingDeleteProperty(item.id) ? (
                <Loader />
              ) : (
                <Can ability={ability} I={PERMISSIONS_LIST.deleteSingleProperty}>
                  <Icons.TrashIcon
                    onClick={() => {
                      handleOpenDeleteModal(item.id);
                    }}
                  />
                </Can>
              )}
            </S.RemoveButtonContainer>
            {!leads && item.is_archived === 0 && (
              <>
                |
                <PrimaryButton
                  className="select-button"
                  eventHandler={() => handleSelectProperty(item)}
                  label="select this property"
                />
              </>
            )}
          </S.RemoveContainer>
        ),
      },
    ],
    [isPendingDeleteProperty]
  );

  useEffect(() => {
    if (selectedProperty && selectedProperty.is_archived === 1) {
      const firstNonArchivedProperty = singleOwner.properties.find((item) => item.is_archived === 0);

      if (firstNonArchivedProperty) {
        navigate(`${adminRoutes.prefix}/${adminRoutes.owners}/${singleOwner.id}/${firstNonArchivedProperty.id}`);
      }
    }
  }, [selectedProperty, singleOwner.properties, navigate]);

  useEffect(() => {
    if (archivePropertyStatus === LOADING_STATUSES.succeeded) {
      setIsOpenModal(false);
    }
  }, [archivePropertyStatus]);

  useEffect(() => {
    if (updateAccountNumberStatus === LOADING_STATUSES.succeeded) {
      dispatch(singleOwnerActions.resetAccountNumberStatus());
    }
  }, [updateAccountNumberStatus]);

  useEffect(() => {
    if (updateStreetAddressStatus === LOADING_STATUSES.succeeded) {
      dispatch(singleOwnerActions.resetStreetAddressStatus());
    }
  }, [updateStreetAddressStatus]);

  useEffect(() => {
    if (deletePropertyStatus === LOADING_STATUSES.succeeded) {
      dispatch(singleOwnerActions.setIsDeletePropertyModal(false));
    }
  }, [deletePropertyStatus]);

  useEffect(() => {
    dispatch(singleOwnerActions.setSelectedPropertyId(selectedPropertyId));
  }, []);

  if (!singleOwner.properties.length) {
    return null;
  }

  return (
    <S.Container>
      <S.Content>
        <S.ContentHeader>Properties</S.ContentHeader>
        <Table
          paginator
          dataKey="id"
          loading={isLoading(updateAccountNumberStatus) || isLoading(updateStreetAddressStatus)}
          rowClassName={rowClassName}
          rows={10}
          tableData={columnDataArr}
          value={sortedProperties}
        />
      </S.Content>
      <Modal
        cancelButtonText="no"
        className="delete-modal"
        description="Are you sure ?"
        isModalOpen={isDeletePropertyModal}
        successButtonText="yes"
        successLoadingStatus={isLoading(deletePropertyStatus)}
        onCancel={() => dispatch(singleOwnerActions.setIsDeletePropertyModal(false))}
        onSuccess={handleRemovePropertyClick}
      />
      <ArchiveModal
        handleArchiveDispatch={handleArchiveProperty}
        isArchived={isArchived}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        singleArchiveId={propertyId}
        singleArchiveStatus={archivePropertyStatus}
      />
    </S.Container>
  );
};
