import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton, PrimaryTextarea } from "common";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";
import { notesSchema } from "validations";

import * as S from "./styled";

export const Notes = ({ isAdminNote, isOwnerNote, title, ownerPropertyId }) => {
  const { adminNotes, ownerNotes } = useSelector(singleOwnerSelectors);

  const { selectedPropertyId } = useParams();

  const dispatch = useDispatch();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(notesSchema),
    defaultValues: {
      notes: "",
    },
  });

  const onSubmit = (data) => {
    dispatch(
      singleOwnerActions.postNote({
        property_id: ownerPropertyId ? ownerPropertyId : selectedPropertyId,
        notes: data.notes,
        is_owner: isAdminNote ? 0 : 1,
      })
    );
  };

  useEffect(() => {
    if (isAdminNote) {
      reset({ notes: adminNotes?.notes || "" });
    } else if (isOwnerNote) {
      reset({ notes: ownerNotes?.notes || "" });
    }

    if (!ownerNotes?.notes) {
      reset({ notes: "" });
    }
  }, [adminNotes, ownerNotes, isAdminNote, isOwnerNote, reset]);

  return (
    <S.Container>
      <S.Content>
        <S.ContentTitle>{title}</S.ContentTitle>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <Controller control={control} name="notes" render={({ field }) => <PrimaryTextarea {...field} />} />
          <PrimaryButton label="Update Notes" mode="gray" type="submit" />
        </S.Form>
      </S.Content>
    </S.Container>
  );
};
