import { BREAKPOINTS } from "assets/styles/breakpoints";
import styled from "styled-components";

export const PropertyBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  svg {
    margin-right: 8px;
  }

  :nth-last-child(1) {
    margin-left: 8px;
    cursor: pointer;
  }
`;

export const PropertyInfoBox = styled.div`
  @media screen and (${BREAKPOINTS.md}) {
    width: unset;
  }
`;

export const RemoveIconBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
