import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const EvidenceTableContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .p-datatable-frozen-tbody {
    position: sticky;
    top: 56px !important;

    tr > td {
      border: 1px solid ${COLORS.overlay_30} !important;
    }

    & > tr {
      background-color: ${COLORS.skyBlue} !important;

      .p-checkbox {
        display: none;
      }
    }
  }

  .p-datatable-thead {
    height: 60px;
  }

  .column {
    padding: 8px !important;

    .p-column-header-content {
      margin: 0 auto;
      width: fit-content;
    }
  }

  .column div {
    white-space: nowrap;
    justify-content: center;
  }

  table {
    border-collapse: unset !important;
  }

  .p-icon {
    width: 10px;
    height: 10px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FilterRightButtons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  height: 60px;

  .search-input {
    margin-left: 20px;
  }
`;

export const Box = styled.div`
  transition: 0.5s;

  &.flex-center {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    flex-wrap: wrap;
  }
`;

export const ResidentialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: 10px;
  padding-inline: 16px;
`;

export const PaginatorWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-content: center;
  justify-content: center;
`;

export const TableContainer = styled.div`
  position: relative;
  border-top: 1px solid ${COLORS.overlay_30};
  border-bottom: 1px solid ${COLORS.overlay_30};

  table > thead {
    position: sticky;
    top: -1px !important;
  }
`;

export const Span = styled.span`
  font-weight: bold;
`;

export const FiltersButtonsBlock = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const FilterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const DraggableColumn = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
