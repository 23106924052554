import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { singleEvidenceSelectors } from "store/admin-slices/single-evidence-slice/selectors";
import { singleEvidenceActions } from "store/admin-slices/single-evidence-slice/slice";
import { LOADING_STATUSES, RESIDENT_TYPES } from "utils/constants";

import { EvidenceSideBar } from "./EvidenceSideBar";
import { EvidenceTable } from "./EvidenceTable";
import { SettingsModal } from "./SettingsModal";
import * as S from "./styled";

export const EvidenceSinglePage = () => {
  const { isOpenSettingsModal, savedFilters, residentType, getEvidencesEquitiesStatus, getEvidencesSalesStatus } =
    useSelector(singleEvidenceSelectors);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(singleEvidenceActions.resetAllData());
  }, []);

  const { propertyId } = useParams();

  useEffect(() => {
    if (
      residentType === "sales"
        ? getEvidencesSalesStatus === LOADING_STATUSES.succeeded
        : getEvidencesEquitiesStatus === LOADING_STATUSES.succeeded
    ) {
      const interval = 20000;

      const newFilters = {};
      if (savedFilters && typeof savedFilters === "object") {
        Object.keys(savedFilters).forEach((key) => {
          newFilters[key] = Array.isArray(savedFilters[key])
            ? savedFilters[key]
                .map((filter) => (typeof filter !== "object" && filter !== null ? filter : null))
                .filter((item) => item && item)
            : savedFilters[key];
        });
      }

      const saveFilters = () => {
        dispatch(
          singleEvidenceActions.saveFilters({
            is_sale: Number(residentType === RESIDENT_TYPES.sales),
            filters: { ...newFilters },
            property_id: propertyId,
          })
        );
      };

      const intervalId = setInterval(saveFilters, interval);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [dispatch, residentType, savedFilters, propertyId, getEvidencesEquitiesStatus, getEvidencesSalesStatus]);

  return (
    <S.EvidenceContainer>
        <>
          <EvidenceSideBar />
          <EvidenceTable />
          {isOpenSettingsModal && <SettingsModal />}
        </>
    </S.EvidenceContainer>
  );
};
