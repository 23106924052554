export const AccountIcon = (props) => (
  <svg fill="none" height="20" viewBox="0 0 16 20" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M15.5806 14.71L15.8206 15.85C16.048 16.8356 15.8235 17.8713 15.2082 18.6742C14.593 19.4771 13.6514 19.9633 12.6406 20H3.36058C2.34976 19.9633 1.40815 19.4771 0.792929 18.6742C0.177708 17.8713 -0.046851 16.8356 0.180583 15.85L0.420583 14.71C0.696618 13.1668 2.0232 12.0327 3.59058 12H12.4106C13.978 12.0327 15.3045 13.1668 15.5806 14.71ZM12.6406 18.49C13.1484 18.4841 13.6263 18.2489 13.9406 17.85V17.86C14.3263 17.3762 14.4765 16.7458 14.3506 16.14L14.1106 15C13.9774 14.1552 13.2652 13.5226 12.4106 13.49H3.59059C2.73595 13.5226 2.02375 14.1552 1.89059 15L1.65059 16.14C1.52774 16.7426 1.67785 17.3687 2.06059 17.85C2.37492 18.2489 2.8528 18.4841 3.36059 18.49H12.6406Z"
      fill="black"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.50057 10H7.50057C5.29143 10 3.50057 8.20915 3.50057 6.00001V3.36001C3.4979 2.46807 3.85104 1.61189 4.48175 0.981192C5.11245 0.350491 5.96863 -0.00265152 6.86057 1.49917e-05H9.14057C10.0325 -0.00265152 10.8887 0.350491 11.5194 0.981192C12.1501 1.61189 12.5032 2.46807 12.5006 3.36001V6.00001C12.5006 8.20915 10.7097 10 8.50057 10ZM6.86057 1.50002C5.83332 1.50002 5.00057 2.33277 5.00057 3.36001V6.00001C5.00057 7.38073 6.11986 8.50001 7.50057 8.50001H8.50057C9.88128 8.50001 11.0006 7.38073 11.0006 6.00001V3.36001C11.0006 2.86671 10.8046 2.39361 10.4558 2.0448C10.107 1.69598 9.63387 1.50002 9.14057 1.50002H6.86057Z"
      fill="black"
      fillRule="evenodd"
    />
  </svg>
);
