import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

export const NotificationContainer = styled.div`
  position: absolute;
  background-color: white;
  padding: 20px;
  max-width: 600px;
  width: calc(100% - 80px);
  border-radius: 4px;
  height: 100%;
  right: 0;
  top: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  transform: ${({ show }) => (show === "true" ? "translateX(0)" : "translateX(200%)")};

  @media screen and (${BREAKPOINTS.md}) {
    width: calc(100% - 80px);
  }

  @media screen and (${BREAKPOINTS.lg}) {
    width: calc(100% - 32px);
  }

  .close-icon {
    min-width: 16px;
  }
`;

export const SidebarOverlay = styled.div`
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: flex-start;
  align-items: center;
  display: flex;
  pointer-events: ${({ show }) => (show === "true" ? "auto" : "none")};
  opacity: ${({ show }) => (show === "true" ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
`;

export const SidebarContent = styled.div`
  width: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 6px;
`;

export const Notifications = styled.div`
  overflow-y: auto;
  gap: 16px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 160px);
  padding-inline: 6px;
  overflow-x: hidden;
`;

export const NotificationContent = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border: 1px solid ${COLORS.green};
  border-radius: 10px;
  background-color: ${({ read }) => (read === "true" ? COLORS.grey : COLORS.white)};
  font-weight: ${({ read }) => (read === "true" ? "normal" : "bold")};
  cursor: pointer;
`;

export const Title = styled.h2`
  font-size: 18px;
  text-align: center;
  font-weight: 600;
`;

export const EmptyNotification = styled.h2``;
