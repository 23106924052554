import { NavLink } from "react-router-dom";
import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const AppealsScrollBlock = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: auto;
  width: 100%;
  padding-block: 20px;

  .appeal-accordion {
    background: ${COLORS.overlay_060};
    border-radius: 10px;
    padding-inline: 16px;
    align-self: center;
    width: 100%;
    padding-block: 16px;

    @media screen and (${BREAKPOINTS.md}) {
      width: 100%;
    }

    @media screen and (${BREAKPOINTS.lg}) {
    width: 100%;
      padding-inline: 32px;
    }

    @media screen and (${BREAKPOINTS.xl}) {
      width: 80%;
    }

    button {
      border-bottom-width: inherit;
      cursor: default;
    }

    span {
      cursor: pointer;
    }
  }
`;

export const AppealsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  color: ${COLORS.deepDark};
  padding-inline: 16px;
  padding-block: 20px 100px;
  align-self: center;
  width: 100%;
  overflow-x: scroll;
`;

export const AppealIconWrapper = styled.div`
  width: 28px;
  height: 28px;
`;
export const AppealsContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LoaderBox = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
`;

export const AppealsSubTitle = styled.span`
  color: ${COLORS.green_300};
  font-weight: 600;
`;

export const AppealsHeading = styled.h1`
  text-align: center;
  font-size: 22px;
  font-weight: 600;
`;

export const AppealsColoredSpan = styled.span`
  color: ${COLORS.green_300};
`;

export const Link = styled(NavLink)`
  color: ${COLORS.deepDark};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;

  &:hover {
    text-decoration: underline ${COLORS.green_300};
  }

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 16px;
    width: max-content;
  }

  @media screen and (${BREAKPOINTS.xl}) {
    font-size: 18px;
  }
`;
