import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Can } from "@casl/react";
import { AdminReloadButton, FilterMultiSelect, PrimaryButton, PrimaryInput, SearchInput, Select, Table } from "common";
import { useDebounce } from "hooks";
import { usePermissions } from "hooks/usePermissions";
import { Paginator } from "primereact/paginator";
import { hearingDatesActions } from "store/admin-slices/hearing-dates-slice/slice";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { smsEligibilitySelector } from "store/admin-slices/sms-eligibility-slice/selectors";
import { smsEligibilityActions } from "store/admin-slices/sms-eligibility-slice/slice";
import { COUNTIES_FILTER, isLoading, LOADING_STATUSES, PERMISSIONS_LIST, YEARS_FILTER } from "utils/constants";
import { downloadFileByLink, getPaginationDescription } from "utils/helpers";

import { Filter } from "components/Filter";

import * as S from "./styled";

const roleOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Owner",
    value: 0,
  },
  {
    label: "Lead",
    value: 1,
  },
];
export const SmsEligibilityPage = () => {
  const {
    smsEligibilityData,
    paginationData,
    last_page,
    getSmsEligibilityStatus,
    exportSmsEligibilityHref,
    exportSmsEligibilityStatus,
  } = useSelector(smsEligibilitySelector);
  const { userInfo } = useSelector(adminSignInSelectors);

  const [selectedCountyOptions, setSelectedCountyOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [roleSelectValue, setRoleSelectValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [csvDownloadType, setCsvDownloadType] = useState(null);
  const debouncedSearchQuery = useDebounce(searchQuery, 200);
  const [startDate, setStartDate] = useState("");
  const debouncedStartDate = useDebounce(startDate, 500);
  const [endDate, setEndDate] = useState("");
  const debouncedEndDate = useDebounce(endDate, 500);

  const [sortData, setSortData] = useState({
    field: "sign_up_date",
    direction: -1,
  });

  const ability = usePermissions(userInfo.permissions);

  const ref = useRef(null);

  const dispatch = useDispatch();

  const tableData = useMemo(
    () => [
      {
        headerClassName: "column-header",
        selectionMode: "multiple",
      },
      { sortable: true, header: "Sign up date", field: "sign_up_date" },
      { sortable: true, header: "Email", field: "email" },
      {
        sortable: true,
        header: "Name",
        field: "first_name",
        body: (item) => (
          <S.Paragraph>
            {item.first_name} {item.last_name}
          </S.Paragraph>
        ),
      },
      { sortable: true, header: "Phone", field: "phone_number" },
      {
        sortable: true,
        header: "Owner/Lead",
        field: "is_lead",
        body: (item) => <S.Paragraph>{item.is_lead ? "Lead" : "Owner"}</S.Paragraph>,
      },
    ],
    []
  );

  const selectedRole = roleOptions.find((item) => item.label === roleSelectValue);

  const getSmsEligibilityList = (props) => {
    const params = {
      page: props?.page ?? paginationData.page,
      limit: props?.rows ?? paginationData.rows,
      search: debouncedSearchQuery,
      years: selectedYear,
      counties: selectedCountyOptions,
      start_date: debouncedStartDate,
      end_date: debouncedEndDate,
      is_lead: selectedRole ? selectedRole.value : "",
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "ASC" : "DESC",
    };

    dispatch(smsEligibilityActions.getSmsEligibilityData(params));
  };

  const onPageChange = ({ first, rows, page }) => {
    dispatch(
      smsEligibilityActions.setPaginationData({
        first,
        rows,
        page: page + 1,
      })
    );

    getSmsEligibilityList({ page: page + 1, rows });
  };

  const onSearch = (searchResult) => {
    setSearchQuery(searchResult);
  };

  const onSort = (e) => setSortData({ field: e.sortField, direction: e.sortOrder });

  const deSelectYears = () => {
    if (selectedYear?.length > 0) {
      setSelectedYear([]);
    } else {
      setSelectedYear(YEARS_FILTER.map((item) => item.value));
    }
  };

  const deSelectCounties = () => {
    if (selectedCountyOptions?.length > 0) {
      setSelectedCountyOptions([]);
    } else {
      setSelectedCountyOptions(COUNTIES_FILTER.map((item) => item.value));
    }
  };

  const downloadCsv = (withProperties) => {
    setCsvDownloadType(withProperties ? "withProperties" : "standard");

    const params = {
      counties: selectedCountyOptions,
      start_date: debouncedStartDate,
      end_date: debouncedEndDate,
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "ASC" : "DESC",
      search: debouncedSearchQuery,
      years: selectedYear,
      is_lead: selectedRole ? selectedRole.value : "",
      with_properties: withProperties ? 1 : 0,
    };

    if (selectedRow.length > 0) {
      params["owner_ids[]"] = selectedRow.map((row) => row.id);
    }

    dispatch(smsEligibilityActions.exportSmsEligibilityData(params));
  };

  const resetData = () => {
    dispatch(smsEligibilityActions.setPaginationData({ first: 10, rows: 50, page: 1 }));
    dispatch(smsEligibilityActions.setAllowScroll(false));
    setRoleSelectValue("");
    setSearchQuery("");
    setSelectedRow([]);
    setSelectedYear([]);
    setStartDate("");
    setEndDate("");
    setSelectedCountyOptions([]);
    setSortData({
      field: "sign_up_date",
      direction: -1,
    });
  };

  useEffect(() => {
    downloadFileByLink(exportSmsEligibilityStatus, exportSmsEligibilityHref, "sms-eligibility.csv");
  }, [exportSmsEligibilityHref]);

  useEffect(() => {
    if (!isLoading(exportSmsEligibilityStatus)) {
      setCsvDownloadType(null);
    }
  }, [exportSmsEligibilityStatus]);

  useEffect(() => {
    getSmsEligibilityList();
    dispatch(smsEligibilityActions.setAllowScroll(false));
  }, [
    debouncedSearchQuery,
    selectedCountyOptions,
    debouncedStartDate,
    debouncedEndDate,
    selectedYear,
    roleSelectValue,
    paginationData.rows,
    paginationData.page,
    sortData.field,
    sortData.direction,
  ]);

  useEffect(() => {
    return () => dispatch(smsEligibilityActions.resetAllData());
  }, []);

  return (
    <S.Container>
      <S.Title>SMS Eligibility</S.Title>
      <AdminReloadButton onClick={resetData} />
      <Filter>
        <SearchInput
          className="search-input"
          placeholder="Search Client List"
          value={searchQuery}
          onSearch={onSearch}
        />
        <Select
          itemKey="label"
          items={roleOptions}
          label="Owner/Lead"
          value={roleSelectValue}
          onChange={(value) => setRoleSelectValue(value)}
        />
        <FilterMultiSelect
          buttonLabel="COUNTY FILTER"
          options={COUNTIES_FILTER}
          selectedOptions={selectedCountyOptions}
          setSelectedOptions={setSelectedCountyOptions}
        />
        <PrimaryButton
          label={(selectedCountyOptions?.length ? "DE" : "") + "SELECT ALL COUNTIES"}
          mode="gray"
          onClick={deSelectCounties}
        />
        <FilterMultiSelect
          buttonLabel="YEAR FILTER"
          options={YEARS_FILTER}
          selectedOptions={selectedYear}
          setSelectedOptions={setSelectedYear}
        />
        <PrimaryButton
          eventHandler={deSelectYears}
          label={(selectedYear?.length ? "DE" : "") + "SELECT ALL YEARS"}
          mode="gray"
        />
        <Can ability={ability} I={PERMISSIONS_LIST.downloadSmsEligibilityCsv}>
          <PrimaryButton
            label="DOWNLOAD CSV"
            loading={csvDownloadType === "standard" && isLoading(exportSmsEligibilityStatus)}
            mode="gray"
            onClick={() => downloadCsv(false)}
          />
          <PrimaryButton
            label="Download CSV with properties"
            loading={csvDownloadType === "withProperties" && isLoading(exportSmsEligibilityStatus)}
            mode="gray"
            onClick={() => downloadCsv(true)}
          />
        </Can>
        <PrimaryInput
          placeholder="Start date"
          type="date"
          value={startDate}
          onChange={(e) => {
            setStartDate(e.target.value);
            dispatch(hearingDatesActions.setAllowScroll(false));
          }}
        />
        <PrimaryInput
          placeholder="End date"
          type="date"
          value={endDate}
          onChange={(e) => {
            setEndDate(e.target.value);
            dispatch(hearingDatesActions.setAllowScroll(false));
          }}
        />
      </Filter>
      <Table
        dataKey="id"
        loading={getSmsEligibilityStatus !== LOADING_STATUSES.succeeded}
        selection={selectedRow}
        sortField={sortData.field}
        sortOrder={sortData.direction}
        tableData={tableData}
        value={smsEligibilityData || []}
        onSelectionChange={(e) => setSelectedRow(e.value)}
        onSort={onSort}
      />
      <S.PaginatorWrapper ref={ref}>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[50, 100, 200]}
          totalRecords={last_page}
          onClick={() => dispatch(smsEligibilityActions.setAllowScroll(true))}
          onPageChange={onPageChange}
        />
        <S.Box>{getPaginationDescription(paginationData, last_page)}</S.Box>
      </S.PaginatorWrapper>
    </S.Container>
  );
};
