import { preloadImage } from "utils/helpers";

import home_1 from "./home-1.jpg";
import home_2 from "./home-2.jpg";
import home_3 from "./home-3.jpg";
import logo from "./logo.png";
import not_found from "./not-found.jpg";
import PTPLogo from "./PTPLogo.svg";

export const IMAGES = {
  logo,
  home_1,
  home_2,
  home_3,
  not_found,
  PTPLogo,
};

Object.values(IMAGES).forEach((value) => preloadImage(value));
