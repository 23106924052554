import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

import { SinglePageContentShadow } from "../../pages/admin/ArchivedSinglePage/styled";

export const InvoicesContainer = styled(SinglePageContentShadow)`
  width: 100%;
  min-height: auto;
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-block: 10px;

  @media screen and (${BREAKPOINTS.xl}) {
    width: 50%;
  }
`;

export const InvoiceTitle = styled.h1`
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  padding-inline: 6px;
`;

export const InvoiceItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 6px;
`;

export const Value = styled.p`
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;

  &.status {
    color: ${({ status }) => (status === "open" ? COLORS.green : COLORS.overlay_300)};
  }
`;

export const InvoiceDetailText = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-size: 14px;
  margin-bottom: 8px;
  max-width: 120px;
  width: 100%;
  text-align: center;
`;

export const LoaderBox = styled.div`
  display: flex;
  justify-content: center;
`;

export const ItemDescription = styled.div`
  font-size: 14px;
`;

export const Title = styled.h1`
  font-size: 14px;
  padding-inline: 6px;
`;
