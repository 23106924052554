import { COLORS } from "assets/styles/colors";
import { ContentHeader, SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  margin-top: 24px;

  .delete-modal {
    color: ${COLORS.red};
  }

  .search-input {
    height: unset;
    width: 300px;
  }

  svg {
    cursor: pointer;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 20px;
  }
`;

export const Content = styled(SinglePageContentShadow)``;

export const ContentTitle = styled(ContentHeader)``;

export const Box = styled.div`
  color: ${COLORS.green};

  &.red {
    color: ${COLORS.red}
  }
`;

export const FooterContainer = styled.div`
  color: ${COLORS.green};
  display: flex;
  justify-content: space-between;
  font-family: Montserrat, serif;
`;

export const FooterTitle = styled.div`
  margin-left: 8%;
`;

export const PercentTotal = styled.div`
  margin-right: 4%;
`;

export const FilterBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const FilterButtons = styled.div`
  display: flex;
  gap: 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const RemoveContainer = styled.div`
  svg {
    cursor: pointer;
    width: 15px;
    fill: ${COLORS.dimgrey};
  }
`;

export const NoResult = styled.div`
  padding: 8px;
`;
