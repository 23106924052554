import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { COLORS } from "assets/styles/colors";
import { PrimaryButton, PrimaryInput } from "common";
import { LazyLoadSelect } from "common/LazyLoadSelect";
import { adminRoutes } from "router/routes";
import { singleEvidenceSelectors } from "store/admin-slices/single-evidence-slice/selectors";
import { singleEvidenceActions } from "store/admin-slices/single-evidence-slice/slice";
import { LOADING_STATUSES, RESIDENT_TYPES } from "utils/constants";
import { numberToDollar } from "utils/helpers";

import * as S from "./styled";

function areAllSavedFiltersEmpty(filters) {
  for (const key in filters) {
    if (Array.isArray(filters[key]) && filters[key].length > 0) {
      return false;
    }
    if (filters[key] === null) {
      continue;
    }
    if (typeof filters[key] === "object" && filters[key] !== null) {
      const hasNonEmptyValue = Object.values(filters[key]).some(
        (value) => (Array.isArray(value) && value.length > 0) || (value !== null && value !== "")
      );
      if (hasNonEmptyValue) {
        return false;
      }
    } else if (filters[key] !== "" && filters[key] !== null) {
      return false;
    }
  }
  return true;
}

const onlyNumbersRegex = new RegExp("^-?\\d+$");

export const EvidenceSideBar = () => {
  const {
    leftBarValues,
    subjectProperty,
    sortData,
    residentType,
    selects,
    ranges,
    floats,
    savedFilters,
    dates,
    getFiltersStatus,
    hasNotFilters,
    resetFiltersStatus,
    getSortDataStatus,
  } = useSelector(singleEvidenceSelectors);

  const [filters, setFilters] = useState({});
  const [numbers, setNumbers] = useState({});
  const [datesValues, setDatesValues] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [hasSelectKeysInParams, setHasSelectKeysInParams] = useState(false);
  const propertyCounty = leftBarValues?.county || "";

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const property_id = useParams().propertyId;

  const goBack = () => navigate(`${adminRoutes.prefix}/${adminRoutes.evidence}`);

  const handleGetEvidences = (params) => {
    if (residentType === RESIDENT_TYPES.equity) {
      dispatch(singleEvidenceActions.getEvidencesEquities(params));
    } else if (residentType === RESIDENT_TYPES.sales) {
      dispatch(singleEvidenceActions.getEvidencesSales(params));
    }
  };

  const generateParams = () => {
    const params = {
      is_sale: residentType === RESIDENT_TYPES.equity ? 0 : 1,
      property_id,
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "ASC" : "DESC",
    };

    params.is_filtered = areAllSavedFiltersEmpty(savedFilters) ? 0 : 1;

    selects?.forEach((selectItem) => {
      const key = selectItem?.description;
      let value = selectedOptions?.[key];

      if (key === "market_area_code" && value) {
        value = value.map((option) => option.split(" - ")[0]);
      }

      if (value && value.length > 0) {
        params[key] = value;
      }
    });

    const addObjectParams = (sourceObj, targetObj) => {
      Object.entries(sourceObj).forEach(([key, value]) => {
        const rangeItem = ranges?.find((item) => item.name === key);
        const paramKey = rangeItem ? rangeItem.description : key;

        if (targetObj[paramKey]) return;

        if (value && typeof value === "object" && "start" in value && "end" in value) {
          const start = value.start !== -Infinity ? value.start : undefined;
          const end = value.end !== Infinity ? value.end : undefined;

          if ((start !== undefined && start !== null) || (end !== undefined && end !== null)) {
            targetObj[paramKey] = { start, end };
          }
        } else if (value !== undefined && value !== null && value !== "") {
          targetObj[paramKey] = value;
        }
      });
    };

    Object.entries(datesValues).forEach(([key, value]) => {
      if (value && !params[key]) {
        params[key] = value;
      }
    });

    addObjectParams(filters, params);
    addObjectParams(numbers, params);

    return params;
  };

  const handleCompsFiltersClick = () => {
    const params = generateParams();
    handleGetEvidences({ property_id, ...params });
    dispatch(singleEvidenceActions.getSaveComps({ sales: residentType === RESIDENT_TYPES.equity ? 0 : 1, propertyId: property_id }));
  };

  const handleSelectChange = (name, newOptions) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [name]: newOptions,
    }));
  };

  const handleFirstFilterChange = (e, key) => {
    const value = onlyNumbersRegex.test(e.target.value) ? e.target.value : null;
    setFilters((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        start: value,
        end: prevState[key]?.end || null,
      },
    }));
  };

  const handleSecondFilterChange = (e, key) => {
    const value = onlyNumbersRegex.test(e.target.value) ? e.target.value : null;
    setFilters((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        end: value,
        start: prevState[key]?.start || null,
      },
    }));
  };

  const handleDateChange = (e, key) => {
    setDatesValues((prevState) => ({
      ...prevState,
      [key]: e.target.value,
    }));
  };

  const propertyData = [
    {
      id: 1,
      label: "Account:",
      value: leftBarValues?.account_number,
    },
    {
      id: 2,
      label: "Situs:",
      value: leftBarValues?.street_address,
    },
    {
      id: 3,
      label: "Owner:",
      value: leftBarValues?.county_owner_name,
    },
    {
      id: 4,
      label: "County:",
      value: leftBarValues?.county,
    },
  ];

  const propertyInfo = [
    {
      label: new Date().getFullYear() + " Certified Value",
      value: leftBarValues?.certified_value,
      isMoney: true,
    },
    {
      label: "+/-",
      value: leftBarValues?.sales,
      isMoney: true,
    },
    {
      label: `Adjusted ${residentType === RESIDENT_TYPES.sales ? "Sales" : "Equity"} Value`,
      value: leftBarValues?.adjusted_sales_value,
      isMoney: true,
    },
  ];

  useEffect(() => {
    if (property_id) {
      dispatch(
        singleEvidenceActions.getCompsFilters({
          property_id,
          is_sales: residentType === RESIDENT_TYPES.equity ? 0 : 1,
        })
      );
    }
  }, [residentType, property_id]);

  useEffect(() => {
    if (propertyCounty && !sortData.field) {
      dispatch(singleEvidenceActions.setSortData({ field: "adjusted_value", direction: 1 }));
      dispatch(singleEvidenceActions.setTableData(propertyCounty.toLowerCase()));
    }
  }, [propertyCounty, sortData]);

  useEffect(() => {
    if (!selects || !subjectProperty) return;

    const defaultSelectedOptions = {};
    const autoFilledOptions = {};
    const safeSavedFilters = savedFilters || {};

    selects.forEach((selectItem) => {
      let selectedValue;
      if (!selectItem.is_associative) {
        selectedValue = selectItem.data.find((item) =>
          item.startsWith(subjectProperty[selectItem.description] + " "));
      }

      const trimmedSubjectValue = selectedValue ? selectedValue : subjectProperty?.[selectItem?.description]?.trim();

      if (trimmedSubjectValue && hasNotFilters) {
        defaultSelectedOptions[selectItem.description] = [trimmedSubjectValue];

        if (selectItem.is_default_selected) {
          autoFilledOptions[selectItem.description] = [trimmedSubjectValue];
        }
      }
    });

    if (getFiltersStatus === LOADING_STATUSES.succeeded) {
      setSelectedOptions(defaultSelectedOptions);
    }

    if (!areAllSavedFiltersEmpty(safeSavedFilters)) {
      setSelectedOptions(savedFilters);
    } else if (!savedFilters) {
      setSelectedOptions(autoFilledOptions);
    }

    dispatch(singleEvidenceActions.setGetFiltersStatus(LOADING_STATUSES.idle));
  }, [selects, savedFilters, subjectProperty, residentType, getFiltersStatus, hasNotFilters]);

  useEffect(() => {
    const params = generateParams();
    if (savedFilters && selects) {
      const savedFilterKeys = Object.keys(savedFilters);
      const hasFilterKeys = savedFilterKeys.some((filterKey) => {
        const filterValue = savedFilters[filterKey];

        if (!filterValue) return false;

        if (typeof filterValue === "object" && !Array.isArray(filterValue)) {
          return (
            filterValue && Object.keys(filterValue).some(() => Object.prototype.hasOwnProperty.call(params, filterKey))
          );
        }

        return Object.prototype.hasOwnProperty.call(params, filterKey);
      });

      setHasSelectKeysInParams(hasFilterKeys);
    } else {
      setHasSelectKeysInParams(false);
    }
  }, [savedFilters, residentType, selectedOptions]);

  useEffect(() => {
    const newFilters = {
      ...savedFilters,
      ...selectedOptions,
      ...filters,
      ...numbers,
      ...datesValues,
    };

    if (JSON.stringify(newFilters) !== JSON.stringify(savedFilters)) {
      dispatch(singleEvidenceActions.setSavedFilters(newFilters));
    }
  }, [filters, numbers, datesValues, selectedOptions, residentType]);

  useEffect(() => {
    if (selects) {
      dispatch(
        singleEvidenceActions.getFilters({
          property_id,
          is_sale: residentType === RESIDENT_TYPES.equity ? 0 : 1,
        })
      );

      setFilters({});
      setNumbers({});
    }
  }, [selects, residentType]);

  useEffect(() => {
    if (getSortDataStatus !== LOADING_STATUSES.succeeded) return;

    const params = generateParams();

    if (propertyCounty || hasSelectKeysInParams) {
      handleGetEvidences(params);
    }
  }, [propertyCounty, residentType, hasSelectKeysInParams, getSortDataStatus]);

  useEffect(() => {
    const autofillFilters = {};

    if (savedFilters) {
      ranges?.forEach((range) => {
        const key = range.description;

        if (savedFilters[key]) {
          const savedValue = savedFilters[key];

          if (typeof savedValue === "object" && savedValue.start !== undefined && savedValue.end !== undefined) {
            autofillFilters[key] = {
              start: savedValue.start,
              end: savedValue.end,
            };
          } else if (Array.isArray(savedValue)) {
            autofillFilters[key] = savedValue;
          }
        }
      });
      setFilters((prevState) => ({
        ...prevState,
        ...autofillFilters,
      }));

      floats?.forEach((float) => {
        const key = float.description;
        if (savedFilters[key]) {
          setNumbers((prevState) => ({
            ...prevState,
            [key]: savedFilters[key],
          }));
        }
      });

      dates?.forEach((dateItem) => {
        const key = dateItem.description;
        if (savedFilters[key]) {
          setDatesValues((prevState) => ({
            ...prevState,
            [key]: savedFilters[key],
          }));
        }
      });
    }
  }, [savedFilters, ranges, floats, dates]);

  useEffect(() => {
    if (resetFiltersStatus === LOADING_STATUSES.succeeded) {
      setNumbers({});
    }
  }, [resetFiltersStatus]);

  return (
    <S.SlidebarContainer>
      <S.SiteBarFixedContainer>
        <S.StickyContainer>
          <PrimaryButton className="back-button" eventHandler={goBack} label="Back" />
          <S.Border />
          <S.PropertyParentContainer>
            {propertyData.map((item) => (
              <S.PropertyContainer key={item.id}>
                <S.Label>{item?.label}</S.Label>
                <S.Value>{item?.value}</S.Value>
              </S.PropertyContainer>
            ))}
          </S.PropertyParentContainer>
          <S.Border />
          <S.PropertyParentContainer>
            {propertyInfo.map((item) => (
              <S.PropertyContainer key={item.label}>
                <S.Label>{item.label}</S.Label>
                {leftBarValues ? (
                  <S.Value
                    style={
                      item.label === "+/-"
                        ? { color: COLORS.green_300 }
                        : item.label === "Adjusted Value"
                          ? { color: COLORS.light_blue }
                          : {}
                    }
                  >
                    {item.isMoney ? numberToDollar(item.value) : item.value}
                  </S.Value>
                ) : null}
              </S.PropertyContainer>
            ))}
          </S.PropertyParentContainer>
          <S.Border />
        </S.StickyContainer>
        <S.FilterContainer>
          <S.Heading>
            <S.HeadingText>SELECT COMPS BY:</S.HeadingText>
            <PrimaryButton eventHandler={handleCompsFiltersClick} label="Apply" />
          </S.Heading>

          <S.InputsContainer>
            {dates?.map((date) => {
              const prevYear = new Date().getFullYear() - 1;
              const defaultDate = `${prevYear}-01-01`;

              return (
                <S.DatesContent key={date.name}>
                  <S.Border />
                  <S.Block className="block">
                    <S.DateHeader>
                      <S.DateTitle>{date.name}</S.DateTitle>
                      <S.Description>
                        {subjectProperty?.[date.description] ? (
                          <S.Description>({subjectProperty?.[date.description]})</S.Description>
                        ) : null}
                      </S.Description>
                    </S.DateHeader>
                    <PrimaryInput
                      defaultValue={datesValues[date.description] || defaultDate}
                      type="date"
                      onChange={(e) => handleDateChange(e, date.description)}
                    />
                  </S.Block>
                  <S.Border />
                </S.DatesContent>
              );
            })}
            <S.MultiSelectWrapper>
              {(Array.isArray(selects) ? selects : []).map((selectItem) => {
                const options = [];

                if (Array.isArray(selectItem?.data)) {
                  for (const value of selectItem.data) {
                    options.push({ value, label: value, className: value === subjectProperty?.[selectItem?.description] ? "!hidden" : undefined, });
                  }
                } else {
                  for (const [key, value] of Object.entries(selectItem.data)) {
                    options.push({ value: key, label: value, className: key === subjectProperty?.[selectItem?.description] ? "!hidden" : undefined, });
                  }
                }

                const selected = selectedOptions?.[selectItem?.description];

                let selectedValue;
                if (!selectItem.is_associative && subjectProperty) {
                  selectedValue = selectItem.data.find((item) =>
                    item.startsWith(subjectProperty[selectItem.description] + " ")
                  );
                }

                let subject = {};

                if (subjectProperty) {
                  if (selectedValue) {
                    subject = {
                      label: selectedValue,
                      value: selectedValue
                    };
                  } else if (selectItem.is_associative) {
                    subject = {
                      label: selectItem.data[subjectProperty[selectItem.description]],
                      value: subjectProperty[selectItem.description]
                    };
                  } else {
                    subject = {
                      label: subjectProperty[selectItem.description],
                      value: subjectProperty[selectItem.description]
                    };
                  }
                } else {
                  subject = {
                    label: "",
                    value: "",
                  };
                }

                return (
                  <LazyLoadSelect
                    key={selectItem.description}
                    resetFilterOnHide
                    description={
                      selectItem.description === "market_area_code" && subjectProperty
                        ? subjectProperty[selectItem.description]?.trim().split(" ")[0]
                        : subjectProperty
                          ? subjectProperty[selectItem.description]?.trim()
                          : ""
                    }
                    label={selectItem.name}
                    optionLabel="label"
                    options={options}
                    panelHeaderTemplate={(options) => {
                      const isChecked = selectedOptions?.[selectItem.description]?.includes(subject.value);

                      return (
                        <S.TemplateContainer>
                          <S.FilterContainer className="p-multiselect-header p-multiselect-filter-container">
                            {options.filterElement}
                          </S.FilterContainer>
                          {subject.value ? (
                            <S.SelectedSubjectContent>
                              <S.TemplateTitle>Selected / Subject</S.TemplateTitle>
                              <S.TemplateContent
                                onClick={() => {
                                  if (isChecked) {
                                    handleSelectChange(
                                      selectItem.description,
                                      selectedOptions[selectItem.description]?.filter(
                                        (val) => val !== subject.value
                                      )
                                    );
                                  } else {
                                    handleSelectChange(selectItem.description, [
                                      ...(selectedOptions[selectItem.description] || []),
                                      subject.value,
                                    ]);
                                  }
                                }}
                              >
                                <input
                                  readOnly
                                  checked={isChecked}
                                  className="p-checkbox-box checkbox"
                                  type="checkbox"
                                />
                                <S.SubjectSpan>
                                  {subject.label} <S.Paragraph>subject</S.Paragraph>
                                </S.SubjectSpan>
                              </S.TemplateContent>
                              <S.SubjectBorder />
                            </S.SelectedSubjectContent>
                          ) : null}
                        </S.TemplateContainer>
                      );
                    }}
                    placeholder="select"
                    selectedOptions={selected}
                    setSelectedOptions={(newOptions) => handleSelectChange(selectItem.description, newOptions)}
                    subjectValue={subject.value}
                  />
                );
              })}
            </S.MultiSelectWrapper>
            <S.Block>
              {floats?.map((item) => (
                <S.Block key={item.name}>
                  <S.Title>{item.name}</S.Title>
                  <PrimaryInput
                    name={item.name}
                    type="number"
                    value={numbers[item.description] || ""}
                    onChange={(e) =>
                      setNumbers((prevState) => ({
                        ...prevState,
                        [item.description]: e.target.value,
                      }))
                    }
                    onWheel={(e) => e.target.blur()}
                  />
                </S.Block>
              ))}
            </S.Block>
          </S.InputsContainer>
        </S.FilterContainer>
        <S.Border />
        <S.FilterContainer>
          <S.Heading>
            <S.HeadingText>FILTER</S.HeadingText>
            <PrimaryButton eventHandler={handleCompsFiltersClick} label="Apply" />
          </S.Heading>
          <S.InputsContainer>
            {(Array.isArray(ranges) ? ranges : []).map((item) => {
              const filterValue = filters[item.description] || {};

              return (
                <S.InputsBlock key={item.name}>
                  <S.Div>
                    <S.Title>{item.name} &nbsp;</S.Title>
                    <S.Description>
                      {subjectProperty?.[item.description] ? `(${subjectProperty[item.description]})` : ""}
                    </S.Description>
                  </S.Div>
                  <S.FilterInput>
                    <PrimaryInput
                      type="number"
                      value={filterValue.start || ""}
                      onChange={(e) => handleFirstFilterChange(e, item.description)}
                      onWheel={(e) => e.target.blur()}
                    />
                    <S.Span>to</S.Span>
                    <PrimaryInput
                      type="number"
                      value={filterValue.end || ""}
                      onChange={(e) => handleSecondFilterChange(e, item.description)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </S.FilterInput>
                </S.InputsBlock>
              );
            })}
          </S.InputsContainer>
        </S.FilterContainer>
      </S.SiteBarFixedContainer>
    </S.SlidebarContainer>
  );
};
