import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { routes } from "router/routes";
import { getMe, getProperties } from "store/client-slice/actions";
import { getStorage } from "utils/helpers";

import { NavigationBar } from "components/Client/NavigationBar";

import * as S from "./styled";

export const ClientLayout = () => {
  const location = useLocation();
  const isAuthorized = getStorage("owner_token");

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthorized) {
      dispatch(getMe());
    }
  }, []);

  useEffect(() => {
    dispatch(getProperties());
  }, []);

  if (!isAuthorized) {
    return <Navigate state={{ from: location }} to={routes.signin} />;
  }

  return (
    <>
      <NavigationBar />
      <S.Content>
        <Outlet />
      </S.Content>
    </>
  );
};
