import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader, PrimaryButton } from "common";
import { PropertyAccount } from "pages/client/PropertiesPage/SingleProperty/PropertyAccount";
import { PropertyLocation } from "pages/client/PropertiesPage/SingleProperty/PropertyLocation";
import { clientRoutes } from "router/routes";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";
import { clientApi } from "store/client-slice/services";
import { ownerPortalPropertySelector } from "store/owner-portal-property-slice/selectors";
import { isLoading } from "utils/constants";

import { EvidenceSection } from "./EvidenceSection";
import * as S from "./styled";

export const SingleProperty = ({ id, isOpen }) => {
  const { authedOrNot, autedOrNotStatus } = useSelector(ownerPortalPropertySelector);
  const [property, setProperty] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handelNavigateToPreviewDocuments = () => {
    navigate(`${clientRoutes.prefix}${clientRoutes.renewDocuments}/${id}`);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(singleOwnerActions.getNotes({ id, params: { is_owner: 1 } }));
      clientApi.getSingleProperty(id).then(({ data }) => setProperty(data.data));
    }
  }, [isOpen]);

  if (isLoading(autedOrNotStatus) || !property) {
    return (
      <S.LoaderBox>
        <Loader size={80} />
      </S.LoaderBox>
    );
  }

  return (
    <S.Container>
      <S.ButtonWrapper>
        {authedOrNot !== 1 ? (
          <PrimaryButton
            className="renew-documents ml-auto"
            eventHandler={handelNavigateToPreviewDocuments}
            label="Review/Update Documents"
          />
        ) : null}
      </S.ButtonWrapper>
      <S.InfoContainer>
        <S.InfoWrapper>
          <PropertyAccount property={property} />
        </S.InfoWrapper>
        <S.InfoWrapper>
          <PropertyLocation property={property} />
        </S.InfoWrapper>
      </S.InfoContainer>
      {property.evidences?.length ? <EvidenceSection evidences={property.evidences[0]} /> : null}
    </S.Container>
  );
};
