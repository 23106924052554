import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icons } from "assets/svgs";
import { AdminReloadButton, FilterMultiSelect, PrimaryButton, SearchInput, Select, Table } from "common";
import { useDebounce } from "hooks";
import { Paginator } from "primereact/paginator";
import { adminRoutes } from "router/routes";
import { clientEvidenceSelector } from "store/admin-slices/client-evidence-slice/selectors";
import { clientEvidenceActions } from "store/admin-slices/client-evidence-slice/slice";
import { COUNTIES_FILTER, isLoading, LOADING_STATUSES, YEARS_FILTER } from "utils/constants";
import { downloadFileByLink, getPaginationDescription } from "utils/helpers";

import { Template } from "components/Admin/TableRowCustomTemplate";
import { Filter } from "components/Filter";
import { Modal } from "components/Modal";

import * as S from "./styled";

export const ClientEvidencePage = () => {
  const {
    clientEvidences,
    last_page,
    getClientEvidencesStatus,
    paginationData,
    mergeEvidenceFilesStatus,
    exportCSVStatus,
    downloadCsvHref,
    isDeleteModalOpen,
    deleteEvidenceStatus,
    clientEvidenceListFilters,
    allowScroll,
  } = useSelector(clientEvidenceSelector);

  const [selectedYear, setSelectedYear] = useState([]);
  const [selectedMergeOptions, setSelectedMergeOptions] = useState("");
  const [sortData, setSortData] = useState({
    field: "date_submitted",
    direction: -1,
  });
  const [fileId, setFileId] = useState(null);
  const [selectedCountyOptions, setSelectedCountyOptions] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [mergeValue, setMergeValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 200);

  const mergeSelectItems = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Merged",
      value: 1,
    },
    {
      label: "Not Merged",
      value: 0,
    },
  ];

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const ref = useRef(null);

  const handleRowClick = (item) => {
    dispatch(clientEvidenceActions.setAllowScroll(false));
    navigate(`${adminRoutes.prefix}/${adminRoutes.clientEvidence}/${item.data.id}`);
  };

  const getClientEvidenceList = (props) => {
    const params = {
      page: props?.page ?? paginationData.page,
      limit: props?.rows ?? paginationData.rows,
      search: debouncedSearchQuery,
      counties: selectedCountyOptions,
      years: selectedYear,
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "asc" : "desc",
      is_merged: selectedMergeOptions,
    };

    dispatch(clientEvidenceActions.getClientEvidences(params));
  };
  const onPageChange = ({ first, rows, page }) => {
    dispatch(
      clientEvidenceActions.setPaginationData({
        first,
        rows,
        page: page + 1,
      })
    );

    getClientEvidenceList({ page: page + 1, rows });
  };

  const resetData = () => {
    dispatch(clientEvidenceActions.setPaginationData({ first: 10, rows: 50, page: 1 }));
    dispatch(clientEvidenceActions.setAllowScroll(false));
    setSelectedMergeOptions("");
    setMergeValue("");
    setSearchQuery("");
    setSortData({
      field: "date_submitted",
      direction: -1,
    });
    setSelectedProperties([]);
    setSelectedCountyOptions([]);
    setSelectedYear([]);
  };
  const handleExportCsvClick = () => {
    let client_evidences = selectedProperties?.map((prop) => prop.id);
    client_evidences = client_evidences?.filter((id, index, self) => self.indexOf(id) === index);
    const params = {
      search: searchQuery,
      counties: selectedCountyOptions,
      client_evidences,
    };
    dispatch(clientEvidenceActions.exportCSV(params));
  };

  const handleDeleteEvidence = () => {
    dispatch(clientEvidenceActions.deleteEvidence(fileId));
  };

  const deSelectCounties = () => {
    if (selectedCountyOptions?.length > 0) {
      setSelectedCountyOptions([]);
    } else {
      setSelectedCountyOptions(COUNTIES_FILTER.map((item) => item.value));
    }
  };

  const onSearch = (searchResult) => {
    setSearchQuery(searchResult);
  };

  const onSort = (e) => setSortData({ field: e.sortField, direction: e.sortOrder });

  const onMergeSelectChange = (value) => {
    setMergeValue(value);
    setSelectedMergeOptions(mergeSelectItems.find((item) => item.label === value).value);
  };

  const deSelectYears = () => {
    if (selectedYear?.length > 0) {
      setSelectedYear([]);
    } else {
      setSelectedYear(YEARS_FILTER.map((item) => item.value));
    }
  };

  const tableData = useMemo(
    () => [
      { headerClassName: "column-header", selectionMode: "multiple" },
      { sortable: true, field: "date_submitted", header: "Submission date" },
      { sortable: true, field: "client_email", header: "Email" },
      { sortable: true, field: "county", header: "County" },
      { field: "address", sortable: true, header: "Street address" },
      { field: "account_number", header: "Account number" },
      {
        field: "description",
        header: "Description",
        body: ({ description }) => Template.DescriptionTemplate(description, "Description"),
      },
      { field: "is_merged", header: "Merge status", body: ({ is_merged }) => Template.MergeStatus(is_merged) },
      {
        header: "Remove",
        body: (item) => (
          <S.RemoveContainer>
            <Icons.TrashIcon
              onClick={(e) => {
                e.stopPropagation();
                setFileId(item.id);
                dispatch(clientEvidenceActions.setIsDeleteModalOpen(true));
              }}
            />
          </S.RemoveContainer>
        ),
      },
    ],
    [mergeEvidenceFilesStatus]
  );

  useEffect(() => {
    getClientEvidenceList();
    dispatch(
      clientEvidenceActions.setClientEvidenceListFilters({
        selectedCountyOptions: selectedCountyOptions,
        selectedYear: selectedYear,
        mergeValue: mergeValue,
        searchQuery: searchQuery,
        paginationData: paginationData,
      })
    );
  }, [
    debouncedSearchQuery,
    sortData,
    selectedMergeOptions,
    selectedCountyOptions,
    selectedYear,
    deleteEvidenceStatus,
    paginationData,
  ]);

  useEffect(() => {
    dispatch(clientEvidenceActions.setAllowScroll(false));
  }, [debouncedSearchQuery, sortData, selectedMergeOptions, selectedCountyOptions, selectedYear, deleteEvidenceStatus]);

  useEffect(() => {
    downloadFileByLink(exportCSVStatus, downloadCsvHref, "client-evidences.csv");
  }, [downloadCsvHref]);

  useEffect(() => {
    setSelectedProperties([]);
  }, [paginationData.page]);

  useEffect(() => {
    if (deleteEvidenceStatus === LOADING_STATUSES.succeeded) {
      dispatch(clientEvidenceActions.setIsDeleteModalOpen(false));
    }
  }, [deleteEvidenceStatus]);

  useEffect(() => {
    return () => dispatch(clientEvidenceActions.resetAllData());
  }, []);

  useEffect(() => {
    allowScroll && ref.current.scrollIntoView({ behavior: "auto" });
  }, [getClientEvidencesStatus]);

  useEffect(() => {
    setSelectedCountyOptions(clientEvidenceListFilters.selectedCountyOptions);
    setSelectedYear(clientEvidenceListFilters.selectedYear);
    setMergeValue(clientEvidenceListFilters.mergeValue);
    setSelectedMergeOptions(
      clientEvidenceListFilters.mergeValue === "Merged"
        ? 1
        : clientEvidenceListFilters.mergeValue === "Not Merged"
        ? 0
        : ""
    );
    setSearchQuery(clientEvidenceListFilters.searchQuery || "");
    dispatch(clientEvidenceActions.setPaginationData(clientEvidenceListFilters.paginationData || paginationData));
  }, []);

  return (
    <S.ClientEvidenceContainer>
      <S.ClientEvidenceTitle>Client Submitted Evidence</S.ClientEvidenceTitle>
      <AdminReloadButton onClick={resetData} />
      <Filter>
        <SearchInput
          className="search-input"
          placeholder="Search Client List"
          value={searchQuery}
          onSearch={onSearch}
        />
        <Select
          itemKey="label"
          items={mergeSelectItems}
          label="Merge filter"
          value={mergeValue}
          onChange={onMergeSelectChange}
        />
        <FilterMultiSelect
          buttonLabel="COUNTY FILTER"
          options={COUNTIES_FILTER}
          selectedOptions={selectedCountyOptions}
          setSelectedOptions={setSelectedCountyOptions}
        />
        <PrimaryButton
          label={(selectedCountyOptions?.length ? "DE" : "") + "SELECT ALL COUNTIES"}
          mode="gray"
          onClick={deSelectCounties}
        />
        <PrimaryButton
          eventHandler={handleExportCsvClick}
          label="Download CSV"
          loading={isLoading(exportCSVStatus)}
          mode="gray"
        />
        <FilterMultiSelect
          buttonLabel="YEAR FILTER"
          options={YEARS_FILTER}
          selectedOptions={selectedYear}
          setSelectedOptions={setSelectedYear}
        />
        <PrimaryButton
          label={(selectedYear?.length ? "DE" : "") + "SELECT ALL YEARS"}
          mode="gray"
          onClick={deSelectYears}
        />
      </Filter>
      <Modal
        cancelButtonText="no"
        className="delete-modal"
        description="Are you sure ?"
        isModalOpen={isDeleteModalOpen}
        successButtonText="yes"
        successLoadingStatus={isLoading(deleteEvidenceStatus)}
        onCancel={() => dispatch(clientEvidenceActions.setIsDeleteModalOpen(false))}
        onSuccess={handleDeleteEvidence}
      />
      <Table
        dataKey="property_id"
        loading={getClientEvidencesStatus !== LOADING_STATUSES.succeeded}
        selection={selectedProperties}
        sortField={sortData.field}
        sortOrder={sortData.direction}
        tableData={tableData}
        value={clientEvidences || []}
        onRowDoubleClick={handleRowClick}
        onSelectionChange={(e) => setSelectedProperties(e.value)}
        onSort={onSort}
      />
      <S.PaginatorWrapper ref={ref}>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[50, 100, 200]}
          totalRecords={last_page}
          onClick={() => dispatch(clientEvidenceActions.setAllowScroll(true))}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">{getPaginationDescription(paginationData, last_page)}</S.Box>
      </S.PaginatorWrapper>
    </S.ClientEvidenceContainer>
  );
};
