import { useState } from "react";
import { PrimaryAccordion } from "common";

import * as S from "./styled";

export const Invoices = ({invoices}) => {

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  return (
    <S.InvoicesContainer>
      <S.InvoiceTitle>Invoices</S.InvoiceTitle>
      {invoices?.length ? (
        <PrimaryAccordion
          bodyContent={invoices.map((invoice) => (
            <S.InvoiceItem key={invoice.id}>
              <S.InvoiceDetailText>
                <S.Value>{invoice.name}</S.Value>
              </S.InvoiceDetailText>
              <S.InvoiceDetailText>
                Status:
                <S.Value className="status" status={invoice.status}>
                  {invoice.status}
                </S.Value>
              </S.InvoiceDetailText>
              <S.InvoiceDetailText>
                Total:
                <S.Value>${invoice.total?.toLocaleString()}</S.Value>
              </S.InvoiceDetailText>
            </S.InvoiceItem>
          ))}
          headerContent={<S.Title>Invoices List</S.Title>}
          isOpen={isAccordionOpen}
          onToggle={() => setIsAccordionOpen(!isAccordionOpen)}
        />
      ) : (
        <S.Value>No invoices</S.Value>
      )}
    </S.InvoicesContainer>
  );
};
