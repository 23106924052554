import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminRoutes } from "router/routes";
import { SLICE_NAME } from "utils/constants";

import { singleOwnerApi } from "./services";

export const getSingleOwner = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/getSingleOwner`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.getSingleOwner(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postAccountLogs = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/postAccountLogs`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.postAccountLogs(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteAccountLogs = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/deleteAccountLogs`,
  async (id, { rejectWithValue }) => {
    try {
      await singleOwnerApi.deleteAccountLogs(id);
      return { id };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteProperty = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/deleteProperty`,
  async (body, { rejectWithValue }) => {
    try {
      await singleOwnerApi.deleteProperty(body);
      return { id: body.property_id };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const connectOwnerProperty = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/connectOwnerProperty`,
  async ({ body, navigate, leads, ownerId }, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.connectOwnerProperty(body);

      if (!leads) {
        navigate(`${adminRoutes.prefix}/${adminRoutes.owners}/${ownerId}/${response.data.data.id}`);
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifySingleProperty = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/verifySingleProperty`,
  async (params, { rejectWithValue }) => {
    try {
      await singleOwnerApi.verifySingleProperty(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateDocument = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/updateDocument`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.updateDoc(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/deleteDocument`,
  async (id, { rejectWithValue }) => {
    try {
      await singleOwnerApi.deleteDoc(id);
      return { id };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const sendAoA = createAsyncThunk(`${SLICE_NAME.singleOwnerSlice}/sendAoA`, async (body, { rejectWithValue }) => {
  try {
    const response = await singleOwnerApi.sendAoA(body);
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createDocument = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/createDocument`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.createDoc(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReferrals = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/getReferrals`,
  async (ownerId, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.getOwnerReferrals(ownerId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReferredBy = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/getReferredBy`,
  async (ownerId, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.getOwnerReferredBy(ownerId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const archiveOwner = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/archiveOwner`,
  async ({ params, navigate }, { rejectWithValue }) => {
    try {
      await singleOwnerApi.archiveOwner(params);
      navigate(`${adminRoutes.prefix}/${adminRoutes.owners}`);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const archiveProperty = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/archiveProperty`,
  async (params, { rejectWithValue }) => {
    try {
      await singleOwnerApi.archiveProperty(params);
      return params;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postReferredBy = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/postReferredBy`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.postReferredBy(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteReferredBy = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/deleteReferredBy`,
  async (id, { rejectWithValue }) => {
    try {
      await singleOwnerApi.deleteReferredBy(id);
      return id;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postReferredTo = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/postReferredTo`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.postReferredTo(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateReferral = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/updateReferral`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.updateReferral(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteReferredTo = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/deleteReferredTo`,
  async (id, { rejectWithValue }) => {
    try {
      await singleOwnerApi.deleteReferredBy(id);
      return id;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateAccountNumber = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/updateAccountNumber`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.updateAccountNumber(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateStreetAddress = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/updateStreetAddress`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.updateStreetAddress(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateEmail = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/updateEmail`,
  async (body, { rejectWithValue }) => {
    try {
      await singleOwnerApi.updateEmail(body.owner_id, body.email);
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNotes = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/getNotes`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.getNotes(data);
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postNote = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/postNote`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleOwnerApi.postNote(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateContactedStatus = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/updateContactedStatus`,
  async (data, { rejectWithValue }) => {
    try {
      await singleOwnerApi.updateContactedStatus(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
