import axios from "axios";
import { API } from "configs/api";

export const clientApi = {
  checkEmail: (body) => axios.post(API.checkEmail, body),
  checkCode: (body) => axios.post(API.checkCode, body),
  getMe: () => axios.get(API.me, { owner_token: true }),
  appeals: () => axios.get(API.ownerPortalAppeals, { owner_token: true }),
  properties: () => axios.get(API.ownerPortalProperties, { owner_token: true }),
  invoices: () => axios.get(API.ownerPortalInvoices, { owner_token: true }),
  getSingleProperty: (id) => axios.get(`${API.ownerPortal}${API.properties}/${id}`, { owner_token: true }),
  getClientEvidences: (id) => axios.get(`${API.clientEvidence}/${id}`, { owner_token: true }),
  getNotifications: () => axios.get(`${API.notifications}`, { owner_token: true }),
  markAsRead: (body) => axios.put(`${API.notifications}${API.read}`, body, { owner_token: true }),
  deleteNotification: (id) => axios.delete(`${API.notifications}/${id}`, { owner_token: true }),
  getOwnerPortalReferrals: (id) => axios.get(`${API.referralsOwnerPortal}/${id}`, { owner_token: true }),
  getOwnerPortalReferredBy: (id) => axios.get(`${API.referredByOwnerPortal}/${id}`, { owner_token: true }),
  getOwnerReferralCode: () => axios.get(API.getOwnerReferralCode, { owner_token: true }),
  updateAccountInfo: ({ params, id }) => axios.put(`${API.updateAccountInfo}/${id}`, {}, { params, owner_token: true }),
};
