import { BREAKPOINTS } from "assets/styles/breakpoints";
import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .renew-documents {
    font-weight: 400;
    max-width: min-content;

    @media screen and (${BREAKPOINTS.md}) {
      max-width: unset;
    }
  }

  .back-button {
    fill: white;
    max-width: min-content;
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: stretch;

  @media screen and (min-width: 1300px) {
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 1300px) {
    width: calc(100% / 2 - 20px);
  }
`;

export const LoaderBox = styled.div`
  display: flex;
  justify-content: center;
  min-height: 400px;
`;
