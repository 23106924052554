import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";
import { Icons } from "assets/svgs";
import { Checkbox } from "common/Checkbox";

import * as S from "./styled";

export const PrimaryAccordion = ({
  headerContent,
  bodyContent,
  isOpen,
  onToggle,
  checkboxChecked,
  checkboxId,
  checkboxOnChange,
  className,
  headerClassName,
  bodyClassName,
}) => {
  return (
    <S.AccordionContainer className={className}>
      <Accordion icon={<Icons.ArrowDownIcon className={isOpen ? "rotate" : ""} />} open={isOpen}>
        {checkboxId && checkboxOnChange ? (
          <S.AccordionHeaderContainer>
            <Checkbox checked={checkboxChecked} id={checkboxId} onChange={checkboxOnChange} />
            <AccordionHeader onClick={onToggle}>{headerContent}</AccordionHeader>
          </S.AccordionHeaderContainer>
        ) : (
          <AccordionHeader className={headerClassName} onClick={onToggle}>{headerContent}</AccordionHeader>
        )}
        <AccordionBody className={bodyClassName}>{bodyContent}</AccordionBody>
      </Accordion>
    </S.AccordionContainer>
  );
};
