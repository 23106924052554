import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

export const PrivacyPolicyContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;
  font-weight: 300;

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 16px;
  }
`;

export const PrivacyPolicyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PrivacyPolicyHeading = styled.h1`
  font-weight: 600;
  font-size: 20px;

  @media screen and (${BREAKPOINTS.lg}) {
    font-size: 22px;
  }
`;

export const PrivacyPolicyTitle = styled.h2`
  font-weight: 600;
  font-size: 18px;
  color: ${COLORS.primary};

  @media screen and (${BREAKPOINTS.lg}) {
    font-size: 20px;
  }
`;

export const TextBold = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: ${COLORS.primary};

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 16px;
  }
`;

export const PrivacyPolicyParagraph = styled.p``;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.li `
  margin-left: 16px;
  list-style: inside;
  
`;

export const Link = styled.a`
  color: ${COLORS.primary};
  text-decoration: underline;
  transition: opacity all 300ms;

  &:hover {
    opacity: 0.8;
  }
`;