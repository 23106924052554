import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { ContentHeader, SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  flex-grow: 1;

  .delete-modal {
    color: ${COLORS.red};
  }
`;

export const ContentWrapper = styled(SinglePageContentShadow)`
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 24px;

  @media screen and (max-width: 1200px) {
    flex-direction: row;
  }

  @media screen and (${BREAKPOINTS.md}) {
    flex-direction: column;
  }

  @media screen and (${BREAKPOINTS.xl}) {
    flex-direction: row;
  }

  @media screen and(${BREAKPOINTS.lg}) {
    flex-direction: row;
  }
`;

export const Header = styled(ContentHeader)``;

export const DocumentsWrapper = styled(SinglePageContentShadow)`
  flex-grow: 1;
  padding: 16px 12px;
`;

export const SettingsContent = styled(SinglePageContentShadow)`
  width: 100%;
`;

export const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
`;

export const DocumentsContent = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 0.5px solid ${COLORS.overlay_060};
`;

export const Document = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr;
  align-items: center;
  gap: 20px;
  margin-block: 10px;

  .change-filename-input {
    width: 100% !important;
  }
`;

export const Text = styled.div`
  &.red {
    width: fit-content;
    color: ${COLORS.red};
  }

  &.success {
    margin-left: 80px;
    margin-top: 5px;
    color: ${COLORS.green};
  }

  &.center {
    text-align: center;
  }
`;

export const Link = styled.a`
  width: 100%;
  text-decoration: underline;
  color: ${COLORS.green};
  word-wrap: break-word;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  svg {
    min-width: 14px;
    max-width: 14px;
    fill: ${COLORS.dimgrey};
    cursor: pointer;
  }
`;

export const SettingName = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

export const VerifyAoAStatus = styled.div`
  color: ${COLORS.green};

  &.no-auth {
    color: ${COLORS.red};
  }
`;

export const UploadNewAoAContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-width: 250px;
  gap: 16px;

  svg {
    margin-block: auto;
    cursor: pointer;
    min-width: 18px;
    max-width: 18px;
    fill: ${COLORS.dimgrey};
  }

  @media screen and (${BREAKPOINTS.xl}) {
    min-width: 350px;
  }
`;

export const PdfInput = styled.input`
  display: none;
`;
