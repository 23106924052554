import { forwardRef, useEffect, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useDebounce } from "hooks";
import { getImageFromTypedSignature } from "utils/helpers";

import { AoADocument } from "./AoADocument";

export const DownloadAoADocumentPreview = forwardRef(({ owner, properties = [], signature, signType }, typedSignRef) => {
    const debouncedSignature = useDebounce(signature, 100);
    const processedSignature = signType === "type" ? debouncedSignature : signature;
    const [transformedSignature, setTransformedSignature] = useState(null);

    useEffect(() => {
        async function transformSignature() {
            const result = await getImageFromTypedSignature({
                signature: processedSignature,
                signType,
                typedSignRef,
            });
            setTransformedSignature(result);
        }

        transformSignature();
    }, [processedSignature, signType, typedSignRef]);

    return navigator.userAgent.toLowerCase().includes("safari") ? (
        <PDFDownloadLink
            document={
                <AoADocument
                    ref={typedSignRef}
                    isDownloadCase
                    owner={owner}
                    properties={properties}
                    signature={transformedSignature}
                    signType={signType} />
            }
            fileName="aoa.pdf"
        >
            {({ url, loading }) =>
                loading ? (
                    "Loading document..."
                ) : (
                    <a href={url} rel="noopener noreferrer" target="_blank">
                        Download Documents
                    </a>
                )
            }
        </PDFDownloadLink>
    ) : (
        <PDFDownloadLink
            document={
                <AoADocument
                    ref={typedSignRef}
                    isDownloadCase
                    owner={owner}
                    properties={properties}
                    signature={transformedSignature}
                    signType={signType} />
            }
            fileName="aoa.pdf"
        >
            {({ loading }) => (loading ? "Loading document..." : "Download Documents ")}
        </PDFDownloadLink>
    );
});

DownloadAoADocumentPreview.displayName = "DownloadAoADocumentPreview";