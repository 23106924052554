import { StyleSheet } from "@react-pdf/renderer";
import { COLORS } from "assets/styles/colors";

export const styles = StyleSheet.create({
  document: {
    padding: 24,
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  docHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    backgroundColor: COLORS.light_gray_50,
    marginVertical: 4,
    fontFamily: "Serif",
  },
  docHeaderText: {
    fontSize: 11,
    paddingHorizontal: 12,
    paddingVertical: 5,
  },
  docHeaderText2: {
    backgroundColor: COLORS.blue,
    color: COLORS.white,
    textAlign: "center",
    width: 100,
  },
  docTitle: {
    marginTop: 20,
    marginBottom: 10,
    fontSize: 20,
    color: COLORS.blue,
    fontWeight: "light",
  },
  description: {
    fontSize: 8,
    lineHeight: 1.2,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
  },
  footerText: {
    fontSize: 7,
    lineHeight: 1.2,
  },
});
