import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Can } from "@casl/react";
import { Icons } from "assets/svgs";
import { Loader } from "common";
import { usePermissions } from "hooks/usePermissions";
import { adminRoutes } from "router/routes";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { adminSignInActions } from "store/admin-slices/signin-slice/slice";
import { PERMISSIONS_LIST } from "utils/constants";

import * as S from "./styled";

export const AdminNotification = ({ notifications: initialNotifications, showSidebar, onClose }) => {
  const { userInfo } = useSelector(adminSignInSelectors);

  const [notifications, setNotifications] = useState(initialNotifications);
  const [deletingNotificationId, setDeletingNotificationId] = useState(null);

  const sidebarRef = useRef(null);

  const ability = usePermissions(userInfo.permissions);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigateWithLink = (notification) => {
    if (notification.data.property_id && notification.data.owner_id) {
      navigate(
        `${adminRoutes.prefix}/${adminRoutes.owners}/${notification.data.owner_id}/${notification.data.property_id}`
      );
      onClose();
    }
  };

  const handleDeleteNotification = async (id) => {
    setDeletingNotificationId(id);

    try {
      await dispatch(adminSignInActions.deleteNotification(id)).unwrap();
      setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== id));
    } finally {
      setDeletingNotificationId(null);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (showSidebar) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.classList.add("prevent-scroll");
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.classList.remove("prevent-scroll");
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSidebar, onClose]);

  useEffect(() => {
    if (showSidebar) {
      const unreadNotificationIds = notifications.filter((notif) => !notif.read_at).map((notif) => notif.id);

      if (unreadNotificationIds.length > 0) {
        dispatch(adminSignInActions.readAllNotifications({ notification_ids: unreadNotificationIds }));
      }
    }
  }, [notifications, showSidebar, dispatch]);

  useEffect(() => {
    setNotifications(initialNotifications);
  }, [initialNotifications]);

  return (
    <S.SidebarOverlay show={showSidebar ? "true" : "false"}>
      <S.NotificationContainer ref={sidebarRef} show={showSidebar ? "true" : "false"}>
        <S.CloseButton onClick={onClose}>
          <Icons.CloseIcon />
        </S.CloseButton>
        <S.SidebarContent>
          <S.Title>Notifications</S.Title>
          <S.Notifications>
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <S.NotificationContent
                  key={notification.id}
                  read={notification.read_at !== null ? "true" : "false"}
                  onClick={() => handleNavigateWithLink(notification)}
                >
                  {notification.data.message}
                  {deletingNotificationId === notification.id ? (
                    <Loader />
                  ) : (
                    <Can ability={ability} I={PERMISSIONS_LIST.removeNotifications}>
                      <Icons.CloseIcon
                        className="close-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteNotification(notification.id);
                        }}
                      />
                    </Can>
                  )}
                </S.NotificationContent>
              ))
            ) : (
              <S.EmptyNotification>No notifications available</S.EmptyNotification>
            )}
          </S.Notifications>
        </S.SidebarContent>
      </S.NotificationContainer>
    </S.SidebarOverlay>
  );
};
