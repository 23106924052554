import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { ownerPortalPropertyApi } from "./services";

export const propertySearch = createAsyncThunk(
  `${SLICE_NAME.ownerPortalProperty}/propertySearch`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await ownerPortalPropertyApi.propertySearch(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postProperty = createAsyncThunk(
  `${SLICE_NAME.ownerPortalProperty}/postProperty`,
  async ({ ownerId, ...body }, { rejectWithValue, dispatch }) => {
    try {
      const response = await ownerPortalPropertyApi.postProperty(body);

      const property_id = response.data.data.id;

      dispatch(
        connectOwnerProperty({
          owner_id: ownerId,
          property_id,
        })
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateOwner = createAsyncThunk(
  `${SLICE_NAME.ownerPortalProperty}/updateOwner`,
  async ({ body, id }, { rejectWithValue }) => {
    try {
      const response = await ownerPortalPropertyApi.putOwner({ body, id });
      response.data.data = body;
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postAccountLogs = createAsyncThunk(
  `${SLICE_NAME.ownerPortalProperty}/postAccountLogs`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await ownerPortalPropertyApi.postAccountLogs(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const connectOwnerProperty = createAsyncThunk(
  `${SLICE_NAME.ownerPortalProperty}/connectOwnerProperty`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await ownerPortalPropertyApi.connectOwnerProperty(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const propertySearchFromDb = createAsyncThunk(
  `${SLICE_NAME.ownerPortalProperty}/propertySearchFromDb`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await ownerPortalPropertyApi.propertySearchFromDb(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const completeAddProperty = createAsyncThunk(
  `${SLICE_NAME.ownerPortalProperty}/completeAddProperty`,
  async (body, { rejectWithValue }) => {
    try {
      await ownerPortalPropertyApi.completeAddProperty(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addPropertyInLeads = createAsyncThunk(
  `${SLICE_NAME.ownerPortalProperty}/addPropertyInLeads`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await ownerPortalPropertyApi.postPropertyLeads(body);
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deletePropertyLeads = createAsyncThunk(
  `${SLICE_NAME.ownerPortalProperty}/deletePropertyLeads`,
  async (body, { rejectWithValue }) => {
    try {
      await ownerPortalPropertyApi.deletePropertyLeads(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postPropertyId = createAsyncThunk(
  `${SLICE_NAME.ownerPortalProperty}/postPropertyId`,
  async (id, { rejectWithValue }) => {
    try {
      await ownerPortalPropertyApi.postPropertyId(id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postSubmitEvidence = createAsyncThunk(
  `${SLICE_NAME.ownerPortalProperty}/postSubmitEvidence`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await ownerPortalPropertyApi.postSubmitEvidence(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const authedOrNot = createAsyncThunk(
  `${SLICE_NAME.ownerPortalProperty}/authedOrNot`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await ownerPortalPropertyApi.authedOrNot(id);
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
