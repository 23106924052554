import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid ${COLORS.green};
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 10px;

  @media screen and (${BREAKPOINTS.lg}) {
    padding: 18px;
    gap: 16px;
  }
`;

export const AccountTitleSection = styled.div`
  width: 120px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const AccountTitle = styled.div`
  width: 80px;
  height: 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
`;

export const AccountIcon = styled.div`
  width: 24px;
  height: 30px;
  svg {
    width: 24px;
    height: 24px;
  }
`;
export const AccountInfoSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const AccountInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
`;

export const Label = styled.p`
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  font-weight: 400;
  width: 40%;
`;

export const Values = styled.b`
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  font-weight: 600;
  width: 60%;
`;
