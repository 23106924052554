import { AgentIcon } from "./AgentIcon";
import { ArchivedIcon } from "./ArchivedIcon";
import { ArrowDownIcon } from "./ArrowDownIcon";
import { ArrowRightIcon } from "./ArrowRightIcon";
import { ArrowUpDownIcon } from "./ArrowUpDownIcon";
import { ArrowUpSortIcon } from "./ArrowUpSortIcon";
import { AvatarIcon } from "./AvatarIcon";
import { BurgerIcon } from "./BurgerIcon";
import { CheckIcon } from "./CheckIcon";
import { ClarityProcessIcon } from "./ClarityProcessIcon";
import { ClientEvidenceIcon } from "./ClientEvidenceIcon";
import { CloseIcon } from "./CloseIcon";
import { CreateProfileIcon } from "./CreateProfileIcon";
import { DashboardIcon } from "./DashboardIcon";
import { DocumentIcon } from "./DocumentIcon";
import { EditIcon } from "./EditIcon";
import { EmailIcon } from "./EmailIcon";
import { EvidenceIcon } from "./EvidenceIcon";
import { FlagIcon } from "./FlagIcon";
import { HearingDatesIcon } from "./HearingDatesIcon";
import { HelpIcon } from "./HelpIcon";
import { HidePassword } from "./HidePassword";
import { HomeGreyIcon } from "./HomeGreyIcon";
import { HomeIcon } from "./HomeIcon";
import { InvoicesIcon } from "./InvoicesIcon";
import { LeadIcon } from "./LeadIcon";
import { LikeIcon } from "./LikeIcon";
import { LocationIcon } from "./LocationIcon";
import { LocationIconFilled } from "./LocationIconFilled";
import { LogoutIcon } from "./LogoutIcon";
import NotFound from "./NotFound";
import { NotificationIcon } from "./NotificationIcon";
import { PeopleIcon } from "./PeopleIcon";
import { PhoneIcon } from "./PhoneIcon";
import { PlusIcon } from "./PlusIcon";
import { PropertiesIcon } from "./PropertiesIcon";
import { ProtestedAccountIcon } from "./ProtestedAccountIcon";
import { ReferralIcon } from "./ReferralIcon";
import { ReloadIcon } from "./ReloadIcon";
import { RemoveIcon } from "./RemoveIcon";
import { SearchIcon } from "./SearchIcon";
import { SettingsIcon } from "./SettingsIcon";
import { ShowPasswordIcon } from "./ShowPasswordIcon";
import { SmsEligibilityIcon } from "./SmsEligibilityIcon";
import { SuccessIcon } from "./SuccessIcon";
import { TrashIcon } from "./TrashIcon";
import { UnArchivedIcon } from "./UnArchivedIcon";
import { UploadIcon } from "./UploadIcon";
import { UserGroupIcon } from "./UserGroupIcon";
import { UserIcon } from "./UserIcon";
import { WarningIcon } from "./WarningIcon";

export const Icons = {
  UserIcon,
  ShowPasswordIcon,
  ArrowUpSortIcon,
  ArrowUpDownIcon,
  HomeGreyIcon,
  RemoveIcon,
  HidePassword,
  HomeIcon,
  ArrowRightIcon,
  LogoutIcon,
  DashboardIcon,
  LeadIcon,
  UserGroupIcon,
  CheckIcon,
  LikeIcon,
  EditIcon,
  BurgerIcon,
  HelpIcon,
  DocumentIcon,
  HearingDatesIcon,
  ClarityProcessIcon,
  LocationIcon,
  PropertiesIcon,
  InvoicesIcon,
  FlagIcon,
  TrashIcon,
  ReloadIcon,
  SearchIcon,
  WarningIcon,
  ProtestedAccountIcon,
  SettingsIcon,
  PlusIcon,
  EvidenceIcon,
  NotFound,
  ArchivedIcon,
  UnArchivedIcon,
  UploadIcon,
  CloseIcon,
  ClientEvidenceIcon,
  SuccessIcon,
  AvatarIcon,
  ArrowDownIcon,
  AgentIcon,
  CreateProfileIcon,
  EmailIcon,
  PhoneIcon,
  LocationIconFilled,
  NotificationIcon,
  ReferralIcon,
  PeopleIcon,
  SmsEligibilityIcon,
};
