import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { ContentHeader, SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components";

export const ArchiveOwnerContent = styled(SinglePageContentShadow)`
  margin-block: 10px;
  width:100%;

  .archive-owner-button {
    margin-block: 10px;
  }

  .delete-modal {
    color: ${COLORS.red};
  }

  @media screen and (${BREAKPOINTS.xl}) {
    width: 50%;
  }
`;

export const ContentTitle = styled(ContentHeader)``;
