import { BREAKPOINTS } from "assets/styles/breakpoints";
import { ContentHeader, SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components";

export const ArchiveOwnerContent = styled(SinglePageContentShadow)`
  height: fit-content !important;
  margin-block: 10px;
  width: 100%;
  
  .archive-owner-button {
    margin-block: 10px;
  }

  @media screen and (${BREAKPOINTS.xl}) {
    width: ${(props) => (props.leads ? "100%" : "50%")};
  }
`;

export const ContentTitle = styled(ContentHeader)``;
