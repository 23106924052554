import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "assets/styles/colors";
import { Icons } from "assets/svgs";
import { PrimaryButton } from "common";
import Stepper from "common/Stepper";
import { agentsSelector } from "store/admin-slices/agents-slice/selectors";
import { agentsActions } from "store/admin-slices/agents-slice/slice";

import Step1 from "./step-1";
import Step2 from "./step-2";
import * as S from "./styled";

const CreateProfile = () => {
  const { createProfileStep, isModalOpen } = useSelector(agentsSelector);

  const dispatch = useDispatch();
  const openModal = () => {
    dispatch(agentsActions.setIsModalOpen(true));
    dispatch(agentsActions.getPermissions());
  };
  const closeModal = () => {
    dispatch(agentsActions.setIsModalOpen(false));
    dispatch(agentsActions.resetCreateProfileData());
  };

  const stepObj = {
    0: <Step1 />,
    1: <Step2 />,
  };

  return (
    <S.Container>
      <PrimaryButton
        eventHandler={openModal}
        iconEnd={<Icons.CreateProfileIcon fill={COLORS.lightgray} />}
        label="Create profile"
        mode="gray"
      />
      {isModalOpen ? (
        <S.ModalContainer>
          <S.StepArea className="p-20 pt-10 pb-10 sm:w-4/5 md:w-4/5 lg:w-3/5 xl:w-1/2 max-h-[636px] overflow-y-auto">
            <Icons.CloseIcon className="close-button" onClick={closeModal} />
            <Stepper maxStep={2} step={createProfileStep} />
            {stepObj[createProfileStep]}
          </S.StepArea>
        </S.ModalContainer>
      ) : null}
    </S.Container>
  );
};

export default CreateProfile;
