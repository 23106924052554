import { Icons } from "assets/svgs";

import * as S from "./styled";
export const PropertyWidget = ({ property, children }) => {
  return (
    <S.PropertyLink key={property?.id} className="text-green-700 text-base">
      <S.PropertyContent>
        <S.PropertyIconWrapper>
          <Icons.PropertiesIcon height={32} width={32} />
        </S.PropertyIconWrapper>
        <S.PropertyText>
          {property?.street_address} | {property?.county} 
        </S.PropertyText>
        {children}
      </S.PropertyContent>
    </S.PropertyLink>
  );
};
