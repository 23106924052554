import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Can } from "@casl/react";
import { Loader, PrimaryButton } from "common";
import Stepper from "common/Stepper";
import { usePermissions } from "hooks/usePermissions";
import { adminRoutes, clientRoutes } from "router/routes";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";
import { isLoading, PERMISSIONS_LIST } from "utils/constants";
import { transformToClassicDate } from "utils/helpers";

import { Invoices } from "components/Invoices";

import { ReferredBy } from "./Referrals/ReferredBy";
import { ReferredTo } from "./Referrals/ReferredTo";
import { ArchiveOwner } from "./ArchiveOwner";
import { ClientDetails } from "./ClientDetails";
import { Discount } from "./Discount";
import { Documents } from "./Documents";
import { EditOwner } from "./EditOwner";
import { Flags } from "./Flags";
import { Notes } from "./Notes";
import { TrackerTable } from "./OwnerTrackerTable";
import { Properties } from "./Properties";
import * as S from "./styled";

const MAX_STEP = 7;
export const OwnersSinglePage = ({ leads }) => {
  const { getSingleOwnerStatus, singleOwner, getReferralsStatus, referrals, selectedPropertyId } =
    useSelector(singleOwnerSelectors);

  const { userInfo } = useSelector(adminSignInSelectors);

  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const ability = usePermissions(userInfo.permissions);

  const isPageLoading = isLoading(getSingleOwnerStatus) || !singleOwner || isLoading(getReferralsStatus) || !referrals;

  const protestStep = singleOwner?.properties.find((item) => item.id === Number(selectedPropertyId));

  const goBack = () => {
    if (leads) {
      navigate(`${adminRoutes.prefix}/${adminRoutes.leads}`);
    } else {
      navigate(`${adminRoutes.prefix}/${adminRoutes.owners}`);
    }
  };

  useEffect(() => {
    dispatch(singleOwnerActions.getSingleOwner({ id }));
    dispatch(singleOwnerActions.getReferrals(id));
    dispatch(singleOwnerActions.getReferredBy(id));
    return () => dispatch(singleOwnerActions.resetAllData());
  }, [id]);

  useEffect(() => {
    const fetchNotes = async () => {
      if (selectedPropertyId) {
        await dispatch(singleOwnerActions.getNotes({ id: selectedPropertyId, params: { is_owner: 0 } }));
        await dispatch(singleOwnerActions.getNotes({ id: selectedPropertyId, params: { is_owner: 1 } }));
      }
    };

    fetchNotes();
  }, [selectedPropertyId]);

  if (isPageLoading) {
    return (
      <S.LoaderWrapper>
        <Loader size={70} />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.OwnersSinglePageContainer>
      <PrimaryButton className="back-button" eventHandler={goBack} label="Back" />
      {!leads && (
        <S.StepperContainer>
          <Stepper
            isHoverEnabled
            isShowDescription
            descriptions={[
              "Property Signed Up",
              "Forms sent to CAD",
              "Protest Filed",
              "Deadline to submit client evidence",
              "Hearing Date Scheduled",
              "Evidence being curated",
              "Protest completed",
            ]}
            hoverDescriptions={[
              "Your registration has been received and will be processed within 1 business day",
              "Your forms have been reviewed for accuracy and sent to the appraisal district",
              "Your protest has been formally filed with the appraisal district",
              `If you have any additional evidence that you would like us to use in your protest, it must be submitted by ${transformToClassicDate(
                protestStep?.deadline_date
              )}`,
              `Hearing Date Scheduled: ${
                transformToClassicDate(protestStep?.property_hearing_date) || "No date available"
              }`,
              "We are in the process of finding the best comparables and market data to use as evidence for your protest",
              `<a href="${
                clientRoutes.prefix + clientRoutes.invoices
              }" target="_blank" style="color:grey; text-decoration:underline;">Link to invoice tab</a>`,
            ]}
            maxStep={MAX_STEP}
            step={
              Number(protestStep?.protest_step)
                ? Number(protestStep?.protest_step) === MAX_STEP
                  ? Number(protestStep?.protest_step)
                  : Number(protestStep?.protest_step) + 1 - 0.5
                : 1 - 0.4
            }
            width="120%"
          />
        </S.StepperContainer>
      )}
      <Properties leads={leads} />

      <S.Flex>
        <ClientDetails />
        <Documents leads={leads} />
      </S.Flex>

      {!leads && (
        <>
          <ReferredTo />
          <ReferredBy />
          <Discount />
        </>
      )}

      <S.Flex>
        <Flags />
        {!leads && <Notes isAdminNote className="property_notes" title="Admin Notes" />}
      </S.Flex>

      <EditOwner leads={leads} />
      <S.Flex>
        <Can ability={ability} I={PERMISSIONS_LIST.archiveOwner}>
          <ArchiveOwner leads={leads} />
        </Can>
        {!leads && <Invoices invoices={singleOwner?.invoices} />}
      </S.Flex>
      <Can ability={ability} I={PERMISSIONS_LIST.ownerActionTracker}>
        <TrackerTable className={leads ? "textEllipse" : ""} />
      </Can>
    </S.OwnersSinglePageContainer>
  );
};
