import { forwardRef, useMemo, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { COLORS } from "assets/styles/colors";
import { ErrorMessage, PrimaryButton, PrimaryInput } from "common";

import * as S from "./styled";

export const Signature = forwardRef(({ signatureErr, signType, setSignature, deleteSignature, setSignType }, typedSignRef) => {
  const [typedSign, setTypedSign] = useState("");

  const drawSignRef = useRef(null);

  const errorClassName = signatureErr ? "error" : "";

  const handleSignTypeClick = (type) => {
    deleteSignature();
    setSignType(type);
    setTypedSign("");
  };

  const handleSignChange = (e) => {
    const signatureString = e.target.value;
    if(signatureString) {
      setSignature(signatureString);
      setTypedSign(signatureString);
    } else {
      deleteSignature();
      setTypedSign("");
    }
  };

  const handleCanvasEnd = () => {
    const signature = drawSignRef.current.getTrimmedCanvas().toDataURL("image/png");
    setSignature(signature);
  };

  const handleDrawSignClear = () => {
    drawSignRef.current.clear();
    deleteSignature();
  };

  const getSignType = useMemo(
    () => ({
      type: (
        <S.SignatureTypeContent>
          <S.SignatureInputContent>
            Full Name:
            <PrimaryInput
              placeholder="Full Name"
              value={typedSign}
              wrapperClassName="signature-input"
              onChange={handleSignChange}
            />
          </S.SignatureInputContent>
          <S.TypedSignature>
            <S.Box ref={typedSignRef}>{typedSign}</S.Box>
          </S.TypedSignature>
        </S.SignatureTypeContent>
      ),
      draw: (
        <>
          <SignatureCanvas
            ref={drawSignRef}
            canvasProps={{ className: "sigCanvas" }}
            clearOnResize={false}
            penColor={COLORS.deepDark}
            onEnd={handleCanvasEnd}
          />
          <PrimaryButton eventHandler={handleDrawSignClear} label="Clear" />
        </>
      ),
    }),
    [typedSign]
  );

  return (
    <S.SignatureContainer>
      <S.SignatureBlockTitle>Choose one of these options:</S.SignatureBlockTitle>
      <S.SignatureHeader>
        <S.SignatureHeaderItem
          className={`${signType === "type" ? "selected" : ""} ${errorClassName} `}
          onClick={() => handleSignTypeClick("type")}
        >
          Type
        </S.SignatureHeaderItem>
        <S.SignatureHeaderItem
          className={`${signType === "draw" ? "selected" : ""} ${errorClassName} `}
          onClick={() => handleSignTypeClick("draw")}
        >
          Draw
        </S.SignatureHeaderItem>
      </S.SignatureHeader>
      <S.SignatureContent className={errorClassName}>{getSignType[signType]}</S.SignatureContent>
      {!!signatureErr && <ErrorMessage children={signatureErr} />}
    </S.SignatureContainer>
  );
});

Signature.displayName = "Signature";