import { AccountIcon } from "assets/svgs/AccountIcon";
import * as S from "pages/client/PropertiesPage/SingleProperty/PropertyAccount/styled";

export const PropertyAccount = ({ property }) => {
  return (
    <S.Container>
      <S.AccountTitleSection>
        <S.AccountIcon>
          <AccountIcon />
        </S.AccountIcon>
        <S.AccountTitle>Account</S.AccountTitle>
      </S.AccountTitleSection>
      <S.AccountInfoSection>
        <S.AccountInfoWrapper>
          <S.Label>Property ID:</S.Label> <S.Values>{property?.account_number}</S.Values>
        </S.AccountInfoWrapper>
        <S.AccountInfoWrapper>
          <S.Label>Account Active Since:</S.Label>
          <S.Values>{property.account_active_since ? property?.account_active_since : "N/A"}</S.Values>
        </S.AccountInfoWrapper>
      </S.AccountInfoSection>
    </S.Container>
  );
};
