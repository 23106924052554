import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 75px);
  display: flex;
  justify-content: center;
`;

export const RenewDocumentWrapper = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.primary};
  border-radius: 10px;
  background-color: ${COLORS.white};
  padding-inline: 16px;
  padding-block: 20px;
  max-width: 600px;
  height: fit-content;
`;

export const Box = styled.div`
  font-size: 14px;
  @media screen and (${BREAKPOINTS.md}) {
    font-size: 14px;
  }
`;

export const RenewDocumentHeader = styled.div`
  border: 1px solid ${COLORS.green};
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

export const InfoBlock = styled.div`
  width: 100px;

  @media screen and (${BREAKPOINTS.md}) {
    width: 30%;
  }
`;

export const Block = styled.div``;

export const SubTitle = styled.p`
  font-weight: 600;
  margin-block: 10px;
  font-size: 20px;
`;

export const Ul = styled.ul`
  list-style-type: none;
  margin-left: 20px;
`;

export const Li = styled.li`
  &::before {
    content: "";
    padding: 3px;
    margin-right: 10px;
    border-radius: 100%;
    background-color: ${COLORS.green};
    display: inline-block;
    margin-left: -1em;
  }
`;

export const ViewDoc = styled.div`
  font-size: 16px;
  display: none;
  font-weight: bold;
  color: ${COLORS.green};
  text-decoration: underline;
  text-underline-offset: 8px;
  text-underline-color: ${COLORS.green};

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 20px;
    display: block;
  }
`;

export const preview = styled.span`
  cursor: pointer;
`;

export const DownloadDoc = styled(ViewDoc)`
  display: block;

  @media screen and (${BREAKPOINTS.md}) {
    display: none;
  }
`;

export const PdfLink = styled.a``;

export const LoaderBox = styled.div`
  display: flex;
  justify-content: center;
  min-height: 80vh;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: 16px;
  row-gap: 10px;
  align-items: center;

  button {
    width: 100%;
  }

  @media screen and (${BREAKPOINTS.md}) {
    flex-direction: row;
    column-gap: 10px;

    button {
      width: unset;
    }
  }
`;
