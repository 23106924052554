import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const AccountBlock = styled.div`
  height: fit-content;
  padding: 32px;
  background: ${COLORS.overlay_060};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-width: 600px;
  width: 100%;

  .contact-us-button {
    background: ${COLORS.deepDark};
    width: fit-content;
    margin-top: 20px;
  }

  input {
    font-size: 14px;
    line-height: 21px;

    @media screen and (${BREAKPOINTS.md}) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const AccountInfo = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 300;
  margin-left: 24px;

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 16px;
    line-height: 24px;
    margin-left: 24px;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 300;
  margin-left: 24px;

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 16px;
    line-height: 24px;
    margin-left: 24px;
  }
`;

export const AccountInfoHeading = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;

  @media screen and (${BREAKPOINTS.md}) {
    font-weight: 800;
    font-size: 14px;
  }
`;

export const AccountTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
    fill: ${COLORS.primary};
  }
`;

export const EditButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
    fill: ${COLORS.primary};
  }
`;

export const AccountInfoTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: ${COLORS.primary};
  text-transform: uppercase;

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const AccountInfoBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const LoaderBox = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
`;

export const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  column-gap: 8px;

  .mailing-address-input {
    width: 100%;
  }
`;
