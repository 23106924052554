import { IMAGES } from "assets/images";
import styled from "styled-components/macro";

export const StyledHomePageContainer = styled.section`
  min-height: calc(100vh - 300px - 90px);
  background-image: url(${IMAGES.home_3});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
