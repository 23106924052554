import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icons } from "assets/svgs";
import clsx from "clsx";
import { Loader, PrimaryButton, PrimaryInput, PrimaryTextarea, Select } from "common";
import { SuccessModal } from "pages/client/OwnerPortalEvidencePage/SuccessModal";
import { clientRoutes } from "router/routes";
import { getProperties, getSingleProperty } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { ownerPortalPropertySelector } from "store/owner-portal-property-slice/selectors";
import { ownerPortalPropertyActions } from "store/owner-portal-property-slice/slice";
import { EVIDENCE_TYPES_ITEMS, isLoading, LOADING_STATUSES } from "utils/constants";
import { toggleClassOnRoot } from "utils/helpers";
import { v4 as uuid } from "uuid";
import { ownerPortalSubmitEvidenceSchema } from "validations/ownerPortalSubmitEvidenceSchema";

import * as S from "./styled";

const OwnerPortalEvidencePage = ({ addressSelectOptions, account, property }) => {
  const { postEvidenceStatus, areEvidenceTypesValid, isOpenEvidenceSuccessModal } =
    useSelector(ownerPortalPropertySelector);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      address: property.street_address,
      client_email: account.email,
      first_name: account.first_name,
      last_name: account.last_name,
    },
    resolver: yupResolver(ownerPortalSubmitEvidenceSchema),
  });

  const findSelectedPropertyId = (value) => {
    const id = addressSelectOptions.find((option) => option.value === value).id;
    navigate(`${clientRoutes.prefix}${clientRoutes.submitEvidence}/${id}`);
  };

  const handleEvidenceTypeChange = (label, fileItemId) => {
    const updatedEvidenceTypes = uploadedFiles.map((item) => {
      if (item.id === fileItemId) {
        return { id: item.id, file: item.file, evidence_type: label };
      }
      return item;
    });
    setUploadedFiles(updatedEvidenceTypes);
  };

  const handleFileDelete = (id) => {
    const updatedFiles = [...uploadedFiles];
    const newFiles = updatedFiles.filter((item) => item.id !== id);
    setUploadedFiles(newFiles);
    const filesObject = {};
    newFiles.forEach((item, index) => {
      filesObject[index] = item.file;
    });
    setValue("files", filesObject);
    const fileInput = document.getElementById("file-upload");
    fileInput.value = null;
  };

  const onSubmit = (data) => {
    if (!areEvidenceTypesValid || !isValid || uploadedFiles?.some((item) => item?.isTooLarge)) {
      return;
    }

    const formData = new FormData();

    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("client_email", data.client_email);
    formData.append("address", data.address);
    formData.append("property_id", property.id);
    formData.append("description", data.description);
    formData.append("county", property.county);
    uploadedFiles.forEach((item, index) => {
      formData.append(`files[${index}][evidence_type]`, item.evidence_type);
    });
    uploadedFiles.forEach((item, index) => {
      formData.append(`files[${index}][file]`, item.file);
    });

    dispatch(ownerPortalPropertyActions.postSubmitEvidence(formData));
  };

  const setFiles = (files) => {
    if (Object.keys(files)?.length) {
      Object.keys(files)?.forEach((key) => {
        setUploadedFiles((prevState) => [
          ...prevState,
          { id: uuid(), evidence_type: "", error: { message: "" }, file: files[`${key}`] },
        ]);
      });
    }
  };

  const submitAdditionalEvidence = () => {
    toggleClassOnRoot();
    dispatch(ownerPortalPropertyActions.setIsOpenEvidenceSuccessModal(false));
    dispatch(ownerPortalPropertyActions.resetAllData());
    reset();
    setUploadedFiles([]);
  };

  useEffect(() => {
    if (uploadedFiles.length) {
      const isEvidenceTypesValid = uploadedFiles.every((file) => file.evidence_type);

      const sizeCheckedFiles = uploadedFiles.map((file) => {
        if (file.file.size > 15 * 1024 * 1024) {
          return { ...file, isTooLarge: true };
        }
        return file;
      });

      if (JSON.stringify(sizeCheckedFiles) !== JSON.stringify(uploadedFiles)) {
        setUploadedFiles(sizeCheckedFiles);
      }

      dispatch(ownerPortalPropertyActions.setAreEvidenceTypesValid(isEvidenceTypesValid));
    } else {
      dispatch(ownerPortalPropertyActions.setAreEvidenceTypesValid(false));
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (postEvidenceStatus === LOADING_STATUSES.succeeded) {
      toggleClassOnRoot();
      reset();
      dispatch(ownerPortalPropertyActions.setIsOpenEvidenceSuccessModal(true));
    }
  }, [postEvidenceStatus]);

  useEffect(() => {
    if (postEvidenceStatus === LOADING_STATUSES.failed) {
      toast.error("The file content is too large");
    }
  }, [postEvidenceStatus]);

  return (
    <S.SubmitEvidenceForm onSubmit={handleSubmit(onSubmit)}>
      <S.EvidenceContainer>
        <S.EvidenceSection>
          <S.EvidenceContent>
            <S.EvidenceTitle>Submit Your Own Evidence (Optional)</S.EvidenceTitle>
            <PrimaryInput
              error={errors.first_name}
              label="First Name"
              register={register("first_name")}
              wrapperClassName="input-wrapper"
            />
            <PrimaryInput
              error={errors.last_name}
              label="Last Name"
              register={register("last_name")}
              wrapperClassName="input-wrapper"
            />
            <PrimaryInput
              error={errors.client_email}
              label="Email Address"
              register={register("client_email")}
              wrapperClassName="input-wrapper"
            />
            <Controller
              control={control}
              name="address"
              render={({ field: { value, onChange } }) => (
                <Select
                  defaultValue={value}
                  error={errors.address}
                  itemKey="label"
                  items={addressSelectOptions}
                  label="Address"
                  value={value}
                  onChange={(value) => {
                    findSelectedPropertyId(value);
                    onChange(value);
                  }}
                />
              )}
            />
            <PrimaryTextarea
              error={errors.description}
              label="Description of Evidence*"
              register={register("description")}
            />
          </S.EvidenceContent>

          <S.UploadedFilesContainer>
            <S.UploadFileSection>
              <S.UploadTitle>
                Upload your evidence <br /> (max size 15 MB per file)
              </S.UploadTitle>
              <S.FileUploadButton htmlFor="file-upload">
                <S.UploadButton className={errors.files ? "invalid" : ""}>
                  <Icons.UploadIcon />
                  UPLOAD FILE
                  <Controller
                    control={control}
                    name="files"
                    render={({ field: { onChange, onBlur, ref }, fieldState: { error } }) => (
                      <S.FileInput
                        ref={ref}
                        multiple
                        accept=".pdf, .xlsx, .png, .jpeg, .jpg, .doc, .docx, .csv, .txt"
                        error={error}
                        id="file-upload"
                        type="file"
                        onBlur={onBlur}
                        onChange={(e) => {
                          if (e.target.files[0]) {
                            onChange(e.target.files);
                            setFiles(e.target.files);
                          }
                        }}
                      />
                    )}
                  />
                </S.UploadButton>
              </S.FileUploadButton>
            </S.UploadFileSection>

            <S.EvidenceFiles>
              {uploadedFiles
                ? uploadedFiles.map((fileItem, index) => (
                    <div key={index} className=" flex flex-col ">
                      <S.UploadFilesTitles>Choose evidence type for each file</S.UploadFilesTitles>

                      <S.UploadedFiles key={fileItem.id}>
                        <Select
                          className="evidence-type w-full"
                          error={fileItem?.error}
                          itemKey="label"
                          items={EVIDENCE_TYPES_ITEMS}
                          label="Evidence Type*"
                          value={fileItem.evidence_type}
                          onChange={(selectedLabel) => handleEvidenceTypeChange(selectedLabel, fileItem.id)}
                        />
                        <S.FileWrapper>
                          <S.File>
                            <S.FileText>
                              {fileItem.file.name.length > 30
                                ? `${fileItem.file.name.substring(0, 30)}...`
                                : fileItem.file.name}
                            </S.FileText>
                            <Icons.CloseIcon className="close-button" onClick={() => handleFileDelete(fileItem.id)} />
                          </S.File>
                          <div
                            className={clsx("text-red-500 text-xs mt-1 text-end", {
                              hidden: !fileItem.isTooLarge,
                            })}
                          >
                            The file is larger than 15MB. You can send this file to:
                            <a
                              className="text-[#437f55] pl-1"
                              href="mailto:info@propertytaxprotest.com"
                              rel="noreferrer"
                              target="_blank"
                            >
                              info@propertytaxprotest.com
                            </a>
                            .
                          </div>
                        </S.FileWrapper>
                      </S.UploadedFiles>
                    </div>
                  ))
                : null}
            </S.EvidenceFiles>
          </S.UploadedFilesContainer>

          <PrimaryButton
            className="submit-button"
            label="Submit Evidence"
            loading={isLoading(postEvidenceStatus)}
            type="submit"
          />
        </S.EvidenceSection>
      </S.EvidenceContainer>
      {isOpenEvidenceSuccessModal && <SuccessModal submitAdditionalEvidence={submitAdditionalEvidence} />}
    </S.SubmitEvidenceForm>
  );
};

export const OwnerPortalEvidencePageWrapper = () => {
  const { account, properties, property } = useSelector(clientSelectors);

  const dispatch = useDispatch();
  const { id } = useParams();

  const addressSelectOptions = useMemo(
    () =>
      properties.map((property) => ({
        id: property.id,
        label: property.street_address,
        value: property.street_address,
      })),
    [properties]
  );

  useEffect(() => {
    dispatch(getSingleProperty(id));
  }, [id]);

  useEffect(() => {
    dispatch(getProperties());
  }, []);

  if (!account || !addressSelectOptions.length || !property) {
    return (
      <S.LoaderWrapper>
        <Loader size={80} />
      </S.LoaderWrapper>
    );
  }

  return <OwnerPortalEvidencePage account={account} addressSelectOptions={addressSelectOptions} property={property} />;
};
