import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Can } from "@casl/react";
import { Icons } from "assets/svgs";
import { Loader } from "common";
import { usePermissions } from "hooks/usePermissions";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";
import { isLoading, LOADING_STATUSES, PERMISSIONS_LIST } from "utils/constants";

import * as S from "./styled";

const Flag = ({ accLog, selectedRowId }) => {
  const { deleteAccountsLogStatus } = useSelector(singleOwnerSelectors);
  const { userInfo } = useSelector(adminSignInSelectors);

  const dispatch = useDispatch();

  const ability = usePermissions(userInfo.permissions);

  const isPendingDeleteFlag = isLoading(deleteAccountsLogStatus) && accLog.id === selectedRowId;

  const openDeleteFlagModal = (id) => {
    dispatch(singleOwnerActions.setFlagId(id));
    dispatch(singleOwnerActions.setIsDeleteFlagModalOpen(true));
  };

  useEffect(() => {
    if (deleteAccountsLogStatus === LOADING_STATUSES.succeeded) {
      dispatch(singleOwnerActions.setIsDeleteFlagModalOpen(false));
    }
  }, [deleteAccountsLogStatus]);

  return (
    <Can passThrough ability={ability} I={PERMISSIONS_LIST.addDeleteFlag}>
      {(allowed) =>
        allowed ? (
          <S.Flag>
            {isPendingDeleteFlag ? (
              <Loader />
            ) : (
              <>
                <S.IconWrapper
                  disabled={isLoading(deleteAccountsLogStatus)}
                  onClick={() => openDeleteFlagModal(accLog.id)}
                >
                  <Icons.TrashIcon />
                </S.IconWrapper>
              </>
            )}
            <S.FlagItem title={accLog.type}>{accLog.type}</S.FlagItem>
            <S.FlagItem title={accLog.content}>{accLog.content}</S.FlagItem>
          </S.Flag>
        ) : (
          <S.Flag>
            <S.FlagItem title={accLog.type}>{accLog.type}</S.FlagItem>
            <S.FlagItem title={accLog.content}>{accLog.content}</S.FlagItem>
          </S.Flag>
        )
      }
    </Can>
  );
};

export default Flag;
