import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const PropertiesScrollBlock = styled.div`
  width: 100%;
`;

export const AccordionWrapper = styled.div`
  * {
    font-family: Montserrat, serif;
  }
`;

export const PropertiesButtonBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 48px;
  gap: 10px;

  .add-another-property {
    height: 50px;
    background-color: ${COLORS.green};
    color: ${COLORS.white};
    border-radius: 4px;
    font-weight: 400;
  }
`;

export const PropertiesLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const LoaderBox = styled.div`
  display: flex;
  justify-content: center;
  min-height: 80vh;
`;

export const PropertyWidgetWrapper = styled.div`
  width: 100%;

  .submit-evidence {
    padding: 10px;
    font-size: 12px;

    @media screen and (${BREAKPOINTS.md}) {
      font-size: 14px;
      white-space: nowrap;
      min-width: max-content;
    }
  }
`;
