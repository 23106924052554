import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PrimaryButton, PrimaryInput, Table } from "common";
import { useDebounce } from "hooks";
import { Filter } from "pages/admin/components/Filter";
import { Paginator } from "primereact/paginator";
import { ownerTrackerListSelectors } from "store/admin-slices/owner-tracker-slice/selectors";
import { ownerTrackerActions } from "store/admin-slices/owner-tracker-slice/slice";
import { isLoading, LOADING_STATUSES } from "utils/constants";
import { downloadFileByLink, getPaginationDescription } from "utils/helpers";

import * as S from "./styled";

const tableData = [
  { headerClassName: "column-header", selectionMode: "multiple" },
  {
    header: "Full Name",
    field: "user_name",
    sortable: true,
  },
  {
    header: "Email",
    field: "email",
    sortable: true,
  },
  {
    header: "Description",
    field: "description",
    sortable: true,
  },
  {
    header: "Creation Date",
    field: "created_at",
    sortable: true,
  },
];

export const TrackerTable = ({ className }) => {
  const { last_page, paginationData, ownerTrackerList, exportCSVStatus, downloadCsvHref, getOwnerTrackerListStatus } =
    useSelector(ownerTrackerListSelectors);

  const [sortData, setSortData] = useState({
    field: "created_at",
    direction: -1,
  });
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [paginationChange, setPaginationChange] = useState({ prev: null, new: paginationData });

  const [startTime, setStartTime] = useState("");
  const debounceStartTime = useDebounce(startTime, 500);

  const [endTime, setEndTime] = useState("");
  const debounceEndTime = useDebounce(endTime, 500);

  const ref = useRef();

  const dispatch = useDispatch();
  const { id } = useParams();
  const getTrackerList = (props) => {
    const params = {
      page: props?.page ?? paginationData.page,
      limit: props?.rows ?? paginationData.rows,
      start_date: debounceStartTime,
      end_date: debounceEndTime,
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "asc" : "desc",
    };
    dispatch(ownerTrackerActions.getOwnerTrackerList({ id, params }));
  };

  const onSort = (e) => setSortData({ field: e.sortField, direction: e.sortOrder });

  const handleExportCsvClick = () => {
    const params = {
      owner_id: id,
      actions: selectedAgents.map((item) => item.id),
      start_date: startTime,
      end_date: endTime,
    };
    dispatch(ownerTrackerActions.exportCSV(params));
  };

  const onPageChange = ({ first, rows, page }) => {
    dispatch(
      ownerTrackerActions.setPaginationData({
        first,
        rows,
        page: page + 1,
      })
    );

    getTrackerList({ page: page + 1, rows });
  };

  useEffect(() => {
    getTrackerList();
  }, [sortData, debounceStartTime, debounceEndTime]);

  useEffect(() => {
    downloadFileByLink(exportCSVStatus, downloadCsvHref, "owners-action-tracking.csv");
  }, [downloadCsvHref]);

  useEffect(() => {
    return () => dispatch(ownerTrackerActions.resetAllData());
  }, []);

  useEffect(() => {
    setPaginationChange({ prev: paginationChange.new, new: paginationData });

    if (
      (paginationChange.prev?.page !== paginationChange.new?.page ||
        paginationChange.prev?.rows !== paginationChange.new?.rows) &&
      getOwnerTrackerListStatus === LOADING_STATUSES.succeeded
    ) {
      ref.current.scrollIntoView({ behavior: "auto" });
    }
  }, [paginationData, getOwnerTrackerListStatus]);

  return (
    <S.Content>
      <S.ContentTitle>Action Tracker</S.ContentTitle>
      <Filter>
        <PrimaryInput placeholder="Start date" type="datetime-local" onChange={(e) => setStartTime(e.target.value)} />
        <PrimaryInput placeholder="End date" type="datetime-local" onChange={(e) => setEndTime(e.target.value)} />
        <PrimaryButton
          eventHandler={handleExportCsvClick}
          label="Download CSV"
          loading={isLoading(exportCSVStatus)}
          mode="gray"
        />
      </Filter>
      <Table
        className={className}
        dataKey="id"
        loading={isLoading(getOwnerTrackerListStatus)}
        selection={selectedAgents}
        sortField={sortData.field}
        sortOrder={sortData.direction}
        tableData={tableData}
        value={ownerTrackerList || []}
        onSelectionChange={(e) => setSelectedAgents(e.value)}
        onSort={onSort}
      />
      <S.PaginatorWrapper ref={ref}>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[10, 50, 100, 200]}
          totalRecords={last_page}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">{getPaginationDescription(paginationData, last_page)}</S.Box>
      </S.PaginatorWrapper>
    </S.Content>
  );
};
