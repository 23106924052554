import axios from "axios";
import { API } from "configs/api";

export const ownerPortalPropertyApi = {
  // Signup
  propertySearch: (id) => axios.get(`${API.getPropertyInfo}/${id}`),
  postProperty: (body) => axios.post(API.properties, body, { noCancel: true }),
  putOwner: ({ body, id }) => axios.put(`${API.signup}/${id}`, body),
  postAccountLogs: (body) => axios.post(API.accountLogs, body, { noCancel: true }),
  connectOwnerProperty: (body) => axios.post(API.propertyOwner, body, { noCancel: true }),
  propertySearchFromDb: (params) => axios.get(API.propertiesSearch, { params }),
  completeAddProperty: (body) => axios.post(API.docs + API.multipleCreate, body, { formData: true }),
  postPropertyLeads: (body) => axios.post(API.propertyOwner, { ...body }),
  deletePropertyLeads: (body) => axios.delete(API.propertyOwner, { data: body }),
  postPropertyId: (id) => axios.post(`${API.signup}${API.completeSignup}/${id}`),
  postSubmitEvidence: (body) => axios.post(API.clientEvidence, body, { formData: true }),
  authedOrNot: (id) => axios.get(`${API.authedOrNot}/${id}`, { owner_token: true }),
};
