import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Can } from "@casl/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton, PrimaryInput, Select } from "common";
import { usePermissions } from "hooks/usePermissions";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";
import { COUNTIES_FILTER, isLoading, LOADING_STATUSES, PERMISSIONS_LIST } from "utils/constants";
import { adminSingleOwnerAddAccountSchema } from "validations";

import { AddManuallyProperty } from "../AddManuallyProperty";

import * as S from "./styled";

export const EditOwner = ({ leads }) => {
  const { singleOwner, connectOwnerPropertyStatus, connectOwnerPropertyData, isShowErrorMessages } =
    useSelector(singleOwnerSelectors);
  const { userInfo } = useSelector(adminSignInSelectors);

  const [isAddAccountLoading, setIsAddAccountLoading] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const ability = usePermissions(userInfo.permissions);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(adminSingleOwnerAddAccountSchema),
  });

  const onSubmit = (data) => {
    const body = {
      account_number: data.accountNumber,
      county: data.county.toLowerCase() === "fortbend" ? "Fort Bend" : data.county,
      owner_id: singleOwner.id,
      unit_id: data.unit,
      is_manually: 0,
    };

    dispatch(singleOwnerActions.connectOwnerProperty({ body, leads, navigate, ownerId: singleOwner.id }));
  };

  useEffect(() => {
    if (connectOwnerPropertyStatus === LOADING_STATUSES.succeeded) {
      setValue("county", "");
      setValue("accountNumber", "");
      setValue("unit", "");
    }
    if (connectOwnerPropertyStatus === LOADING_STATUSES.pending) {
      setIsAddAccountLoading(true);
    } else {
      setIsAddAccountLoading(false);
    }
  }, [connectOwnerPropertyStatus]);

  return (
    <S.Flex>
      <S.Container>
        <Can ability={ability} I={PERMISSIONS_LIST.addAccount}>
          <S.Content>
            <S.ContentTitle>Edit Owner</S.ContentTitle>
            <S.AddAccountContent>
              <S.AddAccountForm onSubmit={handleSubmit(onSubmit)}>
                <S.Box>Add Account</S.Box>
                <S.Box>
                  <PrimaryInput
                    error={errors.accountNumber}
                    placeholder="Account Number"
                    register={register("accountNumber")}
                  />
                </S.Box>
                <S.Box>
                  <Controller
                    control={control}
                    name="county"
                    render={({ field: { value, onChange } }) => (
                      <Select
                        error={errors.county}
                        items={COUNTIES_FILTER.map((item) => item.value)}
                        label="County"
                        value={value}
                        onChange={(value) => onChange({ target: { value } })}
                      />
                    )}
                  />
                </S.Box>
                <S.Box>
                  <PrimaryInput error={errors.unit} placeholder="Unit # if needed" register={register("unit")} />
                </S.Box>
                <S.Box>
                  <PrimaryButton
                    eventHandler={() => dispatch(singleOwnerActions.setIsShowErrorMessages(true))}
                    label="add property to owner"
                    loading={isLoading(isAddAccountLoading)}
                    mode="gray"
                  />
                  {connectOwnerPropertyData && isShowErrorMessages.payload ? (
                    <S.Text className={connectOwnerPropertyData.error ? "error" : ""}>
                      {connectOwnerPropertyData.message}
                    </S.Text>
                  ) : null}
                </S.Box>
              </S.AddAccountForm>
            </S.AddAccountContent>
          </S.Content>
        </Can>
      </S.Container>
      <S.Container>
        <Can ability={ability} I={PERMISSIONS_LIST.addAccount}>
          <AddManuallyProperty />
        </Can>
      </S.Container>
    </S.Flex>
  );
};
