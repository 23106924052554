import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Loader, PrimaryButton, PrimaryInput } from "common";
import { getOwnerReferralCode } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { referralsSelectors } from "store/referral-slice/selectors";
import { referralActions } from "store/referral-slice/slice";
import { isLoading, LOADING_STATUSES } from "utils/constants";
import { referralSchema } from "validations";

import { ReferredBy } from "./ReferredBy";
import { ReferredTo } from "./ReferredTo";
import * as S from "./styled";

export const ClientReferralPage = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(referralSchema),
  });
  const dispatch = useDispatch();

  const { ownerReferralCode, getOwnerReferralCodeStatus } = useSelector(clientSelectors);
  const { postReferralEmailStatus } = useSelector(referralsSelectors);

  const onSubmit = (data) => {
    dispatch(
      referralActions.postReferralEmail({
        email: data.email,
        referral_code: ownerReferralCode,
      })
    );
  };

  useEffect(() => {
    setValue("email", "");
  }, [postReferralEmailStatus]);

  useEffect(() => {
    dispatch(getOwnerReferralCode());
  }, []);

  if (isLoading(getOwnerReferralCodeStatus)) {
    return (
      <S.LoaderBox>
        <Loader size={80} />
      </S.LoaderBox>
    );
  }

  return (
    <S.ReferralContainer>
      {ownerReferralCode ? <S.ReferralTitle> Your Referral code is {ownerReferralCode} </S.ReferralTitle> : null}
      <S.InviteContent>
        <S.InviteParagraph>Invite your friends to sign up by entering their emails.</S.InviteParagraph>
        <S.ReferralForm onSubmit={handleSubmit(onSubmit)}>
          <PrimaryInput
            className="email-input"
            error={errors.email}
            placeholder="Referee's Email"
            readOnly={!ownerReferralCode}
            register={register("email")}
          />
          {postReferralEmailStatus === LOADING_STATUSES.succeeded ? (
            <S.SuccessMessageBlock>Referral Email Sent! Ready to send another one.</S.SuccessMessageBlock>
          ) : null}
          <PrimaryButton
            className="w-fit"
            disabled={!ownerReferralCode}
            label="Invite Friends"
            loading={isLoading(postReferralEmailStatus)}
          />
        </S.ReferralForm>
      </S.InviteContent>
      <ReferredTo />
      <ReferredBy />
    </S.ReferralContainer>
  );
};
