import { Icons } from "assets/svgs";
import { LocationIcon } from "assets/svgs/LocationIcon";
import { generateAddressLink } from "utils/helpers";

import * as S from "./styled";

export const PropertyLocation = ({ property }) => {
  return (
    <S.LocationContainer>
      <S.LocationAddress>
        <S.LocationTitleSection>
          <S.LocationIcon>
            <LocationIcon />
          </S.LocationIcon>
          <S.LocationTitle>Location</S.LocationTitle>
        </S.LocationTitleSection>
        <S.AddressLink
          className="text-green-700 underline"
          rel="noreferrer"
          target="_blank"
          to={generateAddressLink(property)}
        >
          See your property on CAD website
          <Icons.ArrowRightIcon />
        </S.AddressLink>
      </S.LocationAddress>
      <S.LocationSection>
        <S.LocationInfoWrapper>
          <S.Label>Address:</S.Label>
          <S.Values className="flex gap-2 items-center">{property?.street_address}</S.Values>
        </S.LocationInfoWrapper>
        <S.LocationInfoWrapper>
          <S.Label>Neighborhood:</S.Label>
          <S.Values>{property?.neighborhood_code ? property?.neighborhood_code : " "}</S.Values>
        </S.LocationInfoWrapper>
      </S.LocationSection>
    </S.LocationContainer>
  );
};
