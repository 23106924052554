import { useState } from "react";
import { Step, Stepper as MtStepper, Tooltip } from "@material-tailwind/react";
import { Icons } from "assets/svgs";
import clsx from "clsx";

import * as S from "./styled";

const Stepper = ({
  step,
  maxStep = 3,
  isShowDescription = false,
  descriptions = [],
  hoverDescriptions = [],
  isHoverEnabled = false,
  width = "100%",
  wrapperClassName, 
}) => {
  const [hoveredStep, setHoveredStep] = useState(null);

  const activeStepArray = Array.from({ length: maxStep }, (_, i) => ({
    isChecked: step > i && <Icons.CheckIcon />,
  }));

  return (
    <S.StepperWrapper
      className={clsx(wrapperClassName, {
        showDescription: isShowDescription,
      })}
      width={width}
    >
      <MtStepper activeLineClassName="bg-primary-100" activeStep={step} className="!w-full" lineClassName="bg-white">
        {activeStepArray.map((data, index) => (
          <Step
            key={index}
            activeClassName="!bg-white !ring-[6px] !ring-green-600"
            className="h-3 w-3 !bg-gray-400 cursor-pointer relative"
            completedClassName="!bg-primary-100 w-6 h-6"
            onMouseEnter={() => setHoveredStep(index)}
            onMouseLeave={() => setHoveredStep(null)}
          >
            {data.isChecked}
            {isHoverEnabled && (
              <Tooltip
                className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/30"
                content={
                  <S.TooltipContent>
                    <div
                      className="font-normal opacity-80 text-blue-gray-800"
                      dangerouslySetInnerHTML={{ __html: hoverDescriptions[index] }}
                    />
                  </S.TooltipContent>
                }
                open={hoveredStep === index}
                placement="bottom"
              >
                <div>
                  <S.Div />
                </div>
              </Tooltip>
            )}
            {isShowDescription && (
              <S.Description
                className={`${index === 0 ? "first-child" : ""} ${
                  index === descriptions.length - 1 ? "last-child" : ""
                }`}
              >
                {descriptions[index]}
              </S.Description>
            )}
          </Step>
        ))}
      </MtStepper>
    </S.StepperWrapper>
  );
};

export default Stepper;
