import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Can } from "@casl/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { COLORS } from "assets/styles/colors";
import { Icons } from "assets/svgs";
import { Loader, PrimaryButton, Table } from "common";
import { useDebounce } from "hooks";
import { usePermissions } from "hooks/usePermissions";
import { adminRoutes } from "router/routes";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { singleClientEvidenceSelector } from "store/admin-slices/single-client-evidence-slice/selectors";
import { singleClientEvidenceActions } from "store/admin-slices/single-client-evidence-slice/slice";
import { submitEvidenceSelectors } from "store/submit-evidence-slice/selectors";
import { isLoading, LOADING_STATUSES, PERMISSIONS_LIST } from "utils/constants";
import { singleDocumentsSchema } from "validations/singleDocumentsSchema";

import { Template } from "components/Admin/TableRowCustomTemplate";
import { Modal } from "components/Modal";

import * as S from "./styled";
import { TrackerTable } from "./TrackerTable";
import { UploadClientEvidenceFile } from "./UploadClientEvidenceFile";

export const ClientEvidenceSinglePage = () => {
  const {
    singleClientEvidences,
    getSingleClientEvidencesStatus,
    mergeEvidenceFilesStatus,
    postChangeFileNameStatus,
    postChangeFileTypeStatus,
    postAddFileStatus,
    paginationData,
    isDeleteModalOpen,
    deleteEvidenceFileStatus,
  } = useSelector(singleClientEvidenceSelector);
  const { postEvidenceResponse } = useSelector(submitEvidenceSelectors);

  const { userInfo } = useSelector(adminSignInSelectors);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(singleDocumentsSchema),
  });

  const { document } = watch();

  const [sortData, setSortData] = useState({
    field: "created_at",
    direction: -1,
  });

  const [selectedId, setSelectedId] = useState(null);
  const [selectedTypeId, setSelectedTypeId] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const [startDate, setStartDate] = useState("");
  const debouncedStartDate = useDebounce(startDate, 500);

  const [endDate, setEndDate] = useState("");
  const debouncedEndDate = useDebounce(endDate, 500);

  const approvedSelectItems = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ability = usePermissions(userInfo.permissions);

  const handleApprovedChange = (id, selectedLabel) => {
    const selectedValue = approvedSelectItems.find((item) => item.label === selectedLabel)?.value;
    dispatch(singleClientEvidenceActions.postFileStatus({ id, status: selectedValue }));
  };

  const handleMergeFile = () => {
    dispatch(singleClientEvidenceActions.mergeEvidenceFiles({ id }));
  };

  const handleDeleteEvidenceFile = () => {
    dispatch(singleClientEvidenceActions.deleteEvidenceFile(fileId));
  };

  const goBack = () => {
    navigate(`${adminRoutes.prefix}/${adminRoutes.clientEvidence}`);
  };

  const getTrackerList = (props) => {
    const params = {
      page: props?.page ?? paginationData.page,
      limit: props?.rows ?? paginationData.rows,
      order_column: sortData.field,
      start_date: debouncedStartDate,
      end_date: debouncedEndDate,
      direction: sortData.direction === 1 ? "asc" : "desc",
    };
    dispatch(singleClientEvidenceActions.getTracker({ id, params }));
  };

  const onPageChange = ({ first, rows, page }) => {
    dispatch(
      singleClientEvidenceActions.setPaginationData({
        first,
        rows,
        page: page + 1,
      })
    );

    getTrackerList({ page: page + 1, rows });
  };

  const handleEditFileName = (id, name) => {
    setSelectedId(id);
    setValue("document", name);
  };

  const handleSaveFileName = () => {
    if (selectedId && document) {
      dispatch(singleClientEvidenceActions.postChangeFileName({ id: selectedId, name: document }));
    }
  };

  const handleFileTypeChange = (id, type) => {
    setSelectedTypeId(id);
    dispatch(singleClientEvidenceActions.postChangeFileType({ id, evidence_type: type }));
  };

  const handleOpenModal = () => {
    dispatch(singleClientEvidenceActions.setIsModalOpen(true));
  };

  const archiveClientEvidenceFiles = () => {
    const body = new FormData();

    if (!selectedItems.length) return;

    selectedItems.forEach((item) => {
      body.append("file_ids[]", item.id);
    });

    dispatch(singleClientEvidenceActions.archiveClientEvidenceFile({ id, body }))
      .unwrap()
      .then(() => {
        dispatch(singleClientEvidenceActions.getSingleClientEvidences(id));
        setSelectedItems([]);
      });
  };

  const tableData = useMemo(
    () => [
      { headerClassName: "column-header", selectionMode: "multiple" },
      {
        header: (
          <Can passThrough ability={ability} I={PERMISSIONS_LIST.editFileName}>
            {(allowed) => (allowed ? "Actions" : null)}
          </Can>
        ),
        body: ({ id, name }) => (
          <Can passThrough ability={ability} I={PERMISSIONS_LIST.editFileName}>
            {(allowed) => (
              <>
                {allowed && id === selectedId && isLoading(postChangeFileNameStatus) ? (
                  <Loader />
                ) : allowed && id === selectedId ? (
                  <Icons.CheckIcon color={COLORS.green} onClick={handleSubmit(handleSaveFileName)} />
                ) : allowed ? (
                  <Icons.EditIcon onClick={() => handleEditFileName(id, name)} />
                ) : null}
              </>
            )}
          </Can>
        ),
      },
      {
        header: "File name",
        body: (item) => Template.EditClientEvidenceFiles(item, selectedId, register, errors),
      },
      {
        header: "Type",
        body: (item) => (
          <Can passThrough ability={ability} I={PERMISSIONS_LIST.editEvidenceType}>
            {(allowed) =>
              allowed ? (
                Template.FileTypeSelect(item, selectedTypeId, handleFileTypeChange, postChangeFileTypeStatus)
              ) : (
                <S.EditEvidenceType>{item.type ? item.type : ""}</S.EditEvidenceType>
              )
            }
          </Can>
        ),
      },
      {
        header: "Approved/Not Approved",
        body: (item) => (
          <Can passThrough ability={ability} I={PERMISSIONS_LIST.approvedNotApproved}>
            {(allowed) =>
              allowed ? (
                Template.ApprovedOrNotFiles(item, approvedSelectItems, handleApprovedChange)
              ) : (
                <S.Approved className={`select ${item.status ? "approved" : "not-approved"}`}>
                  {item.status !== null
                    ? approvedSelectItems.find((selectItem) => selectItem.value === item.status).label
                    : ""}
                </S.Approved>
              )
            }
          </Can>
        ),
      },
      {
        header: "Remove",
        body: (item) => (
          <Can passThrough ability={ability} I={PERMISSIONS_LIST.editFileName}>
            {(allowed) => (
              <>
                {allowed && (
                  <S.RemoveContainer>
                    <Icons.TrashIcon
                      onClick={() => {
                        setFileId(item.id);
                        dispatch(singleClientEvidenceActions.setIsDeleteModalOpen(true));
                      }}
                    />
                  </S.RemoveContainer>
                )}
              </>
            )}
          </Can>
        ),
      },
    ],
    [setSelectedId, document, selectedId, postChangeFileNameStatus, postChangeFileTypeStatus]
  );

  useEffect(() => {
    if (!window.location.href.includes("create")) {
      getTrackerList();
    }
  }, [debouncedStartDate, debouncedEndDate, sortData]);

  useEffect(() => {
    if (!window.location.href.includes("create")) {
      dispatch(singleClientEvidenceActions.getSingleClientEvidences(id));
    }
  }, [id, deleteEvidenceFileStatus]);

  useEffect(() => {
    if (postAddFileStatus === LOADING_STATUSES.succeeded) {
      dispatch(singleClientEvidenceActions.getSingleClientEvidences(id));
    }
  }, [postAddFileStatus, deleteEvidenceFileStatus]);

  useEffect(() => {
    if (postChangeFileNameStatus === LOADING_STATUSES.succeeded) {
      setSelectedId(null);
    }
  }, [postChangeFileNameStatus]);

  useEffect(() => {
    if (postEvidenceResponse?.id) {
      navigate(`${adminRoutes.prefix}/${adminRoutes.clientEvidence}/${postEvidenceResponse?.id}`);
    }
  }, [postEvidenceResponse?.id]);

  useEffect(() => {
    if (deleteEvidenceFileStatus === LOADING_STATUSES.succeeded) {
      dispatch(singleClientEvidenceActions.setIsDeleteModalOpen(false));
    }
  }, [deleteEvidenceFileStatus]);

  useEffect(() => {
    return () => dispatch(singleClientEvidenceActions.resetAllData());
  }, []);

  useEffect(() => {
    if (postEvidenceResponse?.id) {
      navigate(`${adminRoutes.prefix}/${adminRoutes.clientEvidence}/${postEvidenceResponse?.id}`);
    }
  }, [postEvidenceResponse?.id]);

  return (
    <S.Container>
      <PrimaryButton className="back-button" eventHandler={goBack} label="Back" />
      <S.Content>
        <Table
          paginator
          dataKey="id"
          loading={isLoading(getSingleClientEvidencesStatus)}
          rows={10}
          selection={selectedItems}
          tableData={tableData}
          value={singleClientEvidences.files}
          onSelectionChange={(e) => {
            if (e.originalEvent.target.localName !== "li") {
              setSelectedItems(e.value);
            }
          }}
        />
        <S.FooterContainer>
          <PrimaryButton
            disabled={!selectedItems.length}
            eventHandler={archiveClientEvidenceFiles}
            label="Don't Use Evidence"
            mode="gray"
          />
          <Can ability={ability} I={PERMISSIONS_LIST.mergeButton}>
            <PrimaryButton
              eventHandler={handleMergeFile}
              label="Merge"
              loading={isLoading(mergeEvidenceFilesStatus)}
              mode={singleClientEvidences.is_merged === 1 ? "green" : "gray"}
            />
          </Can>
          <Can ability={ability} I={PERMISSIONS_LIST.uploadEvidence}>
            <PrimaryButton eventHandler={handleOpenModal} label="Upload PDF" mode="gray" />
          </Can>
        </S.FooterContainer>
      </S.Content>
      <UploadClientEvidenceFile />
      <Modal
        cancelButtonText="no"
        className="delete-modal"
        description="Are you sure ?"
        isModalOpen={isDeleteModalOpen}
        successButtonText="yes"
        successLoadingStatus={isLoading(deleteEvidenceFileStatus)}
        onCancel={() => dispatch(singleClientEvidenceActions.setIsDeleteModalOpen(false))}
        onSuccess={handleDeleteEvidenceFile}
      />
      <TrackerTable
        debouncedEndDate={debouncedEndDate}
        debouncedStartDate={debouncedStartDate}
        setEndDate={setEndDate}
        setSortData={setSortData}
        setStartDate={setStartDate}
        sortData={sortData}
        onPageChange={onPageChange}
      />
    </S.Container>
  );
};
