import { Icons } from "assets/svgs";
import { HomeGreyIcon } from "assets/svgs/HomeGreyIcon";
import { v4 as uuid } from "uuid";

import * as S from "./styled";

export const Properties = ({ isDelete = false, properties, toggleAcceptModal }) => (
  <>
    {properties.map((property) => (
      <S.PropertyBox key={uuid()}>
        <HomeGreyIcon />
        <S.PropertyInfoBox>
          {property.street_address || property.address} |{" "}
          {property.county?.toLowerCase() === "fortbend" ? "Fort Bend" : property.county}
        </S.PropertyInfoBox>
        {!!isDelete && (
          <S.RemoveIconBlock>
            <Icons.RemoveIcon onClick={() => toggleAcceptModal(property.index, property.id, property.owner_id)} />
          </S.RemoveIconBlock>
        )}
      </S.PropertyBox>
    ))}
  </>
);
