import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME, TOAST_TYPES } from "utils/constants";
import { toastify } from "utils/helpers";

import { clientApi } from "./services";

export const postCheckEmail = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/postCheckEmail`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await clientApi.checkEmail(body);
      response.data.data = { ...response.data.data, ...body };
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postCheckCode = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/postCheckCode`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await clientApi.checkCode(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMe = createAsyncThunk(`${SLICE_NAME.clientSlice}/getMe`, async (_, { rejectWithValue }) => {
  try {
    const response = await clientApi.getMe();
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getAppeals = createAsyncThunk(`${SLICE_NAME.clientSlice}/getAppeals`, async (_, { rejectWithValue }) => {
  try {
    const response = await clientApi.appeals();
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getProperties = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/getProperties`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await clientApi.properties();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getInvoices = createAsyncThunk(`${SLICE_NAME.clientSlice}/getInvoices`, async (_, { rejectWithValue }) => {
  try {
    const response = await clientApi.invoices();
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSingleProperty = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/getSingleProperty`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await clientApi.getSingleProperty(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getClientEvidences = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/getClientEvidences`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await clientApi.getClientEvidences(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNotifications = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/getNotifications`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await clientApi.getNotifications();
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const markNotificationAsRead = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/markNotificationAsRead`,
  async (body, { rejectWithValue }) => {
    try {
      await clientApi.markAsRead(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/deleteNotification`,
  async (id, { rejectWithValue }) => {
    try {
      await clientApi.deleteNotification(id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReferrals = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/getReferrals`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await clientApi.getOwnerPortalReferrals(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReferredBy = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/getReferredBy`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await clientApi.getOwnerPortalReferredBy(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOwnerReferralCode = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/getOwnerReferralCode`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await clientApi.getOwnerReferralCode();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateAccountInfo = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/updateAccountInfo`,
  async ({ params, id }, { rejectWithValue }) => {
    try {
      const response = await clientApi.updateAccountInfo({ params, id });
      return response.data;
    } catch (err) {
      toastify(TOAST_TYPES.error, err.data.message);
      return rejectWithValue(err);
    }
  }
);
