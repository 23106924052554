import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";
import { removeStorage, setStorage } from "utils/helpers";

import {
  deleteNotification,
  getAppeals,
  getClientEvidences,
  getInvoices,
  getMe,
  getNotifications,
  getOwnerReferralCode,
  getProperties,
  getReferrals,
  getReferredBy,
  getSingleProperty,
  markNotificationAsRead,
  postCheckCode,
  postCheckEmail,
  updateAccountInfo,
} from "./actions";

const initialState = {
  signinStep: 0,
  ownerPortalReferralCode: null,
  email: null,
  properties: [],
  property: null,
  clientEvidences: null,
  invoices: [],
  account: null,
  notifications: [],
  appeals: [],
  referrals: null,
  referredBy: null,
  emailUpdatedOrNot: false,
  checkEmailErrorMessage: null,
  checkCodeErrorMessage: null,
  ownerReferralCode: null,
  postCheckEmailStatus: LOADING_STATUSES.idle,
  postCheckCodeStatus: LOADING_STATUSES.idle,
  getMeStatus: LOADING_STATUSES.idle,
  getAppealsStatus: LOADING_STATUSES.idle,
  getPropertiesStatus: LOADING_STATUSES.idle,
  getInvoicesStatus: LOADING_STATUSES.idle,
  getPropertyStatus: LOADING_STATUSES.idle,
  getClientEvidencesStatus: LOADING_STATUSES.idle,
  getNotificationsStatus: LOADING_STATUSES.idle,
  markNotificationAsReadStatus: LOADING_STATUSES.idle,
  getReferredByStatus: LOADING_STATUSES.idle,
  getReferralsStatus: LOADING_STATUSES.idle,
  postReferralCodeStatus: LOADING_STATUSES.idle,
  getOwnerReferralCodeStatus: LOADING_STATUSES.idle,
  updateAccountInfoStatus: LOADING_STATUSES.idle,
  deleteNotificationStatus: LOADING_STATUSES.idle,
};

export const clientSlice = createSlice({
  name: SLICE_NAME.clientSlice,
  initialState,
  reducers: {
    setEmailUpdatedOrNot: (state, { payload }) => {
      state.emailUpdatedOrNot = payload;
    },
    setUpdateAccountInfoStatus: (state, { payload }) => {
      state.updateAccountInfoStatus = payload;
    },
    logout: () => removeStorage("owner_token"),
    resetAllData: () => initialState,
  },
  extraReducers: ({ addCase }) => {
    //CHECK EMAIL
    addCase(postCheckEmail.pending, (state) => {
      state.postCheckEmailStatus = LOADING_STATUSES.pending;
    });
    addCase(postCheckEmail.fulfilled, (state, { payload }) => {
      state.signinStep = 1;
      state.email = payload.data.email;
      state.postCheckEmailStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postCheckEmail.rejected, (state) => {
      state.checkEmailErrorMessage = "Unable to find client profile with this E-mail";
      state.postCheckEmailStatus = LOADING_STATUSES.failed;
    });

    // CHECK CODE
    addCase(postCheckCode.pending, (state) => {
      state.postCheckCodeStatus = LOADING_STATUSES.pending;
    });
    addCase(postCheckCode.fulfilled, (state, { payload }) => {
      state.postCheckCodeStatus = LOADING_STATUSES.succeeded;
      setStorage("owner_token", payload.user_token);
    });
    addCase(postCheckCode.rejected, (state) => {
      state.checkCodeErrorMessage = "Invalid verification code";
      state.postCheckCodeStatus = LOADING_STATUSES.failed;
    });

    // getMe
    addCase(getMe.pending, (state) => {
      state.getMeStatus = LOADING_STATUSES.pending;
    });
    addCase(getMe.fulfilled, (state, { payload }) => {
      state.account = payload.data;
      state.getMeStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getMe.rejected, (state) => {
      state.getMeStatus = LOADING_STATUSES.failed;
    });

    //appeals
    addCase(getAppeals.pending, (state) => {
      state.getAppealsStatus = LOADING_STATUSES.pending;
    });
    addCase(getAppeals.fulfilled, (state, { payload }) => {
      state.appeals = payload.data;
      state.getAppealsStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getAppeals.rejected, (state) => {
      state.getAppealsStatus = LOADING_STATUSES.failed;
    });

    //properties;
    addCase(getProperties.pending, (state) => {
      state.getPropertiesStatus = LOADING_STATUSES.pending;
    });
    addCase(getProperties.fulfilled, (state, { payload }) => {
      state.properties = payload.data;
      state.getPropertiesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getProperties.rejected, (state) => {
      state.getPropertiesStatus = LOADING_STATUSES.failed;
    });

    // invoices;
    addCase(getInvoices.pending, (state) => {
      state.getInvoicesStatus = LOADING_STATUSES.pending;
    });
    addCase(getInvoices.fulfilled, (state, { payload }) => {
      state.invoices = payload;
      state.getInvoicesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getInvoices.rejected, (state) => {
      state.getInvoicesStatus = LOADING_STATUSES.failed;
    });

    // GET SINGLE PROPERTY;
    addCase(getSingleProperty.pending, (state) => {
      state.getPropertyStatus = LOADING_STATUSES.pending;
    });
    addCase(getSingleProperty.fulfilled, (state, { payload }) => {
      state.property = payload.data;
      state.getPropertyStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getSingleProperty.rejected, (state) => {
      state.getPropertyStatus = LOADING_STATUSES.failed;
    });

    // GET CLIENT EVIDENCES;
    addCase(getClientEvidences.pending, (state) => {
      state.getClientEvidenciesStatus = LOADING_STATUSES.pending;
    });
    addCase(getClientEvidences.fulfilled, (state, { payload }) => {
      state.clientEvidences = payload.data;
      state.getClientEvidenciesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getClientEvidences.rejected, (state) => {
      state.getClientEvidenciesStatus = LOADING_STATUSES.failed;
    });

    // GET NOTIFICATIONS;
    addCase(getNotifications.pending, (state) => {
      state.getNotificationsStatus = LOADING_STATUSES.pending;
    });
    addCase(getNotifications.fulfilled, (state, { payload }) => {
      state.notifications = payload;
      state.getNotificationsStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getNotifications.rejected, (state) => {
      state.getNotificationsStatus = LOADING_STATUSES.failed;
    });

    // MARK AS READ
    addCase(markNotificationAsRead.pending, (state) => {
      state.markNotificationAsReadStatus = LOADING_STATUSES.pending;
    });
    addCase(markNotificationAsRead.fulfilled, (state) => {
      state.markNotificationAsReadStatus = LOADING_STATUSES.succeeded;
    });
    addCase(markNotificationAsRead.rejected, (state) => {
      state.markNotificationAsReadStatus = LOADING_STATUSES.failed;
    });

    // GET REFERRALS
    addCase(getReferrals.pending, (state) => {
      state.getReferralsStatus = LOADING_STATUSES.pending;
    });
    addCase(getReferrals.fulfilled, (state, { payload }) => {
      state.getReferralsStatus = LOADING_STATUSES.succeeded;
      state.referrals = payload.data;
    });
    addCase(getReferrals.rejected, (state) => {
      state.getReferralsStatus = LOADING_STATUSES.failed;
    });

    // get referred by
    addCase(getReferredBy.pending, (state) => {
      state.getReferredByStatus = LOADING_STATUSES.pending;
    });
    addCase(getReferredBy.fulfilled, (state, { payload }) => {
      state.referredBy = payload?.data;
      state.getReferredByStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getReferredBy.rejected, (state) => {
      state.getReferredByStatus = LOADING_STATUSES.failed;
    });

    // GET OWNER REFERRAL CODE
    addCase(getOwnerReferralCode.pending, (state) => {
      state.getOwnerReferralCodeStatus = LOADING_STATUSES.pending;
    });
    addCase(getOwnerReferralCode.fulfilled, (state, { payload }) => {
      state.ownerReferralCode = payload?.referral_code;
      state.getOwnerReferralCodeStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getOwnerReferralCode.rejected, (state) => {
      state.getOwnerReferralCodeStatus = LOADING_STATUSES.failed;
    });

    // GET OWNER REFERRAL CODE
    addCase(updateAccountInfo.pending, (state) => {
      state.updateAccountInfoStatus = LOADING_STATUSES.pending;
    });
    addCase(updateAccountInfo.fulfilled, (state) => {
      state.updateAccountInfoStatus = LOADING_STATUSES.succeeded;
    });
    addCase(updateAccountInfo.rejected, (state) => {
      state.updateAccountInfoStatus = LOADING_STATUSES.failed;
    });

    // DELETE NOTIFICATION
    addCase(deleteNotification.pending, (state) => {
      state.deleteNotificationStatus = LOADING_STATUSES.pending;
    });
    addCase(deleteNotification.fulfilled, (state) => {
      state.deleteNotificationStatus = LOADING_STATUSES.succeeded;
    });
    addCase(deleteNotification.rejected, (state) => {
      state.deleteNotificationStatus = LOADING_STATUSES.failed;
    });
  },
});

export const { logout, resetAllData, setEmailUpdatedOrNot, setUpdateAccountInfoStatus } = clientSlice.actions;

export const clientReducer = clientSlice.reducer;
