import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

export const ViewPdfModalContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.overlay_300};
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ViewPdfModalContent = styled.div`
  padding: 20px;
  border-radius: 4px;
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 36px;

  .pdf-viewer {
    min-width: 90vw;
    min-height: 68vh;

    @media screen and (${BREAKPOINTS.md}) {
      min-height: 80vh;
    }
  }
`;