import { createSlice } from "@reduxjs/toolkit";
import { exportSmsEligibilityData, getSmsEligibilityData } from "store/admin-slices/sms-eligibility-slice/action";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";

const initialState = {
  smsEligibilityData: null,
  exportSmsEligibilityHref: null,
  per_page: 50,
  last_page: 0,
  paginationData: {
    first: 10,
    rows: 50,
    page: 1,
  },
  allowScroll: false,
  getSmsEligibilityStatus: LOADING_STATUSES.idle,
  exportSmsEligibilityStatus: LOADING_STATUSES.idle,
};

export const smsEligibilitySlice = createSlice({
  name: SLICE_NAME.smsEligibilitySlice,
  initialState,
  reducers: {
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    setAllowScroll: (state, { payload }) => {
      state.allowScroll = payload;
    },
    resetAllData: (state) => ({
      ...initialState,
      paginationData: state.paginationData,
    }),
  },
  extraReducers: ({ addCase }) => {
    // get sms eligibility
    addCase(getSmsEligibilityData.pending, (state) => {
      state.getSmsEligibilityStatus = LOADING_STATUSES.pending;
    });
    addCase(getSmsEligibilityData.fulfilled, (state, { payload }) => {
      state.smsEligibilityData = payload.data;
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
      state.getSmsEligibilityStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getSmsEligibilityData.rejected, (state) => {
      state.getSmsEligibilityStatus = LOADING_STATUSES.failed;
    });

    // export sms eligibility
    addCase(exportSmsEligibilityData.pending, (state) => {
      state.exportSmsEligibilityStatus = LOADING_STATUSES.pending;
    });
    addCase(exportSmsEligibilityData.fulfilled, (state, { payload }) => {
      state.exportSmsEligibilityHref = payload;
      state.exportSmsEligibilityStatus = LOADING_STATUSES.succeeded;
    });
    addCase(exportSmsEligibilityData.rejected, (state) => {
      state.exportSmsEligibilityStatus = LOADING_STATUSES.failed;
    });
  },
});

export const smsEligibilityReducer = smsEligibilitySlice.reducer;

export const smsEligibilityActions = {
  ...smsEligibilitySlice.actions,
  getSmsEligibilityData,
  exportSmsEligibilityData,
};
