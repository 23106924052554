import axios from "axios";
import { API } from "configs/api";

export const singleEvidenceSliceApi = {
  getColumnsInfo: (params) =>
    axios.get(`${API.admin + API.evidences + API.getColumnOrder}`, {
      params,
      token: true,
    }),
  saveColumnsInfo: (body) =>
    axios.post(`${API.admin + API.evidences + API.saveColumnOrder}`, body, {
      token: true,
    }),
  setEvidenceStatus: ({ property_id, ...body }) =>
    axios.post(`${API.comps + API.changeCompsStatus}/${property_id}`, body, {
      token: true,
    }),
  getEvidenceStatus: ({ property_id }) =>
    axios.get(`${API.comps + API.evidenceStatus}/${property_id}`, {
      token: true,
    }),
  getEvidencesEquities: ({ property_id, ...params }) =>
    axios.get(`${API.comps + API.equities}/${property_id}`, {
      token: true,
      params,
    }),
  getEvidencesSales: ({ property_id, ...params }) =>
    axios.get(`${API.comps}${API.sales}/${property_id}`, {
      token: true,
      params,
    }),
  getSaveComps: ({ propertyId, ...params }) =>
    axios.get(`${API.comps + API.getSaveComps}/${propertyId}`, {
      token: true,
      params,
    }),
  getLeftBarValues: ({ propertyId, ...body }) =>
    axios.post(`${API.comps + API.leftBarValues}/${propertyId}`, body, {
      token: true,
    }),
  saveComps: ({ propertyId, ...body }) =>
    axios.post(`${API.comps + API.saveComps}/${propertyId}`, body, {
      token: true,
    }),
  exportCsv: ({ propertyId, ...params }) =>
    axios.get(`${API.comps + API.exportCSV}/${propertyId}`, {
      token: true,
      responseType: "blob",
      params,
    }),
  getCompsFilters: (params) =>
    axios.get(`${API.comps}${API.getFilters}`, {
      token: true,
      params,
    }),
  getSubjectProperty: ({ propertyId, ...params }) =>
    axios.get(`${API.comps + API.subject}/${propertyId}`, {
      token: true,
      params,
    }),
  saveFilters: (body) =>
    axios.post(`${API.comps + API.filters + API.save}`, body, {
      token: true,
    }),
  getFilters: ({ property_id, ...params }) =>
    axios.get(`${API.comps + API.filters}/${property_id}`, {
      token: true,
      params,
    }),
};
