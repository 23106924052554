import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IMAGES } from "assets/images";
import { Icons } from "assets/svgs";
import { clientRoutes } from "router/routes";
import { clientSelectors } from "store/client-slice/selectors";
import { logout, resetAllData } from "store/client-slice/slice";

import { NotificationSidebar } from "components/NotificationSidebar";

import * as S from "./styled";

export const NavigationBar = () => {
  const { notifications } = useSelector(clientSelectors);

  const [open, setOpen] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const mainOptions = [
    {
      label: "Protest Status",
      href: clientRoutes.prefix + clientRoutes.appeals,
      icon: <Icons.ClarityProcessIcon />,
    },
    {
      label: "Properties",
      href: clientRoutes.prefix + clientRoutes.properties,
      icon: <Icons.LocationIcon />,
    },
    {
      label: "Invoices",
      href: clientRoutes.prefix + clientRoutes.invoices,
      icon: <Icons.InvoicesIcon />,
    },
    {
      label: "Referrals",
      href: clientRoutes.prefix + clientRoutes.referrals,
      icon: <Icons.PeopleIcon />,
    },
    {
      label: "Account Settings",
      href: clientRoutes.prefix + clientRoutes.account,
      icon: <Icons.HelpIcon />,
    },
  ];

  const bottomOptions = [
    {
      key: "logout",
      label: "Log Out",
      href: "null",
      icon: <Icons.LogoutIcon />,
      logout: true,
    },
  ];

  const handleNotificationClick = () => {
    setShowSidebar(true);
  };

  const handleLogoutClick = () => {
    dispatch(resetAllData());
    dispatch(logout());
  };

  const handleCloseSidebar = () => {
    setShowSidebar(false);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  return (
    <>
      <S.Container>
        <S.NavContent>
          <S.Box>
            <S.NavLink to="https://www.propertytaxprotest.com/">
              <S.AppLogo alt="PTP Logo" src={IMAGES.PTPLogo} />
            </S.NavLink>
            <S.NotificationIcon onClick={handleNotificationClick}>
              <Icons.NotificationIcon />
              {notifications.filter((notification) => !notification.read_at).length > 0 && (
                <S.NotificationCount>
                  {notifications.filter((notification) => !notification.read_at).length}
                </S.NotificationCount>
              )}
            </S.NotificationIcon>
          </S.Box>

          <S.BurgerMenuIcon onClick={() => setOpen(!open)}>
            {open ? (
              <Icons.CloseIcon />
            ) : (
              <>
                <Icons.BurgerIcon />
              </>
            )}
          </S.BurgerMenuIcon>
        </S.NavContent>
        <S.OptionsContent className={open ? "open" : ""}>
          <S.MainOptions>
            {mainOptions.map((item) => (
              <S.NavLinkWrapper key={item.label} className={pathname.includes(item.href) ? "active" : ""}>
                <S.NavLink to={item.href} onClick={handleCloseMenu}>
                  {item.icon} {item.label}
                </S.NavLink>
              </S.NavLinkWrapper>
            ))}
          </S.MainOptions>
          <S.BottomOptions>
            {bottomOptions.map((item) => (
              <S.NavLinkWrapper key={item.key} className={pathname.includes(item.href) ? "active" : ""}>
                {item.logout ? (
                  <S.NavLink className="logout" onClick={handleLogoutClick}>
                    {item.icon} {item.label}
                  </S.NavLink>
                ) : (
                  <S.Wrapper>
                    <S.NavLink to={item.href} onClick={handleCloseMenu}>
                      {item.icon} {item.label}
                    </S.NavLink>
                  </S.Wrapper>
                )}
              </S.NavLinkWrapper>
            ))}
          </S.BottomOptions>
        </S.OptionsContent>
      </S.Container>
      <NotificationSidebar showSidebar={showSidebar} onClose={handleCloseSidebar} />
    </>
  );
};
