import { forwardRef, useMemo } from "react";
import { Document, PDFViewer } from "@react-pdf/renderer";
import { PrimaryButton } from "common";
import { getImageFromTypedSignature } from "utils/helpers";

import { AoAPage, AoAPage2, SaPage } from "components/Documents";

import * as S from "./styled";

export const ViewPdfModal = forwardRef(({ signType, toggleModal, firstProp, properties, signature, ownerData }, typedSignRef) => {
  const transformedSignature = useMemo(async () => {
    return await getImageFromTypedSignature({ signature, signType, typedSignRef });
  }, [signature]);

  return <S.ViewPdfModalContainer onClick={toggleModal}>
    <S.ViewPdfModalContent onClick={(e) => e.stopPropagation()}>
      <PDFViewer className="pdf-viewer">
        <Document>
          <AoAPage firstProp={firstProp} owner={ownerData} properties={properties.slice(0, 4)} />
          {properties.length > 4 && (
            <AoAPage firstProp={firstProp} owner={ownerData} properties={properties.slice(4)} />
          )}
          <AoAPage2 firstProp={firstProp} owner={ownerData} signature={transformedSignature} signType={signType} />
          <SaPage owner={ownerData} signature={transformedSignature} signType={signType} />
        </Document>
      </PDFViewer>
      <PrimaryButton eventHandler={toggleModal} label="ok" />
    </S.ViewPdfModalContent>
  </S.ViewPdfModalContainer>;
});

ViewPdfModal.displayName = "ViewPdfModal";