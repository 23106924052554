import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "assets/svgs";
import { Loader } from "common";
import Stepper from "common/Stepper";
import { clientRoutes } from "router/routes";
import { getAppeals } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { isLoading } from "utils/constants";
import { transformToClassicDate } from "utils/helpers";

import * as S from "./styled";

export const AppealsPage = () => {
  const { getAppealsStatus, appeals } = useSelector(clientSelectors);

  const dispatch = useDispatch();

  const date = new Date().getFullYear();

  useEffect(() => {
    dispatch(getAppeals());
  }, [dispatch]);
  if (isLoading(getAppealsStatus)) {
    return (
      <S.LoaderBox>
        <Loader size={80} />
      </S.LoaderBox>
    );
  }

  return (
    <S.AppealsScrollBlock>
      <S.AppealsHeading>
        Your {date} <S.AppealsColoredSpan>Protest Status</S.AppealsColoredSpan>
      </S.AppealsHeading>
      {appeals?.map((item) => {
        return (
          <div key={item.id} className="appeal-accordion">
            <S.AppealsContent>
              <S.AppealIconWrapper>
                <Icons.PropertiesIcon height={28} width={28} />
              </S.AppealIconWrapper>
              <S.Link to={`${clientRoutes.prefix}${clientRoutes.properties}?id=${item.id}&should-scroll=true`}>
                <S.AppealsSubTitle>Address :</S.AppealsSubTitle> {item.street_address}
                &nbsp;<S.AppealsSubTitle>County :</S.AppealsSubTitle> {item.county}
              </S.Link>
            </S.AppealsContent>
            <S.AppealsContainer>
              <Stepper
                isHoverEnabled
                isShowDescription
                descriptions={[
                  "Property Signed Up",
                  "Forms sent to CAD",
                  "Protest Filed",
                  "Deadline to submit client evidence",
                  "Hearing Date Scheduled",
                  "Evidence being curated",
                  "Protest completed",
                ]}
                hoverDescriptions={[
                  "Your registration has been received and will be processed within 1 business day",
                  "Your forms have been reviewed for accuracy and sent to the appraisal district",
                  "Your protest has been formally filed with the appraisal district",
                  `If you have any additional evidence that you would like us to use in your protest, it must be submitted by ${transformToClassicDate(
                    item?.deadline_date
                  )}`,
                  `Hearing Date Scheduled: ${transformToClassicDate(item?.hearing_date) || "No date available"}`,
                  "We are in the process of finding the best comparables and market data to use as evidence for your protest",
                  `<a href="${
                    clientRoutes.prefix + clientRoutes.invoices
                  }" target="_blank" style="color:grey; text-decoration:underline;">Link to invoice tab</a>`,
                ]}
                maxStep={7}
                step={Number(item.completed_step) + 1 - 0.4}
                wrapperClassName={"xl:!w-full !w-[210%]"}
              />
            </S.AppealsContainer>
          </div>
        );
      })}
    </S.AppealsScrollBlock>
  );
};
