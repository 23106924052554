import React, { useState } from "react";
import { PrimaryAccordion } from "common";

import * as S from "./styled";

export const EvidenceSection = ({ evidences }) => {
  const sortedYears = Object.keys(evidences.files).sort((a, b) => b - a);
  const initialOpenAccordion = sortedYears.reduce((acc, year, index) => {
    acc[year] = index === 0;
    return acc;
  }, {});

  const [openAccordion, setOpenAccordion] = useState(initialOpenAccordion);

  const toggleAccordion = (key) => {
    setOpenAccordion((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const groupFilesByType = (files) => {
    const groupedFiles = {};
    files.forEach((file) => {
      const { type } = file;
      if (!groupedFiles[type]) {
        groupedFiles[type] = [];
      }
      groupedFiles[type].push(file);
    });
    return groupedFiles;
  };

  return (
    <S.EvidenceContainer>
      <S.EvidenceTitle>Submitted evidence</S.EvidenceTitle>
      <>
        {sortedYears.map((year, index) => (
          <PrimaryAccordion
            key={year}
            bodyClassName="pb-0"
            bodyContent={
              <S.AccordionBodyContent>
                {Object.entries(groupFilesByType(evidences.files[year])).map(([type, files]) => (
                  <S.FileWrapper key={type}>
                    <S.FileType>{type}</S.FileType>
                    <S.FilesContainer>
                      {files.map((file) => (
                        <S.FileLink key={file.id} href={file.path} rel="noopener noreferrer" target="_blank">
                          {file.name.length > 20 ? `${file.name.substring(0, 20)}...` : file.name}
                        </S.FileLink>
                      ))}
                    </S.FilesContainer>
                  </S.FileWrapper>
                ))}
              </S.AccordionBodyContent>
            }
            headerContent={year}
            isOpen={!!openAccordion[year]}
            onToggle={() => toggleAccordion(year)}
          />
        ))}
      </>
    </S.EvidenceContainer>
  );
};
