import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton, PrimaryInput, Select } from "common";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";
import { COUNTIES_FILTER, LOADING_STATUSES } from "utils/constants";
import { addPropertyManuallySchema } from "validations";

import * as S from "./styled";

export const AddManuallyProperty = () => {
  const { singleOwner, connectOwnerPropertyStatus, isShowErrorMessages, connectOwnerPropertyData } =
    useSelector(singleOwnerSelectors);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isAddManuallyLoading, setIsAddManuallyLoading] = useState(false);
  const [isAddManuallyClick, setIsAddManuallyClick] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(addPropertyManuallySchema),
  });

  const { manuallyCounty } = watch();

  const onSubmit = async (data) => {
    let selectedCounty = manuallyCounty;
    if (selectedCounty.toLowerCase() === "fort bend") {
      selectedCounty = "Fort Bend";
    }
    const body = {
      owner_id: singleOwner.id,
      unit_id: data.unit,
      is_manually: 1,
      street_name: data.streetName,
      address_number: data.addressNumber,
      city: data.city,
      county: selectedCounty,
      state: data.state,
      zip: data.zip,
    };
    dispatch(singleOwnerActions.connectOwnerProperty({ body, navigate, ownerId: singleOwner.id }));
  };

  useEffect(() => {
    if (connectOwnerPropertyStatus === LOADING_STATUSES.succeeded) {
      reset();
    }
    if (connectOwnerPropertyStatus === LOADING_STATUSES.pending) {
      setIsAddManuallyLoading(true);
    } else {
      setIsAddManuallyLoading(false);
    }
  }, [connectOwnerPropertyStatus]);

  return (
    <S.Container>
      <S.AddAccountForm onSubmit={handleSubmit(onSubmit)}>
        <S.Box>Add Property Manually</S.Box>
        <PrimaryInput
          error={errors.addressNumber}
          placeholder="Address Number"
          readOnly={false}
          register={register("addressNumber")}
        />
        <PrimaryInput
          error={errors.streetName}
          placeholder="Street Name"
          readOnly={false}
          register={register("streetName")}
        />
        <PrimaryInput error={errors.unit} placeholder="Unit # if needed" register={register("unit")} />
        <Controller
          control={control}
          name="manuallyCounty"
          render={({ field: { value, onChange } }) => (
            <Select
              error={errors.manuallyCounty}
              items={COUNTIES_FILTER.map((item) => item.description)}
              label="County"
              value={value}
              onChange={(value) => onChange({ target: { value } })}
            />
          )}
        />
        <PrimaryInput error={errors.city} placeholder="City" register={register("city")} />
        <PrimaryInput readOnly error={errors.state} placeholder="State" register={register("state")} value="TX" />
        <PrimaryInput error={errors.zip} placeholder="Zip" register={register("zip")} />
        <PrimaryButton
          eventHandler={() => {
            dispatch(singleOwnerActions.setIsShowErrorMessages(false));
            setIsAddManuallyClick(false);
          }}
          label="add property manually"
          loading={isAddManuallyClick && isAddManuallyLoading}
          mode="gray"
          type="submit"
        />
        {connectOwnerPropertyData && !isShowErrorMessages.payload ? (
          <S.Text className={connectOwnerPropertyData.error ? "error" : ""}>{connectOwnerPropertyData.message}</S.Text>
        ) : null}
      </S.AddAccountForm>
    </S.Container>
  );
};
