import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

export const EvidenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  button {
    padding-block: 8px !important;
    font-family: "Montserrat", sans-serif !important;
  }
`;

export const EvidenceTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`;

export const AccordionBodyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: "Montserrat", sans-serif !important;
`;

export const FilesContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, calc(100% / 4)));
`;

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FileType = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const FileLink = styled.a`
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  padding: 10px 20px;
  border: 1px solid ${COLORS.primary};
  border-radius: 4px;
  display: flex;
  justify-content: center;
`;
