import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const AdminLayoutContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  overflow-y: hidden;

  .pagination button {
    &.p-highlight,
    &:hover {
      background-color: ${COLORS.light_green} !important;
      border: none !important;
      color: ${COLORS.deepDark} !important;
    }
  }

  // primereact styles
  .p-highlight {
    background-color: ${COLORS.light_green} !important;
    color: ${COLORS.dark} !important;
    box-shadow: none !important;

    span svg {
    }
  }

  .p-checkbox-box.p-highlight {
    background-color: ${COLORS.green} !important;
    border-color: ${COLORS.green} !important;
  }

  .p-checkbox-box {
    &:hover {
      border-color: ${COLORS.green} !important;
    }
  }

  .p-checkbox-box.p-highlight.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px ${COLORS.light_green} !important;
    border-color: ${COLORS.green} !important;
  }

  .p-checkbox-focused {
    box-shadow: none !important;
  }

  .p-component-overlay {
    background-color: ${COLORS.light_green}95 !important;
  }

  .p-sortable-column-icon {
    color: ${COLORS.dark} !important;
  }
  .p-inputwrapper {
    &:hover {
      border-color: ${COLORS.green} !important;
    }
  }
  .p-inputwrapper.p-inputwrapper-focus {
    box-shadow: none !important;
    border-color: ${COLORS.green} !important;
  }
`;

export const AdminNavigationBar = styled.div`
  min-width: 102px;
  border-radius: 1px solid ${COLORS.dark};
  display: flex;
  height: auto;
  position: relative;
  z-index: 20;
  .navBarOpenClose {
    width: 100px;
    .active {
      background-color: ${COLORS.green};
    }
    &:hover {
      width: 400px;
      .hideTitle {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  .hideTitle {
    opacity: 0;
    pointer-events: none;
    min-width: max-content;
  }
`;

export const RightSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 102px);
`;

export const AdminHeader = styled.div`
  height: 70px;
  background-color: ${COLORS.green};
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    color: ${COLORS.white};
    font-size: 24px;
    margin-left: 50px;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-right: 50px;

  .notification-icon {
    cursor: pointer;
    margin-right: 6px;
    fill: ${COLORS.white};
    width: 24px;
    height: 24px;
  }
`;

export const UserName = styled.p`
  font-size: 12px;
  color: ${COLORS.white};
`;

export const AvatarIconContainer = styled.span`
  display: inline-block;
  height: 35px;
  width: 35px;
  background-color: #f0f0f0;
  border-radius: 50%;
  overflow: hidden;
`;

export const UserLayoutContent = styled.div`
  min-height: calc(100vh - 300px - 90px);
`;

export const Content = styled.div`
  width: 100%;
  padding: 16px;
  margin-top: 70px;
  display: flex;
  justify-content: center;

  @media screen and (${BREAKPOINTS.md}) {
    width: calc(100% - 312px);
    margin-left: 312px;
    min-height: 100vh;
    padding: 24px;
    margin-top: unset;
  }
`;

export const NotificationIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  gap: 8px;
  line-height: 24px;
  cursor: pointer;
  margin-left: 10px;

  svg {
    margin-right: 6px;
    fill: ${COLORS.white};
    width: 24px;
    height: 24px;
  }
`;

export const NotificationCount = styled.div`
  position: absolute;
  top: 0;
  left: 12px;
  background-color: ${COLORS.red};
  color: ${COLORS.white};
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;

  @media screen and (${BREAKPOINTS.md}) {
    top: 0;
    right: -5px;
  }
`;
