import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";

import {
  archiveOwner,
  archiveProperty,
  connectOwnerProperty,
  createDocument,
  deleteAccountLogs,
  deleteDocument,
  deleteProperty,
  deleteReferredBy,
  deleteReferredTo,
  getNotes,
  getReferrals,
  getReferredBy,
  getSingleOwner,
  postAccountLogs,
  postNote,
  postReferredBy,
  postReferredTo,
  sendAoA,
  updateAccountNumber,
  updateContactedStatus,
  updateDocument,
  updateEmail,
  updateReferral,
  updateStreetAddress,
  verifySingleProperty,
} from "./actions";

const initialState = {
  singleOwner: null,
  referrals: null,
  connectOwnerPropertyData: null,
  verifySinglePropertyData: null,
  documentSelectedId: null,
  referredBy: null,
  accountNumberErrorMessage: null,
  isModalOpen: false,
  isDeleteModalOpen: false,
  isAddModalOpen: false,
  isDeleteReferralModalOpen: false,
  isEditReferralModalOpen: false,
  isDeletePropertyModal: false,
  isDeleteDocumentModal: false,
  isDeleteFlagModalOpen: false,
  isShowErrorMessages: false,
  propertyId: 0,
  documentId: 0,
  flagId: 0,
  selectedPropertyId: null,
  postReferredByError: null,
  postReferredToError: null,
  updateReferralError: null,
  adminNotes: null,
  ownerNotes: null,
  getSingleOwnerStatus: LOADING_STATUSES.idle,
  postAccountsLogStatus: LOADING_STATUSES.idle,
  deleteAccountsLogStatus: LOADING_STATUSES.idle,
  deletePropertyStatus: LOADING_STATUSES.idle,
  connectOwnerPropertyStatus: LOADING_STATUSES.idle,
  verifySinglePropertyStatus: LOADING_STATUSES.idle,
  updateDocumentStatus: LOADING_STATUSES.idle,
  deleteDocumentStatus: LOADING_STATUSES.idle,
  createDocumentStatus: LOADING_STATUSES.idle,
  archiveOwnerStatus: LOADING_STATUSES.idle,
  getReferralsStatus: LOADING_STATUSES.idle,
  getReferredByStatus: LOADING_STATUSES.idle,
  archivePropertyStatus: LOADING_STATUSES.idle,
  postReferredByStatus: LOADING_STATUSES.idle,
  deleteReferredByStatus: LOADING_STATUSES.idle,
  postReferredToStatus: LOADING_STATUSES.idle,
  deleteReferredToStatus: LOADING_STATUSES.idle,
  updateAccountNumberStatus: LOADING_STATUSES.idle,
  updateReferralStatus: LOADING_STATUSES.idle,
  updateStreetAddressStatus: LOADING_STATUSES.idle,
  updateEmailStatus: LOADING_STATUSES.idle,
  getNotesStatus: LOADING_STATUSES.idle,
  postNoteStatus: LOADING_STATUSES.idle,
  changeContactedStatus: LOADING_STATUSES.idle,
};

export const singleOwnerSlice = createSlice({
  name: SLICE_NAME.singleOwnerSlice,
  initialState,
  reducers: {
    setIsModalOpen: (state, { payload }) => {
      state.isModalOpen = payload;
    },
    setIsAddModal: (state, { payload }) => {
      state.isAddModalOpen = payload;
    },
    setIsDeleteModalOpen: (state, { payload }) => {
      state.isDeleteModalOpen = payload;
    },
    setIsDeleteReferralModalOpen: (state, { payload }) => {
      state.isDeleteReferralModalOpen = payload;
    },
    setIsEditReferralModalOpen: (state, { payload }) => {
      state.isEditReferralModalOpen = payload;
    },
    setIsDeletePropertyModal: (state, { payload }) => {
      state.isDeletePropertyModal = payload;
    },
    setIsDeleteDocumentModal: (state, { payload }) => {
      state.isDeleteDocumentModal = payload;
    },
    setPropertyId: (state, { payload }) => {
      state.propertyId = payload;
    },
    setDocumentId: (state, { payload }) => {
      state.documentId = payload;
    },
    setFlagId: (state, { payload }) => {
      state.flagId = payload;
    },
    setIsDeleteFlagModalOpen: (state, { payload }) => {
      state.isDeleteFlagModalOpen = payload;
    },
    setDocumentSelectedId: (state, { payload }) => {
      state.documentSelectedId = payload.id;
    },
    resetReferredStatuses: (state) => {
      state.postReferredByStatus = LOADING_STATUSES.idle;
      state.postReferredByError = null;
      state.updateReferralStatus = LOADING_STATUSES.idle;
      state.updateReferralError = null;
      state.postReferredToStatus = LOADING_STATUSES.idle;
      state.postReferredToError = null;
    },
    resetCreateDocumentStatus: (state) => {
      state.createDocumentStatus = LOADING_STATUSES.idle;
    },
    setIsShowErrorMessages: (state, payload) => {
      state.isShowErrorMessages = payload;
    },
    resetAccountNumberStatus: (state) => {
      state.updateAccountNumberStatus = LOADING_STATUSES.idle;
    },
    resetStreetAddressStatus: (state) => {
      state.updateStreetAddressStatus = LOADING_STATUSES.idle;
    },
    setSelectedPropertyId: (state, { payload }) => {
      state.selectedPropertyId = payload;
    },
    resetAllData: () => initialState,
  },
  extraReducers: ({ addCase }) => {
    // get single owner
    addCase(getSingleOwner.pending, (state) => {
      state.getSingleOwnerStatus = LOADING_STATUSES.pending;
    });
    addCase(getSingleOwner.fulfilled, (state, { payload }) => {
      state.singleOwner = payload.data;
      state.getSingleOwnerStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getSingleOwner.rejected, (state) => {
      state.getSingleOwnerStatus = LOADING_STATUSES.failed;
    });

    // add accounts log
    addCase(postAccountLogs.pending, (state) => {
      state.postAccountsLogStatus = LOADING_STATUSES.pending;
    });
    addCase(postAccountLogs.fulfilled, (state, { payload }) => {
      state.postAccountsLogStatus = LOADING_STATUSES.succeeded;
      state.singleOwner.account_logs?.push(payload.data);
    });
    addCase(postAccountLogs.rejected, (state) => {
      state.postAccountsLogStatus = LOADING_STATUSES.failed;
    });

    // delete account log
    addCase(deleteAccountLogs.pending, (state) => {
      state.deleteAccountsLogStatus = LOADING_STATUSES.pending;
    });
    addCase(deleteAccountLogs.fulfilled, (state, { payload }) => {
      state.deleteAccountsLogStatus = LOADING_STATUSES.succeeded;
      state.singleOwner.account_logs = state.singleOwner.account_logs.filter((accLog) => accLog.id !== payload.id);
    });
    addCase(deleteAccountLogs.rejected, (state) => {
      state.deleteAccountsLogStatus = LOADING_STATUSES.failed;
    });

    // delete property
    addCase(deleteProperty.pending, (state) => {
      state.deletePropertyStatus = LOADING_STATUSES.pending;
    });
    addCase(deleteProperty.fulfilled, (state, { payload }) => {
      state.singleOwner.properties = state.singleOwner.properties.filter((item) => item.id !== payload.id);
      state.deletePropertyStatus = LOADING_STATUSES.succeeded;
    });
    addCase(deleteProperty.rejected, (state) => {
      state.deletePropertyStatus = LOADING_STATUSES.failed;
    });

    // connect property owner
    addCase(connectOwnerProperty.pending, (state) => {
      state.connectOwnerPropertyStatus = LOADING_STATUSES.pending;
    });
    addCase(connectOwnerProperty.fulfilled, (state, { payload }) => {
      const newProperties = state.singleOwner.properties.filter((prop) => prop.id !== payload.data.id);
      state.singleOwner.properties = [...newProperties, payload.data];

      state.connectOwnerPropertyData = {
        message: "Property added successfully!",
        error: false,
      };
      state.connectOwnerPropertyStatus = LOADING_STATUSES.succeeded;
    });
    addCase(connectOwnerProperty.rejected, (state, payload) => {
      state.connectOwnerPropertyData = {
        message: payload.payload.data.message,
        error: true,
      };
      state.connectOwnerPropertyStatus = LOADING_STATUSES.failed;
    });

    // verify single property
    addCase(verifySingleProperty.pending, (state) => {
      state.verifySinglePropertyStatus = LOADING_STATUSES.pending;
    });
    addCase(verifySingleProperty.fulfilled, (state) => {
      state.verifySinglePropertyData = {
        message: "Verified Successfully!",
        error: false,
      };
      state.verifySinglePropertyStatus = LOADING_STATUSES.succeeded;
    });
    addCase(verifySingleProperty.rejected, (state) => {
      state.verifySinglePropertyData = {
        message: "Hmm. Something else went wrong. Let Jason know.",
        error: true,
      };
      state.verifySinglePropertyStatus = LOADING_STATUSES.failed;
    });

    // update document
    addCase(updateDocument.pending, (state) => {
      state.updateDocumentStatus = LOADING_STATUSES.pending;
    });
    addCase(updateDocument.fulfilled, (state, { payload }) => {
      state.singleOwner.documents = state.singleOwner.documents.map((doc) => {
        if (doc.id === payload.data.id) {
          return payload.data;
        }
        return doc;
      });
      state.documentSelectedId = null;
      state.updateDocumentStatus = LOADING_STATUSES.succeeded;
    });
    addCase(updateDocument.rejected, (state) => {
      state.updateDocumentStatus = LOADING_STATUSES.failed;
    });

    // delete document
    addCase(deleteDocument.pending, (state) => {
      state.deleteDocumentStatus = LOADING_STATUSES.pending;
    });
    addCase(deleteDocument.fulfilled, (state, { payload }) => {
      state.singleOwner.documents = state.singleOwner.documents.filter((doc) => doc.id !== payload.id);
      state.documentSelectedId = null;
      state.deleteDocumentStatus = LOADING_STATUSES.succeeded;
    });
    addCase(deleteDocument.rejected, (state) => {
      state.deleteDocumentStatus = LOADING_STATUSES.failed;
    });

    // send aoa
    addCase(sendAoA.pending, (state) => {
      state.sendAoAStatus = LOADING_STATUSES.pending;
    });
    addCase(sendAoA.fulfilled, (state, { payload }) => {
      state.singleOwner.documents = state.singleOwner.documents.map((doc) => {
        if (doc.id === payload.data.id) {
          return payload.data;
        }
        return doc;
      });

      state.sendAoAStatus = LOADING_STATUSES.succeeded;
      state.documentSelectedId = null;
    });
    addCase(sendAoA.rejected, (state) => {
      state.sendAoAStatus = LOADING_STATUSES.failed;
    });

    // create document
    addCase(createDocument.pending, (state) => {
      state.createDocumentStatus = LOADING_STATUSES.pending;
    });
    addCase(createDocument.fulfilled, (state, { payload }) => {
      state.singleOwner.documents = [payload.data, ...state.singleOwner.documents];
      state.createDocumentStatus = LOADING_STATUSES.succeeded;
    });
    addCase(createDocument.rejected, (state) => {
      state.createDocumentStatus = LOADING_STATUSES.failed;
    });

    // update owner
    addCase(archiveOwner.pending, (state) => {
      state.archiveOwnerStatus = LOADING_STATUSES.pending;
    });
    addCase(archiveOwner.fulfilled, (state) => {
      state.archiveOwnerStatus = LOADING_STATUSES.succeeded;
    });
    addCase(archiveOwner.rejected, (state) => {
      state.archiveOwnerStatus = LOADING_STATUSES.failed;
    });

    // get referred to
    addCase(getReferrals.pending, (state) => {
      state.getReferralsStatus = LOADING_STATUSES.pending;
    });
    addCase(getReferrals.fulfilled, (state, { payload }) => {
      state.getReferralsStatus = LOADING_STATUSES.succeeded;
      state.referrals = payload.data;
    });
    addCase(getReferrals.rejected, (state) => {
      state.getReferralsStatus = LOADING_STATUSES.failed;
    });

    //post referred to
    addCase(postReferredTo.pending, (state) => {
      state.postReferredToStatus = LOADING_STATUSES.pending;
      state.postReferredToError = null;
    });
    addCase(postReferredTo.fulfilled, (state, { payload }) => {
      state.postReferredToStatus = LOADING_STATUSES.succeeded;
      state.referrals = payload.data;
    });
    addCase(postReferredTo.rejected, (state, { payload }) => {
      state.postReferredToStatus = LOADING_STATUSES.failed;
      state.postReferredToError = payload?.data;
    });

    //update referred to
    addCase(updateReferral.pending, (state) => {
      state.updateReferralStatus = LOADING_STATUSES.pending;
      state.updateReferralError = null;
    });
    addCase(updateReferral.fulfilled, (state, { payload }) => {
      state.referredBy = payload.data;
      state.updateReferralStatus = LOADING_STATUSES.succeeded;
    });
    addCase(updateReferral.rejected, (state, { payload }) => {
      state.updateReferralError = payload?.data;
      state.updateReferralStatus = LOADING_STATUSES.failed;
    });

    // get referred by
    addCase(getReferredBy.pending, (state) => {
      state.getReferredByStatus = LOADING_STATUSES.pending;
    });
    addCase(getReferredBy.fulfilled, (state, { payload }) => {
      state.referredBy = payload?.data;
      state.getReferredByStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getReferredBy.rejected, (state) => {
      state.getReferredByStatus = LOADING_STATUSES.failed;
    });

    //post referred by
    addCase(postReferredBy.pending, (state) => {
      state.postReferredByStatus = LOADING_STATUSES.pending;
      state.postReferredByError = null;
    });
    addCase(postReferredBy.fulfilled, (state) => {
      state.postReferredByStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postReferredBy.rejected, (state, { payload }) => {
      state.postReferredByStatus = LOADING_STATUSES.failed;
      state.postReferredByError = payload.data;
    });

    //delete referred by
    addCase(deleteReferredBy.pending, (state) => {
      state.deleteReferredByStatus = LOADING_STATUSES.pending;
    });
    addCase(deleteReferredBy.fulfilled, (state) => {
      state.referredBy = null;
      state.deleteReferredByStatus = LOADING_STATUSES.succeeded;
    });
    addCase(deleteReferredBy.rejected, (state) => {
      state.deleteReferredByStatus = LOADING_STATUSES.failed;
    });

    //delete referred to
    addCase(deleteReferredTo.pending, (state) => {
      state.deleteReferredToStatus = LOADING_STATUSES.pending;
    });
    addCase(deleteReferredTo.fulfilled, (state, { payload }) => {
      state.referrals = state.referrals.filter((item) => item.id !== payload);
      state.deleteReferredToStatus = LOADING_STATUSES.succeeded;
    });
    addCase(deleteReferredTo.rejected, (state) => {
      state.deleteReferredToStatus = LOADING_STATUSES.failed;
    });

    //archive property
    addCase(archiveProperty.pending, (state) => {
      state.archivePropertyStatus = LOADING_STATUSES.pending;
    });
    addCase(archiveProperty.fulfilled, (state, { payload }) => {
      state.singleOwner.properties = state.singleOwner.properties.map((item) => {
        if (item.id === payload.id) {
          return { ...item, is_archived: item.is_archived === 0 ? 1 : 0 };
        }
        return item;
      });
      state.archivePropertyStatus = LOADING_STATUSES.succeeded;
    });

    addCase(archiveProperty.rejected, (state) => {
      state.archivePropertyStatus = LOADING_STATUSES.failed;
    });

    //update account number
    addCase(updateAccountNumber.pending, (state) => {
      state.updateAccountNumberStatus = LOADING_STATUSES.pending;
    });
    addCase(updateAccountNumber.fulfilled, (state) => {
      state.updateAccountNumberStatus = LOADING_STATUSES.succeeded;
    });
    addCase(updateAccountNumber.rejected, (state, payload) => {
      state.accountNumberErrorMessage = payload.payload.data.message;
      state.updateAccountNumberStatus = LOADING_STATUSES.failed;
    });

    //update street address
    addCase(updateStreetAddress.pending, (state) => {
      state.updateStreetAddressStatus = LOADING_STATUSES.pending;
    });
    addCase(updateStreetAddress.fulfilled, (state) => {
      state.updateStreetAddressStatus = LOADING_STATUSES.succeeded;
    });
    addCase(updateStreetAddress.rejected, (state) => {
      state.updateStreetAddressStatus = LOADING_STATUSES.failed;
    });

    //update email
    addCase(updateEmail.pending, (state) => {
      state.updateEmailStatus = LOADING_STATUSES.pending;
    });
    addCase(updateEmail.fulfilled, (state) => {
      state.updateEmailStatus = LOADING_STATUSES.succeeded;
    });
    addCase(updateEmail.rejected, (state) => {
      state.updateEmailStatus = LOADING_STATUSES.failed;
    });

    //get notes
    addCase(getNotes.pending, (state) => {
      state.getNotesStatus = LOADING_STATUSES.pending;
    });
    addCase(getNotes.fulfilled, (state, { payload }) => {
      if (payload.is_owner === 1) {
        state.ownerNotes = payload;
      } else {
        state.adminNotes = payload;
      }
      state.getNotesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getNotes.rejected, (state) => {
      state.getNotesStatus = LOADING_STATUSES.failed;
    });

    // post note
    addCase(postNote.pending, (state) => {
      state.postNoteStatus = LOADING_STATUSES.pending;
    });
    addCase(postNote.fulfilled, (state) => {
      state.postNoteStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postNote.rejected, (state) => {
      state.postNoteStatus = LOADING_STATUSES.failed;
    });

    // update contacted status
    addCase(updateContactedStatus.pending, (state) => {
      state.changeContactedStatus = LOADING_STATUSES.pending;
    });
    addCase(updateContactedStatus.fulfilled, (state) => {
      state.changeContactedStatus = LOADING_STATUSES.succeeded;
    });
    addCase(updateContactedStatus.rejected, (state) => {
      state.changeContactedStatus = LOADING_STATUSES.failed;
    });
  },
});

export const singleOwnerReducer = singleOwnerSlice.reducer;

export const singleOwnerActions = {
  ...singleOwnerSlice.actions,
  archiveOwner,
  archiveProperty,
  connectOwnerProperty,
  createDocument,
  deleteAccountLogs,
  deleteDocument,
  deleteProperty,
  deleteReferredBy,
  deleteReferredTo,
  getReferrals,
  getReferredBy,
  getSingleOwner,
  postAccountLogs,
  postReferredBy,
  postReferredTo,
  sendAoA,
  updateDocument,
  updateReferral,
  verifySingleProperty,
  updateAccountNumber,
  updateStreetAddress,
  updateEmail,
  getNotes,
  postNote,
  updateContactedStatus
};
