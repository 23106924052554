import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Loader, PrimaryButton, PrimaryTextarea, Select } from "common";
import { getProperties, getSingleProperty } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { contactUsSchema } from "validations/contactUsSchema";

import * as S from "./styled";

const ContacUsForm = ({ addressSelectOptions }) => {
  const dispatch = useDispatch();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(contactUsSchema),
  });

  const findSelectedPropertyId = (value) => {
    const id = addressSelectOptions.find((option) => option.value === value).id;
    dispatch(getSingleProperty(id));
  };

  const onSubmit = (data) => {
    if (!isValid) {
      return;
    }
  };

  return (
    <S.Container onSubmit={handleSubmit(onSubmit)}>
      <S.ContactUsFormWrapper>
        <S.Title>Contact Us</S.Title>
        <Controller
          control={control}
          name="address"
          render={({ field: { value, onChange } }) => (
            <Select
              defaultValue={value}
              error={errors.address}
              itemKey="label"
              items={addressSelectOptions}
              label="Address"
              value={value}
              onChange={(value) => {
                findSelectedPropertyId(value);
                onChange(value);
              }}
            />
          )}
        />
        <PrimaryTextarea error={errors.description} label="Message*" register={register("description")} />
        <PrimaryButton label="submit" type="submit" />
      </S.ContactUsFormWrapper>
    </S.Container>
  );
};

export const ContactUsPage = () => {
  const { properties } = useSelector(clientSelectors);

  const dispatch = useDispatch();

  const addressSelectOptions = useMemo(
    () =>
      properties.map((property) => ({
        id: property.id,
        label: property.street_address,
        value: property.street_address,
      })),
    [properties]
  );

  useEffect(() => {
    dispatch(getProperties());
  }, []);

  if (!addressSelectOptions.length) {
    return (
      <S.LoaderWrapper>
        <Loader size={80} />
      </S.LoaderWrapper>
    );
  }

  return <ContacUsForm addressSelectOptions={addressSelectOptions} />;
};
