import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  textarea {
    font-size: 14px;
    line-height: 21px;

    @media screen and (${BREAKPOINTS.md}) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const ContactUsFormWrapper = styled.form`
  width: 100%;
  max-width: 600px;
  height: fit-content;
  padding: 32px;
  background: ${COLORS.overlay_060};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const Title = styled.div`
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;

  @media screen and (${BREAKPOINTS.md}) {
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
  }
`;

export const LoaderWrapper = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
`;
