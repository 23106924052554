import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Font, pdf, PDFDownloadLink } from "@react-pdf/renderer";
// react pdf font register
import SerifRegular from "assets/fonst/NotoSerif-Regular.ttf";
import RobotoBold from "assets/fonst/Roboto-Bold.ttf";
import RobotoLight from "assets/fonst/Roboto-Light.ttf";
import RobotoMedium from "assets/fonst/Roboto-Medium.ttf";
import RobotoRegular from "assets/fonst/Roboto-Regular.ttf";
import { PrimaryButton } from "common";
import { SuccessModal } from "pages/client/AddPropertyPage/SuccessModal";
import { clientSelectors } from "store/client-slice/selectors";
import { ownerPortalPropertySelector } from "store/owner-portal-property-slice/selectors";
import { ownerPortalPropertyActions } from "store/owner-portal-property-slice/slice";
import { isLoading, LOADING_STATUSES } from "utils/constants";
import { generateDocSignUrl } from "utils/docusign";
import { getImageFromTypedSignature, setStorage, toggleClassOnRoot } from "utils/helpers";

import { AoADocument, DownloadAoADocumentPreview, SADocument } from "components/Documents";
import { Properties } from "components/Properties";
import { Signature } from "components/Signature";
import { ViewPdfModal } from "components/ViwePdfModal";

import * as S from "./styled";

Font.register({
  family: "Serif",
  fonts: [{ src: SerifRegular, fontWeight: 400 }],
});

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoMedium, fontWeight: "medium" },
    { src: RobotoLight, fontWeight: "light" },
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoBold, fontWeight: "bold" },
  ],
});

export const Step2 = () => {
  const {
    properties,
    signature,
    completeAddPropertyStatus,
    signType,
    isOpenSuccessModal,
    isAddedManually,
    signatureErr,
  } = useSelector(ownerPortalPropertySelector);

  const { account } = useSelector(clientSelectors);

  const owner_id = account?.id;
  const typedSignRef = useRef(null);

  const [isOpenViewPdfModal, setIsOpenViewPdfModal] = useState(false);

  const dispatch = useDispatch();

  const firstPropIndex = properties.findIndex(
    (obj) => Object.prototype.hasOwnProperty.call(obj, "county_owner_name") && obj["county_owner_name"] !== null
  );

  const firstProp = firstPropIndex !== -1 ? properties[firstPropIndex] : null;

  const handleBackClick = (e) => {
    e.preventDefault();
    dispatch(ownerPortalPropertyActions.backStep());
  };

  const toggleModal = () => {
    document.getElementById("root").classList.toggle("prevent-scroll-desktop");
    setIsOpenViewPdfModal((prev) => !prev);
  };

  const isRedirectToDocusign = () => {
    return properties[0].county.toLowerCase() === "collin" || properties[0].county.toLowerCase() === "harris";
  };

  const redirectToDocusign = () => {
    setStorage("owner_data", { email: account.email });
    const url = generateDocSignUrl({ ...account, properties });
    window.location.href = url;
  };

  const onSubmit = async () => {
    if (!signature) {
      dispatch(ownerPortalPropertyActions.setSignatureErr());
      return;
    }

    if (account.referral_code?.trim()) {
      dispatch(
        ownerPortalPropertyActions.postAccountLogs({
          owner_id: owner_id,
          type: "Referral Code",
          content: "" + account.referral_code?.trim(),
          flag: 1,
        })
      );
    }

    properties.forEach((prop) => {
      if (!prop.id && !prop.account_number) {
        dispatch(
          ownerPortalPropertyActions.postAccountLogs({
            owner_id: owner_id,
            type: "Account Number is missing",
            content: prop.address,
            flag: 1,
          })
        );

        dispatch(
          ownerPortalPropertyActions.postProperty({
            ownerId: owner_id,
            county: prop.county,
            street_address: prop.address.trim(),
            city: prop.cityStateZip.trim().split(" ")[0] ?? "",
            zipcode: prop.cityStateZip.trim().split(" ")[2] ?? "",
            from_portal: 1,
          })
        );
      } else {
        dispatch(
          ownerPortalPropertyActions.connectOwnerProperty({
            owner_id: owner_id,
            property_id: prop.id,
            is_completed: 1,
            unit_id: prop.unit,
            is_manually: isAddedManually ? 0 : 1,
            from_portal: 1,
          })
        );
      }
    });

    const FData = new FormData();
    if (signType) {
      const transformedSignature = await getImageFromTypedSignature({ signature, signType, typedSignRef });
      const aoaBlob = await pdf(AoADocument({ owner: account, properties, signature: transformedSignature, signType })).toBlob();
      const saBlob = await pdf(SADocument({ owner: account, signature: transformedSignature, signType })).toBlob();

      const docs_sa = new File([saBlob], "sa.pdf");
      const docs_aoa = new File([aoaBlob], "aoa.pdf");
      FData.append("docs_sa", docs_sa);
      FData.append("docs_aoa", docs_aoa);
      FData.append("owner_id", owner_id);
      FData.append("send_email", 0);
      FData.append("is_user_generated", 1);
      FData.append("signature_data_url", transformedSignature);
    }

    dispatch(ownerPortalPropertyActions.completeAddProperty(FData));
  };

  useEffect(() => {
    if (completeAddPropertyStatus === LOADING_STATUSES.succeeded) {
      toggleClassOnRoot();
      dispatch(ownerPortalPropertyActions.setIsOpenSuccessModal(true));
      dispatch(
        ownerPortalPropertyActions.updateOwner({
          id: owner_id,
          body: {
            documents_yn: 1,
            sign_up_step_number: 3,
            display_clients: 1,
          },
        })
      );
      dispatch(ownerPortalPropertyActions.postPropertyId(properties[0].id));
    }
  }, [completeAddPropertyStatus]);

  useEffect(() => {
    if (signature && signatureErr) {
      dispatch(ownerPortalPropertyActions.deleteSignatureErr());
    }
  }, [signature, signatureErr]);

  return (
    <S.Box>
      <S.Box className="p-3" />
      <S.Step2Header>
        <S.UserInfoContainer>
          <S.InfoBlock className="pb-8">
            <S.Box className="pb-3 text-[green]">First Name</S.Box>
            <S.Box>{account?.first_name}</S.Box>
          </S.InfoBlock>
          <S.InfoBlock className=" pb-8">
            <S.Box className="pb-3 text-[green]">Last Name</S.Box>
            <S.Box>{account?.last_name}</S.Box>
          </S.InfoBlock>
          <S.InfoBlock className=" pb-8">
            <S.Box className="pb-3 text-[green]">Phone</S.Box>
            <S.Box>{account?.phone_number}</S.Box>
          </S.InfoBlock>
        </S.UserInfoContainer>
        <S.Block>
          <S.Box>
            <S.Box className="pb-3 text-[green]">List of properties</S.Box>
            <S.Box>
              <Properties properties={properties} />
            </S.Box>
          </S.Box>
        </S.Block>
      </S.Step2Header>
      <S.Box>
        <S.SubTitle>Registration Document Overview:</S.SubTitle>
        <S.Ul className="mb-10">
          <S.Li>
            Appointment of Agent form, which authorizes us to protest your proposed market value to the Appraisal Review
            Board.
          </S.Li>
          <S.Li>
            Service Agreement, which outlines our services and fees. We charge 1% of the market value reduction we
            achieve on your protest. If there's no reduction, there's no fee.
          </S.Li>
        </S.Ul>
      </S.Box>
      {!isRedirectToDocusign() ? (
        <>
          <Signature
            ref={typedSignRef}
            deleteSignature={() => dispatch(ownerPortalPropertyActions.deleteSignature())}
            setSignature={(signature) => dispatch(ownerPortalPropertyActions.setSignature({ signature }))}
            setSignType={(type) => dispatch(ownerPortalPropertyActions.setSignType(type))}
            signatureErr={signatureErr}
            signType={signType}
          />
          <S.DownloadDoc>
            <DownloadAoADocumentPreview
              ref={typedSignRef}
              owner={account}
              properties={properties}
              signature={signature}
              signType={signType} />
          </S.DownloadDoc>
          <S.ViewDoc>
            <S.preview onClick={toggleModal}>Preview Documents</S.preview>
          </S.ViewDoc>
        </>
      ) : null}
      <S.ButtonsContainer>
        {isRedirectToDocusign() ? (
          <PrimaryButton eventHandler={redirectToDocusign} label="complete" />
        ) : (
          <PrimaryButton
            disabled={isRedirectToDocusign() ? !signature : null}
            eventHandler={onSubmit}
            label="complete"
            loading={isLoading(completeAddPropertyStatus)}
          />
        )}
        <PrimaryButton eventHandler={handleBackClick} label="Back" mode="gray" />
      </S.ButtonsContainer>
      {isOpenViewPdfModal ? (
        <ViewPdfModal
          ref={typedSignRef}
          firstProp={firstProp}
          ownerData={account}
          properties={properties}
          signature={signature}
          signType={signType}
          toggleModal={toggleModal}
        />
      ) : null}
      {isOpenSuccessModal && <SuccessModal />}
    </S.Box>
  );
};
