import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, RESIDENT_TYPES, SLICE_NAME, TOAST_TYPES } from "utils/constants";
import { toastify } from "utils/helpers";

import {
  exportCompsCsv,
  getColumnsInfo,
  getCompsFilters,
  getEvidencesEquities,
  getEvidencesSales,
  getEvidenceStatus,
  getFilters,
  getLeftBarValues,
  getSaveComps,
  getSubjectProperty,
  resetComps,
  resetFilters,
  saveColumnsInfo,
  saveComps,
  saveFilters,
  setEvidenceStatus,
} from "./actions";

const initialState = {
  status: null,
  isOpenSettingsModal: false,
  isSideBarOpen: true,
  equities: [],
  sales: [],
  savedComps: [],
  subjectProperty: null,
  leftBarValues: null,
  downloadCompsCsvHref: null,
  hasNotFilters: false,
  currentCounty: "",
  filteredBody: {},
  savedFilters: {},
  sortData: {
    field: "",
    direction: -1,
  },
  columnsInfo: [],
  compsFilters: null,
  ranges: null,
  floats: null,
  selects: null,
  dates: null,
  residentType: RESIDENT_TYPES.equity,
  tableData: [],
  getSortDataStatus: LOADING_STATUSES.idle,
  getEvidanceStatusStatus: LOADING_STATUSES.idle,
  getEvidencesSalesStatus: LOADING_STATUSES.idle,
  getEvidencesEquitiesStatus: LOADING_STATUSES.idle,
  getSaveCompsStatus: LOADING_STATUSES.idle,
  saveCompsStatus: LOADING_STATUSES.idle,
  resetCompsStatus: LOADING_STATUSES.idle,
  resetFiltersStatus: LOADING_STATUSES.idle,
  exportCompsCsvStatus: LOADING_STATUSES.idle,
  getCompsFiltersStatus: LOADING_STATUSES.idle,
  getLeftBarValuesStatus: LOADING_STATUSES.idle,
  getSubjectPropertyStatus: LOADING_STATUSES.idle,
  saveFiltersStatus: LOADING_STATUSES.idle,
  getFiltersStatus: LOADING_STATUSES.idle,
  saveColumnsInfoStatus: LOADING_STATUSES.idle,
};

export const singleEvidenceSlice = createSlice({
  name: SLICE_NAME.singleEvidenceSlice,
  initialState,
  reducers: {
    resetAllData: () => initialState,
    openSettingsModal: (state) => {
      state.isOpenSettingsModal = true;
    },
    closeSettingsModal: (state) => {
      state.isOpenSettingsModal = false;
    },
    setCurrentCounty: (state, { payload }) => {
      state.currentCounty = payload;
    },
    selectResidentType: (state, { payload }) => {
      state.residentType = payload.type;

      const showingRowsForResidentTypes = {
        harris: {
          [RESIDENT_TYPES.equity]: [
            "property_id",
            "account_number",
            "street_address",
            "adjusted_value",
            "comp_index",
            "net_adj",
            "distance",
            "land_area",
            "land_value",
            "neighborhood_group",
            "land_total_area",
            "year_build",
            "eff_year_build",
            "neighborhood_code",
            "certified_market_value",
            "condition",
            "quality",
          ],
          [RESIDENT_TYPES.sales]: [
            "account_number",
            "street_address",
            "adjusted_value",
            "net_adj",
            "net_adj_prc",
            "distance",
            "main_area",
            "land_value",
            "neighborhood_group",
            "year_build",
            "eff_year_build",
            "certified_market_value",
            "neighborhood_code",
            "impr_value",
            "impr_PSF",
            "close_date",
            "close_price",
          ],
        },
        collin: {
          [RESIDENT_TYPES.sales]: [
            "account_number",
            "street_address",
            "adjusted_value",
            "close_date",
            "sale_date",
            "net_adj",
            "distance",
            "main_area",
            "impr_PSF",
            "year_build",
            "close_price",
            "total_PSF",
            "eff_year_build",
            "deed_year",
            "class_code",
            "neighborhood_code",
            "subdivision_code",
            "owner_name",
            "city",
          ],
          [RESIDENT_TYPES.equity]: [
            "account_number",
            "street_address",
            "adjusted_value",
            "net_adj",
            "distance",
            "main_area",
            "impr_PSF",
            "year_build",
            "notice_value",
            "certified_market_value",
            "eff_year_build",
            "land_value",
            "impr_value",
            "class_code",
            "land_area",
            "neighborhood_code",
            "subdivision_code",
            "owner_name",
            "city",
          ],
        },
        tarrant: {
          [RESIDENT_TYPES.sales]: [
            "account_number",
            "street_address",
            "adjusted_value",
            "net_adj",
            "net_adj_prc",
            "distance",
            "main_area",
            "comp_index",
            "year_build",
            "quality",
            "condition",
            "certified_market_value",
            "close_price",
            "close_date",
            "impr_PSF",
          ],
          [RESIDENT_TYPES.equity]: [
            "account_number",
            "adjusted_value",
            "net_adj",
            "net_adj_prc",
            "distance",
            "land_area",
            "main_area",
            "comp_index",
            "year_build",
            "quality",
            "certified_market_value",
            "condition",
            "impr_value",
            "impr_PSF",
          ],
        },
        denton: {
          [RESIDENT_TYPES.equity]: [
            "account_number",
            "adjusted_value",
            "net_adj",
            "net_adj_prc",
            "distance",
            "main_area",
            "land_total_area",
            "class_code",
            "land_value",
            "year_build",
            "certified_market_value",
            "impr_value",
            "impr_PSF",
          ],
          [RESIDENT_TYPES.sales]: [
            "account_number",
            "adjusted_value",
            "net_adj",
            "neighborhood_code",
            "net_adj_prc",
            "distance",
            "main_area",
            "impr_PSF",
            "land_total_area",
            "class_code",
            "close_date",
            "close_price",
            "land_value",
            "year_build",
            "eff_year_build",
            "certified_market_value",
          ],
        },
        dallas: {
          [RESIDENT_TYPES.equity]: [
            "street_address",
            "account_number",
            "adjusted_value",
            "net_adj",
            "net_adj_prc",
            "distance",
            "main_area",
            "land_total_area",
            "year_build",
            "desirability",
            "neighborhood_code",
            "certified_market_value",
            "class_code",
            "impr_value",
            "impr_PSF",
          ],
          [RESIDENT_TYPES.sales]: [
            "account_number",
            "street_address",
            "adjusted_value",
            "close_date",
            "net_adj",
            "net_adj_prc",
            "distance",
            "main_area",
            "impr_PSF",
            "land_total_area",
            "year_build",
            "desirability",
            "close_price",
            "neighborhood_code",
            "class_code",
            "certified_market_value",
            "land_value",
          ],
        },
        fortbend: {
          [RESIDENT_TYPES.equity]: [
            "account_number",
            "adjusted_value",
            "net_adj",
            "net_adj_prc",
            "distance",
            "main_area",
            "land_total_area",
            "year_build",
            "land_value",
            "land_area",
            "certified_market_value",
            "impr_value",
            "impr_PSF",
          ],
          [RESIDENT_TYPES.sales]: [
            "account_number",
            "street_address",
            "adjusted_value",
            "close_date",
            "net_adj",
            "net_adj_prc",
            "distance",
            "main_area",
            "impr_PSF",
            "year_build",
            "close_price",
            "certified_market_value",
          ],
        },
        williamson: {
          [RESIDENT_TYPES.equity]: [
            "account_number",
            "adjusted_value",
            "net_adj",
            "net_adj_prc",
            "distance",
            "main_area",
            "year_build",
            "land_area",
            "certified_market_value",
            "impr_PSF",
          ],
          [RESIDENT_TYPES.sales]: [
            "account_number",
            "adjusted_value",
            "net_adj",
            "net_adj_prc",
            "distance",
            "main_area",
            "land_total_area",
            "year_build",
            "land_area",
            "close_price",
            "close_date",
            "certified_market_value",
            "impr_PSF",
          ],
        },
        montgomery: {
          [RESIDENT_TYPES.equity]: [
            "account_number",
            "adjusted_value",
            "net_adj",
            "net_adj_prc",
            "distance",
            "main_area",
            "year_build",
            "land_area",
            "land_total_area",
            "certified_market_value",
            "impr_PSF",
          ],
          [RESIDENT_TYPES.sales]: [
            "account_number",
            "adjusted_value",
            "net_adj",
            "net_adj_prc",
            "distance",
            "main_area",
            "land_total_area",
            "year_build",
            "close_price",
            "close_date",
            "certified_market_value",
            "impr_PSF",
            "impr_value",
          ],
        },
      };

      const updateTableData = (tableData, fieldsToShow) => {
        return tableData.map((item) => ({
          ...item,
          isShow: fieldsToShow?.includes(item.field),
        }));
      };

      if (showingRowsForResidentTypes[payload.county]) {
        state.tableData = updateTableData(state.tableData, showingRowsForResidentTypes[payload.county][payload.type]);
      }
    },

    setTableData: (state, { payload }) => {
      if (payload === "collin") {
        state.tableData = [
          {
            field: "account_number",
            header: "Account Number",
            sortable: true,
            isShow: true,
          },
          {
            field: "street_address",
            header: "Situs",
            sortable: true,
            isShow: true,
            body: (item) => (
              <p className="whitespace-nowrap text-ellipsis overflow-hidden" title={item.street_address}>
                {item.street_address}
              </p>
            ),
          },
          {
            field: "adjusted_value",
            header: "Adjusted Value",
            body: (item) => (item?.adjusted_value ? `$${Number(item.adjusted_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "close_date",
            header: "Sale Date",
            sortable: true,
            isShow: false,
          },
          {
            field: "net_adj",
            header: "Net Adj %",
            sortable: true,
            isShow: true,
            body: (item) => `${item.net_adj ? item.net_adj.toFixed(2) + "%" : "-"}`,
          },
          {
            field: "distance",
            header: "Distance",
            body: (item) => (item?.distance ? Number(item.distance).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "main_area",
            body: (item) => (item?.main_area ? Number(item.main_area).toLocaleString() : "-"),
            header: "Main Area",
            sortable: true,
            isShow: true,
          },
          {
            field: "impr_PSF",
            body: (item) => (item?.impr_PSF ? Number(item.impr_PSF).toFixed(2).toLocaleString() : "-"),
            header: "Impr PSF",
            sortable: true,
            isShow: true,
          },
          {
            field: "year_build",
            header: "Year Built",
            sortable: true,
            isShow: true,
          },
          {
            field: "notice_value",
            header: "Noticed Val",
            body: (item) => (item?.notice_value ? `$${Number(item.notice_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "certified_market_value",
            header: "Market Val",
            body: (item) =>
              item?.certified_market_value ? `$${Number(item.certified_market_value).toLocaleString()}` : "-",
            sortable: true,
            isShow: true,
          },
          {
            field: "close_price",
            header: "Sale Price",
            body: (item) => (item?.close_price ? `$${Number(item.close_price).toLocaleString()}` : "-"),
            sortable: true,
            isShow: false,
          },
          {
            field: "total_PSF",
            header: "Total PSF",
            body: (item) => (item?.total_PSF ? Number(item.total_PSF).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: false,
          },
          {
            field: "eff_year_build",
            header: "EYOC",
            sortable: true,
            isShow: true,
          },
          {
            field: "deed_year",
            header: "Deed Year",
            sortable: true,
            isShow: false,
          },
          {
            field: "land_value",
            header: "Land Value",
            body: (item) => (item?.land_value ? `$${Number(item.land_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "impr_value",
            header: "Impr Value",
            body: (item) => (item?.impr_value ? `$${Number(item.impr_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "class_code",
            header: "Property Class",
            sortable: true,
            isShow: true,
          },
          {
            field: "land_area",
            header: "Land SQFT",
            body: (item) => (item?.land_area ? Number(Number(item.land_area).toFixed(0)).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "neighborhood_code",
            header: "Neighborhood Code",
            sortable: true,
            isShow: true,
          },
          {
            field: "subdivision_code",
            header: "Subdivision",
            sortable: true,
            isShow: true,
          },
          {
            field: "owner_name",
            header: "Owner",
            body: (item) => (
              <p className="whitespace-nowrap text-ellipsis overflow-hidden" title={item.owner_name}>
                {item.owner_name}
              </p>
            ),
            sortable: true,
            isShow: true,
          },
          {
            field: "city",
            header: "City",
            sortable: true,
            isShow: true,
          },
        ];
      } else if (payload === "tarrant") {
        state.tableData = [
          {
            field: "account_number",
            header: "Account Number",
            sortable: true,
            isShow: true,
          },
          {
            field: "street_address",
            header: "Situs",
            sortable: true,
            isShow: true,
          },
          {
            field: "adjusted_value",
            header: "Adjusted value",
            body: (item) => (item?.adjusted_value ? `$${Number(item.adjusted_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "net_adj",
            header: "Net Adj",
            sortable: true,
            isShow: true,
            body: (item) => (item?.net_adj ? `${Math.round(Number(item.net_adj)).toLocaleString()}` : "-"),
          },
          {
            field: "net_adj_prc",
            header: "Net Adj %",
            sortable: true,
            isShow: true,
            body: (item) => `${item.net_adj_prc ? item.net_adj_prc.toFixed(2) + "%" : "-"}`,
          },
          {
            field: "distance",
            header: "Distance",
            body: (item) => (item?.distance ? Number(item.distance).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "land_area",
            body: (item) => (item?.land_area ? Number(item.land_area).toLocaleString() : "-"),
            header: "Land SQFT",
            sortable: true,
            isShow: true,
          },
          {
            field: "main_area",
            body: (item) => (item?.main_area ? Number(item.main_area).toLocaleString() : "-"),
            header: "Main area",
            sortable: true,
            isShow: true,
          },
          {
            field: "comp_index",
            body: (item) => (item?.comp_index ? Number(item.comp_index).toLocaleString() : "-"),
            header: "Comp index",
            sortable: true,
            isShow: true,
          },
          {
            field: "year_build",
            header: "Year built",
            sortable: true,
            isShow: true,
          },
          {
            field: "certified_market_value",
            header: "Market val",
            body: (item) =>
              item?.certified_market_value ? `$${Number(item.certified_market_value).toLocaleString()}` : "-",
            sortable: true,
            isShow: true,
          },
          {
            field: "quality",
            header: "Quality",
            sortable: true,
            isShow: true,
          },
          {
            field: "condition",
            header: "Condition",
            sortable: true,
            isShow: true,
          },
          {
            field: "close_date",
            header: "Sale date",
            sortable: true,
            isShow: false,
          },
          {
            field: "close_price",
            body: (item) => (item?.close_price ? `$${Number(item.close_price).toLocaleString()}` : "-"),
            header: "Sale price",
            sortable: true,
            isShow: false,
          },
          {
            field: "impr_value",
            header: "IMPR value",
            body: (item) => (item?.impr_value ? `$${Number(item.impr_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "impr_PSF",
            header: "IMPR PSF",
            body: (item) => (item?.impr_PSF ? Number(item.impr_PSF).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
        ];
      } else if (payload === "harris") {
        state.tableData = [
          {
            field: "account_number",
            header: "Account Number",
            sortable: true,
            isShow: true,
          },
          {
            field: "street_address",
            header: "Situs",
            sortable: true,
            isShow: true,
            body: (item) => (
              <p className="whitespace-nowrap text-ellipsis overflow-hidden" title={item.street_address}>
                {item.street_address}
              </p>
            ),
          },
          {
            field: "adjusted_value",
            header: "Adjusted value",
            body: (item) => (item?.adjusted_value ? `$${Number(item.adjusted_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "net_adj",
            header: "Net Adj",
            sortable: true,
            isShow: true,
            body: (item) => (item?.net_adj ? `${Math.round(Number(item.net_adj)).toLocaleString()}` : "-"),
          },
          {
            field: "net_adj_prc",
            header: "Net Adj %",
            sortable: true,
            isShow: true,
            body: (item) => `${item.net_adj_prc ? item.net_adj_prc.toFixed(2) + "%" : "-"}`,
          },
          {
            field: "distance",
            header: "Distance",
            body: (item) => (item?.distance ? Number(item.distance).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "neighborhood_group",
            header: "Neighborhood Group",
            sortable: true,
            isShow: true,
          },
          {
            field: "land_area",
            body: (item) => (item?.land_area ? Number(item.land_area).toLocaleString() : "-"),
            header: "Land SQFT",
            sortable: true,
            isShow: true,
          },
          {
            field: "main_area",
            body: (item) => (item?.main_area ? Number(item.main_area).toLocaleString() : "-"),
            header: "Main area",
            sortable: true,
            isShow: true,
          },
          {
            field: "land_value",
            body: (item) => (item?.land_value ? `$${Number(item.land_value).toLocaleString()}` : "-"),
            header: "Land value",
            sortable: true,
            isShow: true,
          },
          {
            field: "year_build",
            header: "Year built",
            sortable: true,
            isShow: true,
          },
          {
            field: "eff_year_build",
            header: "EYOC",
            sortable: true,
            isShow: true,
          },
          {
            field: "certified_market_value",
            header: "Market val",
            body: (item) =>
              item?.certified_market_value ? `$${Number(item.certified_market_value).toLocaleString()}` : "-",
            sortable: true,
            isShow: true,
          },
          {
            field: "neighborhood_code",
            header: "Neighborhood Code",
            sortable: true,
            isShow: true,
          },
          {
            field: "impr_value",
            header: "IMPR value",
            body: (item) => (item?.impr_value ? `$${Number(item.impr_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "impr_PSF",
            header: "IMPR PSF",
            body: (item) => (item?.impr_PSF ? Number(item.impr_PSF).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "close_date",
            header: "Sale date",
            sortable: true,
            isShow: false,
          },
          {
            field: "close_price",
            header: "Sale Price",
            body: (item) => (item?.close_price ? `$${Number(item.close_price).toLocaleString()}` : "-"),
            sortable: true,
            isShow: false,
          },
        ];
      } else if (payload === "denton") {
        state.tableData = [
          {
            field: "account_number",
            header: "Account Number",
            sortable: true,
            isShow: true,
          },
          {
            field: "adjusted_value",
            header: "Adjusted value",
            body: (item) => (item?.adjusted_value ? `$${Number(item.adjusted_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "net_adj",
            header: "Net Adj",
            sortable: true,
            isShow: true,
            body: (item) => (item?.net_adj ? `${Math.round(Number(item.net_adj)).toLocaleString()}` : "-"),
          },
          {
            field: "net_adj_prc",
            header: "Net Adj %",
            sortable: true,
            isShow: true,
            body: (item) => `${item.net_adj_prc ? item.net_adj_prc.toFixed(2) + "%" : "-"}`,
          },
          {
            field: "distance",
            header: "Distance",
            body: (item) => (item?.distance ? Number(item.distance).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "main_area",
            body: (item) => (item?.main_area ? Number(Math.round(item.main_area)).toLocaleString() : "-"),
            header: "Main area",
            sortable: true,
            isShow: true,
          },
          {
            field: "land_total_area",
            body: (item) => (item?.land_total_area ? Number(item.land_total_area).toLocaleString() : "-"),
            header: "Total Area",
            sortable: true,
            isShow: true,
          },
          {
            field: "class_code",
            header: "Property Class",
            sortable: true,
            isShow: true,
          },
          {
            field: "land_value",
            body: (item) => (item?.land_value ? `$${Number(item.land_value).toLocaleString()}` : "-"),
            header: "Land value",
            sortable: true,
            isShow: true,
          },
          {
            field: "year_build",
            header: "Year built",
            sortable: true,
            isShow: true,
          },
          {
            field: "eff_year_build",
            header: "EYOC",
            sortable: true,
            isShow: false,
          },
          {
            field: "certified_market_value",
            header: "Market val",
            body: (item) =>
              item?.certified_market_value ? `$${Number(item.certified_market_value).toLocaleString()}` : "-",
            sortable: true,
            isShow: true,
          },
          {
            field: "neighborhood_code",
            header: "Neighborhood Code",
            sortable: true,
            isShow: false,
          },
          {
            field: "impr_value",
            header: "IMPR value",
            body: (item) => (item?.impr_value ? `$${Number(item.impr_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "impr_PSF",
            header: "IMPR PSF",
            body: (item) => (item?.impr_PSF ? Number(item.impr_PSF).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "close_date",
            header: "Sale date",
            sortable: true,
            isShow: false,
          },
          {
            field: "close_price",
            header: "Sale Price",
            body: (item) => (item?.close_price ? `$${Number(item.close_price).toLocaleString()}` : "-"),
            sortable: true,
            isShow: false,
          },
        ];
      } else if (payload === "dallas") {
        state.tableData = [
          {
            field: "account_number",
            header: "Account Number",
            sortable: true,
            isShow: true,
          },
          {
            field: "street_address",
            header: "Situs",
            sortable: true,
            isShow: true,
            body: (item) => (
              <p className="whitespace-nowrap text-ellipsis overflow-hidden" title={item.street_address}>
                {item.street_address}
              </p>
            ),
          },
          {
            field: "adjusted_value",
            header: "Adjusted Value",
            body: (item) => (item?.adjusted_value ? `$${Number(item.adjusted_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "close_date",
            header: "Sale Date",
            sortable: true,
            isShow: false,
          },
          {
            field: "net_adj",
            header: "Net Adj",
            sortable: true,
            isShow: true,
            body: (item) => (item?.net_adj ? `${Math.round(Number(item.net_adj)).toLocaleString()}` : "-"),
          },
          {
            field: "net_adj_prc",
            header: "Net Adj %",
            sortable: true,
            isShow: true,
            body: (item) => `${item.net_adj_prc ? item.net_adj_prc.toFixed(2) + "%" : "-"}`,
          },
          {
            field: "distance",
            header: "Distance",
            body: (item) => (item?.distance ? Number(item.distance).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "main_area",
            body: (item) => (item?.main_area ? Number(item.main_area).toLocaleString() : "-"),
            header: "Main Area",
            sortable: true,
            isShow: true,
          },
          {
            field: "land_total_area",
            body: (item) => (item?.land_total_area ? Number(item.land_total_area).toLocaleString() : "-"),
            header: "Total Area",
            sortable: true,
            isShow: true,
          },
          {
            field: "year_build",
            header: "Year Built",
            sortable: true,
            isShow: true,
          },
          {
            field: "desirability",
            header: "Desirability",
            sortable: true,
            isShow: true,
          },
          {
            field: "neighborhood_code",
            header: "Neighborhood Code",
            sortable: true,
            isShow: true,
          },
          {
            field: "certified_market_value",
            header: "Market Val",
            body: (item) =>
              item?.certified_market_value ? `$${Number(item.certified_market_value).toLocaleString()}` : "-",
            sortable: true,
            isShow: true,
          },
          {
            field: "class_code",
            header: "Property Class",
            sortable: true,
            isShow: true,
          },
          {
            field: "impr_value",
            header: "Impr Value",
            body: (item) => (item?.impr_value ? `$${Number(item.impr_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "impr_PSF",
            header: "Impr PSF",
            body: (item) => (item?.impr_PSF ? Number(item.impr_PSF).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "land_value",
            header: "Land Value",
            body: (item) => (item?.land_value ? `$${Number(item.land_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: false,
          },
          {
            field: "close_price",
            header: "Sale Price",
            body: (item) => (item?.close_price ? `$${Number(item.close_price).toLocaleString()}` : "-"),
            sortable: true,
            isShow: false,
          },
        ];
      } else if (payload === "fort bend") {
        state.tableData = [
          {
            field: "account_number",
            header: "Account Number",
            sortable: true,
            isShow: true,
          },
          {
            field: "street_address",
            header: "Situs",
            sortable: true,
            isShow: false,
            body: (item) => (
              <p className="whitespace-nowrap text-ellipsis overflow-hidden" title={item.street_address}>
                {item.street_address}
              </p>
            ),
          },
          {
            field: "adjusted_value",
            header: "Adjusted Value",
            body: (item) => (item?.adjusted_value ? `$${Number(item.adjusted_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "close_date",
            header: "Sale Date",
            sortable: true,
            isShow: false,
          },
          {
            field: "net_adj",
            header: "Net Adj",
            sortable: true,
            isShow: true,
            body: (item) => (item?.net_adj ? `${Math.round(Number(item.net_adj)).toLocaleString()}` : "-"),
          },
          {
            field: "net_adj_prc",
            header: "Net Adj %",
            sortable: true,
            isShow: true,
            body: (item) => `${item.net_adj_prc ? item.net_adj_prc.toFixed(2) + "%" : "-"}`,
          },
          {
            field: "distance",
            header: "Distance",
            body: (item) => (item?.distance ? Number(item.distance).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "main_area",
            body: (item) => (item?.main_area ? Number(item.main_area).toLocaleString() : "-"),
            header: "Main Area",
            sortable: true,
            isShow: true,
          },
          {
            field: "land_total_area",
            body: (item) => (item?.land_total_area ? Number(item.land_total_area).toLocaleString() : "-"),
            header: "Land Total Area",
            sortable: true,
            isShow: true,
          },
          {
            field: "year_build",
            header: "Year Built",
            sortable: true,
            isShow: true,
          },
          {
            field: "certified_market_value",
            header: "Market Val",
            body: (item) =>
              item?.certified_market_value ? `$${Number(item.certified_market_value).toLocaleString()}` : "-",
            sortable: true,
            isShow: true,
          },
          {
            field: "land_area",
            body: (item) => (item?.land_area ? Number(item.land_area).toLocaleString() : "-"),
            header: "Land SQFT",
            sortable: true,
            isShow: true,
          },
          {
            field: "impr_value",
            header: "Impr Value",
            body: (item) => (item?.impr_value ? `$${Number(item.impr_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "impr_PSF",
            header: "Impr PSF",
            body: (item) => (item?.impr_PSF ? Number(item.impr_PSF).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "land_value",
            header: "Land Value",
            body: (item) => (item?.land_value ? `$${Number(item.land_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: false,
          },
          {
            field: "close_price",
            header: "Sale Price",
            body: (item) => (item?.close_price ? `$${Number(item.close_price).toLocaleString()}` : "-"),
            sortable: true,
            isShow: false,
          },
        ];
      } else if (payload === "williamson") {
        state.tableData = [
          {
            field: "account_number",
            header: "Account Number",
            sortable: true,
            isShow: true,
          },
          {
            field: "adjusted_value",
            header: "Adjusted Value",
            body: (item) => (item?.adjusted_value ? `$${Number(item.adjusted_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "net_adj",
            header: "Net Adj",
            sortable: true,
            isShow: true,
            body: (item) => (item?.net_adj ? `${Math.round(Number(item.net_adj)).toLocaleString()}` : "-"),
          },
          {
            field: "net_adj_prc",
            header: "Net Adj %",
            sortable: true,
            isShow: true,
            body: (item) => `${item.net_adj_prc ? item.net_adj_prc.toFixed(2) + "%" : "-"}`,
          },
          {
            field: "distance",
            header: "Distance",
            body: (item) => (item?.distance ? Number(item.distance).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "main_area",
            body: (item) => (item?.main_area ? Number(item.main_area).toLocaleString() : "-"),
            header: "Main Area",
            sortable: true,
            isShow: true,
          },
          {
            field: "land_total_area",
            body: (item) => (item?.land_total_area ? Number(item.land_total_area).toLocaleString() : "-"),
            header: "Total Area",
            sortable: true,
            isShow: true,
          },
          {
            field: "year_build",
            header: "Year Built",
            sortable: true,
            isShow: true,
          },
          {
            field: "land_area",
            body: (item) => (item?.land_area ? Math.round(Number(item.land_area)).toLocaleString() : "-"),
            header: "Land SQFT",
            sortable: true,
            isShow: true,
          },
          {
            field: "close_price",
            header: "Sale Price",
            body: (item) => (item?.close_price ? `$${Number(item.close_price).toLocaleString()}` : "-"),
            sortable: true,
            isShow: false,
          },
          {
            field: "close_date",
            header: "Sale Date",
            sortable: true,
            isShow: false,
          },
          {
            field: "certified_market_value",
            header: "Market Val",
            body: (item) =>
              item?.certified_market_value ? `$${Number(item.certified_market_value).toLocaleString()}` : "-",
            sortable: true,
            isShow: true,
          },
          {
            field: "impr_PSF",
            header: "Impr PSF",
            body: (item) => (item?.impr_PSF ? Number(item.impr_PSF).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
        ];
      } else if (payload === "montgomery") {
        state.tableData = [
          {
            field: "account_number",
            header: "Account Number",
            sortable: true,
            isShow: true,
          },
          {
            field: "adjusted_value",
            header: "Adjusted Value",
            body: (item) => (item?.adjusted_value ? `$${Number(item.adjusted_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "net_adj",
            header: "Net Adj",
            sortable: true,
            isShow: true,
            body: (item) => (item?.net_adj ? `${Math.round(Number(item.net_adj)).toLocaleString()}` : "-"),
          },
          {
            field: "net_adj_prc",
            header: "Net Adj %",
            sortable: true,
            isShow: true,
            body: (item) => `${item.net_adj_prc ? item.net_adj_prc.toFixed(2) + "%" : "-"}`,
          },
          {
            field: "distance",
            header: "Distance",
            body: (item) => (item?.distance ? Number(item.distance).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "main_area",
            body: (item) => (item?.main_area ? Number(item.main_area).toLocaleString() : "-"),
            header: "Main Area",
            sortable: true,
            isShow: true,
          },
          {
            field: "land_total_area",
            body: (item) => (item?.land_total_area ? Number(item.land_total_area).toLocaleString() : "-"),
            header: "Total Area",
            sortable: true,
            isShow: true,
          },
          {
            field: "land_area",
            header: "Land SQFT",
            body: (item) => (item?.land_area ? Number(Number(item.land_area).toFixed(0)).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "year_build",
            header: "Year Built",
            sortable: true,
            isShow: true,
          },
          {
            field: "close_price",
            header: "Sale Price",
            body: (item) => (item?.close_price ? `$${Number(item.close_price).toLocaleString()}` : "-"),
            sortable: true,
            isShow: false,
          },
          {
            field: "close_date",
            header: "Sale Date",
            sortable: true,
            isShow: false,
          },
          {
            field: "certified_market_value",
            header: "Market Val",
            body: (item) =>
              item?.certified_market_value ? `$${Number(item.certified_market_value).toLocaleString()}` : "-",
            sortable: true,
            isShow: true,
          },
          {
            field: "impr_PSF",
            header: "Impr PSF",
            body: (item) => (item?.impr_PSF ? Number(item.impr_PSF).toFixed(2).toLocaleString() : "-"),
            sortable: true,
            isShow: true,
          },
          {
            field: "impr_value",
            header: "Impr Value",
            body: (item) => (item?.impr_value ? `$${Number(item.impr_value).toLocaleString()}` : "-"),
            sortable: true,
            isShow: false,
          },
        ];
      }
    },
    addFilters: (state, { payload }) => {
      state.filters = payload;
    },
    addCompFilters: (state, { payload }) => {
      state.compFilters = payload;
    },
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.compFilters = initialState.compFilters;
    },
    setFilteredBody: (state, { payload }) => {
      state.filteredBody = payload;
    },
    setSortData: (state, { payload }) => {
      state.sortData = payload;
    },
    setSavedFilters: (state, { payload }) => {
      state.savedFilters = payload;
    },
    setGetFiltersStatus: (state, { payload }) => {
      state.getFiltersStatus = payload;
    },
    updateColumnOrder: (state, { payload }) => {
      state.tableData = payload;
    },
  },
  extraReducers: ({ addCase }) => {
    // get columns info
    addCase(getColumnsInfo.pending, (state) => {
      state.getSortDataStatus = LOADING_STATUSES.pending;
    });
    addCase(getColumnsInfo.fulfilled, (state, { payload }) => {
      state.getSortDataStatus = LOADING_STATUSES.succeeded;
      if (!payload?.sequence) {
        return;
      }
      if (Array.isArray(payload.sequence)) {
        state.columnsInfo = payload.sequence;
      } else {
        state.columnsInfo = Object.values(payload.sequence);
      }
      const sortData = state.columnsInfo.find((col) => col.order_column);
      if (sortData) {
        state.sortData = {
          field: sortData.order_column,
          direction: +sortData.direction,
        };
      } else {
        state.sortData = {
          field: "",
          direction: -1,
        };
      }
    });
    addCase(getColumnsInfo.rejected, (state) => {
      state.getSortDataStatus = LOADING_STATUSES.failed;
    });

    // get evidence status
    addCase(getEvidenceStatus.pending, (state) => {
      state.getEvidanceStatusStatus = LOADING_STATUSES.pending;
    });
    addCase(getEvidenceStatus.fulfilled, (state, { payload }) => {
      state.status = payload.comparison_status;
      state.getEvidanceStatusStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getEvidenceStatus.rejected, (state) => {
      state.getEvidanceStatusStatus = LOADING_STATUSES.failed;
    });

    // get evidences equities
    addCase(getEvidencesEquities.pending, (state) => {
      state.getEvidencesEquitiesStatus = LOADING_STATUSES.pending;
    });
    addCase(getEvidencesEquities.fulfilled, (state, { payload }) => {
      state.equities = payload.data;

      // if (payload.data.length >= 1000) {
      //   toastify(TOAST_TYPES.warning, "The data is too big, we show only 1.000", null, 15000);
      // }
      state.getEvidencesEquitiesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getEvidencesEquities.rejected, (state) => {
      state.getEvidencesEquitiesStatus = LOADING_STATUSES.failed;
    });

    // get sales
    addCase(getEvidencesSales.pending, (state) => {
      state.getEvidencesSalesStatus = LOADING_STATUSES.pending;
    });
    addCase(getEvidencesSales.fulfilled, (state, { payload }) => {
      state.sales = payload.data;
      state.last_page_sales = payload?.meta?.total;
      state.getEvidencesSalesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getEvidencesSales.rejected, (state) => {
      state.getEvidencesSalesStatus = LOADING_STATUSES.pending;
    });

    // get saved comps
    addCase(getSaveComps.pending, (state) => {
      state.getSaveCompsStatus = LOADING_STATUSES.pending;
      state.savedComps = [];
    });
    addCase(getSaveComps.fulfilled, (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.savedComps = payload.map((item) => Number(item));
      }
      state.getSaveCompsStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getSaveComps.rejected, (state) => {
      state.getSaveCompsStatus = LOADING_STATUSES.failed;
    });

    // get left bar values
    addCase(getLeftBarValues.pending, (state) => {
      state.getLeftBarValuesStatus = LOADING_STATUSES.pending;
    });
    addCase(getLeftBarValues.fulfilled, (state, { payload }) => {
      state.getLeftBarValuesStatus = LOADING_STATUSES.succeeded;
      state.leftBarValues = payload.data;
    });
    addCase(getLeftBarValues.rejected, (state) => {
      state.getLeftBarValuesStatus = LOADING_STATUSES.failed;
    });

    // get left bar values
    addCase(saveColumnsInfo.pending, (state) => {
      state.saveColumnsInfoStatus = LOADING_STATUSES.pending;
    });
    addCase(saveColumnsInfo.fulfilled, (state) => {
      state.saveColumnsInfoStatus = LOADING_STATUSES.succeeded;
      toastify(TOAST_TYPES.success, "Changes are saved successfully");
    });
    addCase(saveColumnsInfo.rejected, (state) => {
      state.saveColumnsInfoStatus = LOADING_STATUSES.failed;
    });

    // reset comps
    addCase(resetComps.pending, (state) => {
      state.resetCompsStatus = LOADING_STATUSES.pending;
    });
    addCase(resetComps.fulfilled, (state) => {
      state.resetCompsStatus = LOADING_STATUSES.succeeded;
      toastify(TOAST_TYPES.success, "Filters are reseted successfully");
    });
    addCase(resetComps.rejected, (state) => {
      state.resetCompsStatus = LOADING_STATUSES.failed;
    });

    // save comps
    addCase(saveComps.pending, (state) => {
      state.saveCompsStatus = LOADING_STATUSES.pending;
    });
    addCase(saveComps.fulfilled, (state) => {
      state.saveCompsStatus = LOADING_STATUSES.succeeded;
    });
    addCase(saveComps.rejected, (state) => {
      state.saveCompsStatus = LOADING_STATUSES.failed;
    });

    // export csv
    addCase(exportCompsCsv.pending, (state) => {
      state.exportCompsCsvStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCompsCsv.fulfilled, (state, { payload }) => {
      state.exportCompsCsvStatus = LOADING_STATUSES.succeeded;
      state.downloadCompsCsvHref = payload;
    });
    addCase(exportCompsCsv.rejected, (state) => {
      state.exportCompsCsvStatus = LOADING_STATUSES.failed;
    });

    // get subject property
    addCase(getSubjectProperty.fulfilled, (state, { payload }) => {
      state.subjectProperty = Object.fromEntries(
        Object.entries(payload.data).map(([key, value]) => [
          key,
          typeof value === "number" && !Number.isInteger(value) ? parseFloat(value.toFixed(2)) : value,
        ])
      );

      state.subjectProperty.comp_index = 0;

      if (state.selects && state.subjectProperty) {
        state.selects.forEach((select) => {
          const key = select.name;
          if (state.subjectProperty[key]) {
            select.subjectValue = state.subjectProperty[key];
          }
        });
      }

      state.getSubjectPropertyStatus = LOADING_STATUSES.succeeded;
    });

    // get comps filters
    addCase(getCompsFilters.pending, (state) => {
      state.getCompsFiltersStatus = LOADING_STATUSES.pending;
    });
    addCase(getCompsFilters.fulfilled, (state, { payload }) => {
      const categorizedData = Object.entries(payload.data).reduce(
        (acc, [key, item]) => {
          const newItem = { ...item, description: key };
          switch (item.type) {
            case "select":
              acc.selects.push(newItem);
              break;
            case "float":
              acc.floats.push(newItem);
              break;
            case "range":
              acc.ranges.push(newItem);
              break;
            case "date":
              acc.dates.push(newItem);
              break;
            default:
              break;
          }
          return acc;
        },
        { selects: [], floats: [], ranges: [], dates: [] }
      );

      state.selects = categorizedData.selects;
      state.floats = categorizedData.floats;
      state.ranges = categorizedData.ranges;
      state.dates = categorizedData.dates;

      state.getCompsFiltersStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getCompsFilters.rejected, (state) => {
      state.getCompsFiltersStatus = LOADING_STATUSES.failed;
    });

    // save filters
    addCase(saveFilters.pending, (state) => {
      state.saveFiltersStatus = LOADING_STATUSES.pending;
    });
    addCase(saveFilters.fulfilled, (state) => {
      state.saveFiltersStatus = LOADING_STATUSES.succeeded;
    });
    addCase(saveFilters.rejected, (state) => {
      state.saveFiltersStatus = LOADING_STATUSES.failed;
    });

    // reset filters
    addCase(resetFilters.pending, (state) => {
      state.resetFiltersStatus = LOADING_STATUSES.pending;
    });
    addCase(resetFilters.fulfilled, (state) => {
      state.resetFiltersStatus = LOADING_STATUSES.succeeded;

      const floats = {};

      state.floats.forEach((item) => {
        floats[item.description] = null;
      });

      state.savedFilters = { ...state.savedFilters, ...floats };
    });
    addCase(resetFilters.rejected, (state) => {
      state.resetFiltersStatus = LOADING_STATUSES.failed;
    });

    // get filters
    addCase(getFilters.pending, (state) => {
      state.getFiltersStatus = LOADING_STATUSES.pending;

      state.savedFilters = null;
    });
    addCase(getFilters.fulfilled, (state, { payload }) => {
      const filters = payload?.filters;

      if (Array.isArray(filters)) {
        if (filters.length === 0) {
          state.hasNotFilters = true;
        }
      } else if (typeof filters === "object" && filters !== null) {
        if (Object.keys(filters).length === 0) {
          state.hasNotFilters = true;
        }
      } else {
        state.hasNotFilters = true;
      }

      state.savedFilters = filters;
      state.getFiltersStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getFilters.rejected, (state) => {
      state.getFiltersStatus = LOADING_STATUSES.failed;
    });
  },
});

export const singleEvidenceReducer = singleEvidenceSlice.reducer;

export const singleEvidenceActions = {
  ...singleEvidenceSlice.actions,
  exportCompsCsv,
  getEvidenceStatus,
  getEvidencesSales,
  getLeftBarValues,
  getSaveComps,
  resetComps,
  saveComps,
  getCompsFilters,
  getEvidencesEquities,
  getSubjectProperty,
  saveFilters,
  resetFilters,
  getFilters,
  setEvidenceStatus,
  getColumnsInfo,
  saveColumnsInfo,
};
