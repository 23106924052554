import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Font, pdf, PDFDownloadLink } from "@react-pdf/renderer";
// react pdf font register
import SerifRegular from "assets/fonst/NotoSerif-Regular.ttf";
import RobotoBold from "assets/fonst/Roboto-Bold.ttf";
import RobotoLight from "assets/fonst/Roboto-Light.ttf";
import RobotoMedium from "assets/fonst/Roboto-Medium.ttf";
import RobotoRegular from "assets/fonst/Roboto-Regular.ttf";
import { Loader, PrimaryButton } from "common";
import { clientRoutes } from "router/routes";
import { getSingleProperty } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { ownerPortalPropertySelector } from "store/owner-portal-property-slice/selectors";
import { ownerPortalPropertyActions } from "store/owner-portal-property-slice/slice";
import { isLoading, LOADING_STATUSES } from "utils/constants";
import { generateDocSignUrl } from "utils/docusign";
import { getImageFromTypedSignature, setStorage, toggleClassOnRoot } from "utils/helpers";

import { AoADocument, DownloadAoADocumentPreview, SADocument } from "components/Documents";
import { Properties } from "components/Properties";
import { Signature } from "components/Signature";
import { ViewPdfModal } from "components/ViwePdfModal";

import * as S from "./styled";
import { SuccessModal } from "./SuccessModal";

Font.register({
  family: "Serif",
  fonts: [{ src: SerifRegular, fontWeight: 400 }],
});

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoMedium, fontWeight: "medium" },
    { src: RobotoLight, fontWeight: "light" },
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoBold, fontWeight: "bold" },
  ],
});

export const OwnerPortalRenewDocumentsContent = ({ property, account, propertyId }) => {
  const { signature, completeAddPropertyStatus, signType, isOpenSuccessModal, signatureErr } =
    useSelector(ownerPortalPropertySelector);

  const [isOpenViewPdfModal, setIsOpenViewPdfModal] = useState(false);

  const owner_id = account?.id;

  const typedSignRef = useRef(null);

  const firstProp = property && property.length > 0 ? property[0] : null;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isRedirectToDocusign = () => {
    return property[0].county.toLowerCase() === "collin" || property[0].county.toLowerCase() === "harris";
  };

  const redirectToDocusign = () => {
    setStorage("owner_data", { email: account.email });
    const url = generateDocSignUrl({ ...account, properties: property });
    window.location.href = url;
  };

  const toggleModal = () => {
    document.getElementById("root").classList.toggle("prevent-scroll-desktop");
    setIsOpenViewPdfModal((prev) => !prev);
  };

  const handleBackClick = () => {
    navigate(`${clientRoutes.prefix}${clientRoutes.properties}?id=${propertyId}&should-scroll=true`);
  };

  const onSubmit = async () => {
    if (!signature || !signature.trim().length) {
      dispatch(ownerPortalPropertyActions.setSignatureErr());
      return;
    }

    const formData = new FormData();
    if (signType) {
      const transformedSignature = await getImageFromTypedSignature({ signature, signType, typedSignRef });

      const docs_sa = new File([await pdf(SADocument({ owner: account, signature: transformedSignature, signType })).toBlob()], "sa.pdf");
      const docs_aoa = new File(
        [await pdf(AoADocument({ owner: account, properties: property, signature: transformedSignature, signType })).toBlob()],
        "aoa.pdf"
      );
      formData.append("docs_sa", docs_sa);
      formData.append("docs_aoa", docs_aoa);
      formData.append("owner_id", owner_id);
      formData.append("send_email", 0);
      formData.append("is_user_generated", 1);
      formData.append("signature_data_url", transformedSignature);
    }

    dispatch(ownerPortalPropertyActions.completeAddProperty(formData));
  };

  useEffect(() => {
    if (completeAddPropertyStatus === LOADING_STATUSES.succeeded) {
      toggleClassOnRoot();
      dispatch(ownerPortalPropertyActions.setIsOpenSuccessModal(true));
    }
  }, [completeAddPropertyStatus]);

  useEffect(() => {
    if (signature && signatureErr) {
      dispatch(ownerPortalPropertyActions.deleteSignatureErr());
    }
  }, [signature, signatureErr]);

  useEffect(() => {
    return () => dispatch(ownerPortalPropertyActions.resetAllData());
  }, []);

  return (
    <S.Container>
      <S.RenewDocumentWrapper>
        <S.Box className="p-3" />
        <S.RenewDocumentHeader>
          <S.UserInfoContainer>
            <S.InfoBlock className="pb-8">
              <S.Box className="pb-3 text-[green]">First Name</S.Box>
              <S.Box>{account?.first_name}</S.Box>
            </S.InfoBlock>
            <S.InfoBlock className=" pb-8">
              <S.Box className="pb-3 text-[green]">Last Name</S.Box>
              <S.Box>{account?.last_name}</S.Box>
            </S.InfoBlock>
            <S.InfoBlock className=" pb-8">
              <S.Box className="pb-3 text-[green]">Phone</S.Box>
              <S.Box>{account?.phone_number}</S.Box>
            </S.InfoBlock>
          </S.UserInfoContainer>
          <S.Block>
            <S.Box>
              <S.Box className="pb-3 text-[green]">List of properties</S.Box>
              <S.Box>{property ? <Properties properties={property} /> : null}</S.Box>
            </S.Box>
          </S.Block>
        </S.RenewDocumentHeader>
        <S.Box>
          <S.SubTitle>Registration Document Overview:</S.SubTitle>
          <S.Ul className="mb-10">
            <S.Li>
              Appointment of Agent form, which authorizes us to protest your proposed market value to the Appraisal
              Review Board.
            </S.Li>
            <S.Li>
              Service Agreement, which outlines our services and fees. We charge 1% of the market value reduction we
              achieve on your protest. If there's no reduction, there's no fee.
            </S.Li>
          </S.Ul>
        </S.Box>
        {!isRedirectToDocusign() ? (
          <>
            <Signature
              ref={typedSignRef}
              deleteSignature={() => dispatch(ownerPortalPropertyActions.deleteSignature())}
              setSignature={(signature) => dispatch(ownerPortalPropertyActions.setSignature({ signature }))}
              setSignType={(type) => dispatch(ownerPortalPropertyActions.setSignType(type))}
              signatureErr={signatureErr}
              signType={signType}
            />
            <S.DownloadDoc>
              <DownloadAoADocumentPreview
                ref={typedSignRef}
                owner={account}
                properties={property}
                signature={signature}
                signType={signType} />
            </S.DownloadDoc>
            <S.ViewDoc>
              <S.preview onClick={toggleModal}>Preview Documents</S.preview>
            </S.ViewDoc>
          </>
        ) : null}
        <S.ButtonsContainer>
          {isRedirectToDocusign() ? (
            <PrimaryButton eventHandler={redirectToDocusign} label="complete" />
          ) : (
            <PrimaryButton
              disabled={isRedirectToDocusign() ? !signature : null}
              eventHandler={onSubmit}
              label="complete"
              loading={isLoading(completeAddPropertyStatus)}
            />
          )}
          <PrimaryButton eventHandler={handleBackClick} label="Back" mode="gray" />
        </S.ButtonsContainer>

        {isOpenViewPdfModal ? (
          <ViewPdfModal
            ref={typedSignRef}
            firstProp={firstProp}
            ownerData={account}
            properties={property}
            signature={signature}
            signType={signType}
            toggleModal={toggleModal}
          />
        ) : null}
        {isOpenSuccessModal && <SuccessModal id={propertyId} />}
      </S.RenewDocumentWrapper>
    </S.Container>
  );
};

export const OwnerPortalRenewDocuments = () => {
  const { property, account } = useSelector(clientSelectors);

  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!property || property.id !== +id) {
      dispatch(getSingleProperty(id));
    }
  }, [id, property]);

  if (!account || !property || property.id !== +id) {
    return (
      <S.LoaderBox>
        <Loader size={80} />
      </S.LoaderBox>
    );
  }

  return <OwnerPortalRenewDocumentsContent account={account} property={[property]} propertyId={id} />;
};
