import { NavLink } from "react-router-dom";
import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { ContentHeader, SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import styled from "styled-components";

export const ReferralContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Container = styled.div`
  margin-top: 24px;

  svg {
    cursor: pointer;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled(SinglePageContentShadow)`
  background-color: ${COLORS.overlay_060};
`;

export const ContentTitle = styled(ContentHeader)``;

export const Box = styled.div`
  color: ${COLORS.green};

  &.red {
    color: ${COLORS.red};
  }
`;

export const FooterContainer = styled.div`
  color: ${COLORS.green};
  display: flex;
  justify-content: space-between;
  font-family: Montserrat, serif;
`;

export const FooterTitle = styled.div`
  margin-left: 8%;
`;

export const PercentTotal = styled.div`
  margin-right: 4%;
`;

export const FilterBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const FilterButtons = styled.div`
  display: flex;
  gap: 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const NoResult = styled.div`
  padding: 8px;
`;

export const ReferralTitle = styled.h1`
  font-size: 16px;
  font-weight: 600;
`;

export const Link = styled(NavLink)`
  display: inline;
  font-size: 14px;

  &:hover {
    color: ${COLORS.green};
    font-weight: 600;
    text-decoration: underline;
  }
`;

export const ReferralsContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 6px;

  @media screen and (${BREAKPOINTS.lg}) {
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }
`;

export const InviteContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ReferralForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  width: 300px;
`;

export const SuccessMessageBlock = styled.div`
  color: ${COLORS.success};
`;

export const InviteParagraph = styled.p`
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
`;

export const LoaderBox = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
`;
