import { useDispatch } from "react-redux";
import { Icons } from "assets/svgs";
import { singleEvidenceActions } from "store/admin-slices/single-evidence-slice/slice";

import * as S from "./styled";

export const SettingsModal = () => {
  const dispatch = useDispatch();

  const closeSettingsModal = () => {
    dispatch(singleEvidenceActions.closeSettingsModal());
  };

  return (
    <S.Container>
      <S.Content>
        <S.IconBlock>
          <Icons.CloseIcon onClick={closeSettingsModal} />
        </S.IconBlock>
      </S.Content>
    </S.Container>
  );
};
