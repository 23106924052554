import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const PropertyLink = styled.div`
  display: block;
  width: 100%;
`;

export const PropertyText = styled.div`
  width: 100%;
  text-align: start;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const PropertyContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  background: ${COLORS.overlay_060};
  padding: 10px;
  color: ${COLORS.deepDark};
  border-radius: 10px;
  transition-duration: 0.28s;
  width: 100%;
  cursor: pointer;

  &:hover {
    box-shadow: 0 5px 14px 0 ${COLORS.green};
  }
`;

export const PropertyIconWrapper = styled.div`
  width: 32px;
  height: 32px;
`;
