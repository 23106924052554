import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "common";
import { ownerPortalPropertySelector } from "store/owner-portal-property-slice/selectors";
import { ownerPortalPropertyActions } from "store/owner-portal-property-slice/slice";

import * as S from "./styled";

export const AddPropertyAcceptModal = () => {
  const { isOpenAcceptModal, deletePropertyId, ownerData } = useSelector(ownerPortalPropertySelector);

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(ownerPortalPropertyActions.toggleAcceptModal());
  };
  const handleDeleteItem = () => {
    const body = { owner_id: ownerData?.id, property_id: deletePropertyId };
    dispatch(ownerPortalPropertyActions.deleteProperty());
    dispatch(ownerPortalPropertyActions.deletePropertyLeads(body));
    handleCloseModal();
  };

  return (
    <S.Container className={`${isOpenAcceptModal ? "active" : ""}`}>
      <S.Content className={`${isOpenAcceptModal ? "active" : ""}`}>
        <S.Question>Are you sure you want to remove this property?</S.Question>
        <PrimaryButton eventHandler={handleDeleteItem} label="yes" />
        <PrimaryButton className="no" eventHandler={handleCloseModal} label="no" />
      </S.Content>
    </S.Container>
  );
};
