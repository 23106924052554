import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icons } from "assets/svgs";
import { Loader } from "common";
import { clientRoutes } from "router/routes";
import { deleteNotification, getNotifications, markNotificationAsRead } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { LOADING_STATUSES, TOAST_TYPES } from "utils/constants";
import { toastify } from "utils/helpers";

import * as S from "./styled";

export const NotificationSidebar = ({ showSidebar, onClose }) => {
  const {
    notifications: initialNotifications,
    markNotificationAsReadStatus,
    properties,
    deleteNotificationStatus,
  } = useSelector(clientSelectors);

  const [notifications, setNotifications] = useState([]);
  const [loadingNotificationIds, setLoadingNotificationIds] = useState([]);

  const sidebarRef = useRef(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleDeleteNotification = (id) => {
    setLoadingNotificationIds((prevIds) => [...prevIds, id]);
    dispatch(deleteNotification(id)).then(() => {
      setLoadingNotificationIds((prevIds) => prevIds.filter((loadingId) => loadingId !== id));
    });
  };

  const handleNotificationLink = (notification) => {
    if (notification.data?.invoice_id) {
      navigate(`${clientRoutes.prefix}${clientRoutes.invoices}`, {
        state: { selectedInvoiceId: notification.data.invoice_id },
      });
    } 
    
    if (notification.data?.property_id) {
      const propertyExists = properties.some((property) => property.id === notification.data.property_id);
      if (propertyExists) {
        navigate(`${clientRoutes.prefix}${clientRoutes.properties}?id=${notification.data.property_id}&should-scroll=true`);
      } else {
        toastify(TOAST_TYPES.error, "This property has been deleted.", "custom-toast");
        navigate(`${clientRoutes.prefix}${clientRoutes.properties}`);
      }
    }
    onClose();
  };

  useEffect(() => {
    if (markNotificationAsReadStatus === LOADING_STATUSES.succeeded) {
      dispatch(getNotifications());
    }
  }, [markNotificationAsReadStatus]);

  useEffect(() => {
    if (showSidebar) {
      const sortedNotifications = [...initialNotifications].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setNotifications(sortedNotifications);

      const unreadNotificationIds = sortedNotifications.filter((notif) => !notif.read_at).map((notif) => notif.id);

      if (unreadNotificationIds.length > 0) {
        dispatch(markNotificationAsRead({ notification_ids: unreadNotificationIds }));
      }
    }
  }, [initialNotifications, showSidebar]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (showSidebar) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.classList.add("prevent-scroll");
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.classList.remove("prevent-scroll");
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSidebar, onClose]);

  useEffect(() => {
    dispatch(getNotifications());
  }, [deleteNotificationStatus]);

  return (
    <S.SidebarOverlay show={showSidebar ? "true" : "false"}>
      <S.NotificationContainer ref={sidebarRef} show={showSidebar ? "true" : "false"}>
        <S.CloseButton onClick={onClose}>
          <Icons.CloseIcon />
        </S.CloseButton>
        <S.SidebarContent>
          <S.Title>Notifications</S.Title>
          <S.Notifications>
            {notifications.map((notification) => (
              <S.NotificationContent
                key={notification.id}
                read={notification.read_at !== null ? "true" : "false"}
                onClick={() => handleNotificationLink(notification)}
              >
                {notification.data.message}
                {loadingNotificationIds.includes(notification.id) ? (
                  <Loader />
                ) : (
                  <Icons.CloseIcon
                    className="close-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteNotification(notification.id);
                    }}
                  />
                )}
              </S.NotificationContent>
            ))}
          </S.Notifications>
        </S.SidebarContent>
      </S.NotificationContainer>
    </S.SidebarOverlay>
  );
};
