import axios from "axios";
import { API } from "configs/api";
import { defaultBody } from "configs/service";

export const adminSignInApi = {
  signIn: (body) => axios.post(API.adminSignIn, { ...body, ...defaultBody }),
  getUserInfo: () => axios.get(API.usersMe, { token: true }),
  getNotifications: () => axios.get(API.getNotifications, { token: true }),
  deleteNotification: (id) => axios.delete(`${API.getNotifications}/${id}`, { token: true }),
  readAllNotifications: (body) => axios.put(API.readAllNotifications, body, { token: true }),
};
