import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Can } from "@casl/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { COLORS } from "assets/styles/colors";
import { Icons } from "assets/svgs";
import { Loader, PrimaryButton, PrimaryInput } from "common";
import { usePermissions } from "hooks/usePermissions";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";
import { isLoading, LOADING_STATUSES, PERMISSIONS_LIST } from "utils/constants";
import { documentSchema } from "validations";

import * as S from "./styled";

export const Document = ({ doc, email, leads, agentCode }) => {
  const { updateDocumentStatus, deleteDocumentStatus, sendAoAStatus, singleOwner, documentSelectedId } =
    useSelector(singleOwnerSelectors);
  const { userInfo } = useSelector(adminSignInSelectors);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(documentSchema),
  });

  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [filename, setFilename] = useState(doc?.aoa?.filename);
  const [sendAoABody, setSendAoABody] = useState({});

  const { selectedPropertyId } = useParams();

  const dispatch = useDispatch();

  const ability = usePermissions(userInfo.permissions);

  const isPending = (status) => isLoading(status) && documentSelectedId === doc.id;

  const isPendingUpdate = isPending(updateDocumentStatus);

  const isPendingDelete = isPending(deleteDocumentStatus);

  const isPendingAoA = isPending(sendAoAStatus);

  const handleUpdateDocument = () => {
    dispatch(singleOwnerActions.setDocumentSelectedId({ id: doc.id }));

    if (isUpdateMode) {
      dispatch(singleOwnerActions.updateDocument({ id: doc.id, filename: watch().document }));
      setFilename(watch().document);
      setIsUpdateMode(false);
    } else {
      setIsUpdateMode(true);
    }
  };

  const handleOpenDeleteDocumentModal = () => {
    dispatch(singleOwnerActions.setDocumentId(doc.id));
    dispatch(singleOwnerActions.setIsDeleteDocumentModal(true));
  };

  const handleSendAoAClick = () => {
    dispatch(singleOwnerActions.setDocumentSelectedId({ id: doc.id }));
    dispatch(singleOwnerActions.sendAoA(sendAoABody));
  };

  useEffect(() => {
    if (deleteDocumentStatus === LOADING_STATUSES.succeeded) {
      dispatch(singleOwnerActions.setIsDeleteDocumentModal(false));
    }
  }, [deleteDocumentStatus]);

  useEffect(() => {
    const selectedProperty = singleOwner.properties.find((prop) => prop.id === Number(selectedPropertyId));
    setSendAoABody({
      doc_id: doc.id,
      email,
      account_number: selectedProperty?.account_number || "",
      owner_id: singleOwner.id,
      county: selectedProperty?.county,
      agent_code: agentCode,
    });
  }, [selectedPropertyId, email, agentCode]);

  useEffect(() => {
    setValue("document", filename);
  }, [filename]);

  return (
    <S.Document>
      {isPendingUpdate ? (
        <S.LinkWrapper>
          <Loader />
        </S.LinkWrapper>
      ) : (
        <>
          <S.LinkWrapper>
            <S.Text>View AoA</S.Text> -
            {isUpdateMode ? (
              <PrimaryInput
                className="change-filename-input"
                error={errors.document}
                register={register("document")}
                wrapperClassName="w-full"
              />
            ) : (
              <S.Link href={doc?.aoa?.docs_aoa} target="_blank">
                {filename}
              </S.Link>
            )}
            {isUpdateMode ? (
              <Icons.CheckIcon color={COLORS.green} onClick={handleSubmit(handleUpdateDocument)} />
            ) : (
              <Icons.EditIcon onClick={handleUpdateDocument} />
            )}
          </S.LinkWrapper>
        </>
      )}
      {doc.last_sent_to_county ? (
        <S.Text className="success center">{doc.last_sent_to_county}</S.Text>
      ) : (
        <S.Text className="red">Not Sent</S.Text>
      )}
      <S.Options>
        <Can ability={ability} I={PERMISSIONS_LIST.sendAoa}>
          <PrimaryButton eventHandler={handleSendAoAClick} label="send aoa" loading={isPendingAoA} mode="gray" />
        </Can>
        {!leads && <>{isPendingDelete ? <Loader /> : <Icons.TrashIcon onClick={handleOpenDeleteDocumentModal} />}</>}
      </S.Options>
    </S.Document>
  );
};
