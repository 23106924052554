import { ContentHeader, SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const Content = styled(SinglePageContentShadow)``;

export const ContentTitle = styled(ContentHeader)``;

export const Form = styled.form``;
