import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "common";
import { getReferredBy } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { isLoading } from "utils/constants";

import { Footer } from "./Footer";
import * as S from "./styled";

export const ReferredBy = () => {
  const { referredBy, getReferredByStatus, account } = useSelector(clientSelectors);

  const dispatch = useDispatch();

  const tableData = [
    { field: "full_name", header: "Name" },
    { field: "email", header: "Email" },
    { field: "referral_code", header: "Code" },
    { header: "Date", field: "updated_at" },
    {
      header: "Percent",
      body: (item) => (item ? "10%" : ""),
    },
  ];

  useEffect(() => {
    if (account) {
      dispatch(getReferredBy(account?.id));
    }
  }, [account]);

  return (
    <S.Container>
      <S.Content>
        <S.FilterBar>
          <S.ContentTitle>Referred by</S.ContentTitle>
        </S.FilterBar>
        <Table
          dataKey="id"
          footer={<Footer referralsLength={referredBy ? 1 : null} />}
          loading={isLoading(getReferredByStatus)}
          tableData={tableData}
          value={[referredBy]}
        />
      </S.Content>
    </S.Container>
  );
};
