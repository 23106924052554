import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components";

export const SignatureContainer = styled.div`
  margin-block: 31px;
`;

export const SignatureBlockTitle = styled.div`
  margin-bottom: 14px;
`;

export const SignatureHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const SignatureHeaderItem = styled.div`
  padding: 9px 26px;
  border-right: 1px solid ${COLORS.darkgrey};
  border-bottom-right-radius: 4px;
  cursor: pointer;

  &.selected {
    border-color: ${COLORS.green};
    color: ${COLORS.green};
    font-weight: 700;
  }

  &.error {
    color: ${COLORS.red};
    border-color: ${COLORS.red};
  }
`;

export const SignatureContent = styled.div`
  border: 1px solid ${COLORS.green};
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  gap: 8px;

  @media screen and (${BREAKPOINTS.md}) {
    padding: 8px;
  }

  &.error {
    border-color: ${COLORS.red};
  }

  .sigCanvas {
    width: 100%;
    height: 200px;
    border: 1px dotted ${COLORS.overlay_200};
  }
`;

export const SignatureTypeContent = styled.div`
  width: 100%;
  min-height: 240px;
`;

export const SignatureInputContent = styled.div`
  display: flex;
  flex-direction: column;

  div {
    flex-grow: 1;
  }

  .signature-input {
    & > div {
      min-width: unset !important;
    }
  }

  @media screen and (${BREAKPOINTS.md}) {
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    gap: 33px;
    width: 100%;
  }
`;

export const TypedSignature = styled.div`
  font-family: "Italianno", cursive;
  height: 140px;
  font-weight: bold;
  width: 100%;
  margin-block: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    font-size: 40px;
  }
`;

export const Box = styled.div`
  background-color: ${COLORS.white};
`;
