import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader, PrimaryAccordion, PrimaryButton } from "common";
import { clientRoutes } from "router/routes";
import { getProperties } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { ownerPortalPropertyActions } from "store/owner-portal-property-slice/slice";
import { isLoading } from "utils/constants";

import { PropertyWidget } from "components/Client/PropertyWidget";

import { SingleProperty } from "./SingleProperty";
import * as S from "./styled";

export const PropertiesPage = () => {
  const { getPropertiesStatus, properties } = useSelector(clientSelectors);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const propertiesRefs = useRef({});
  const isFirstMountScrolled = useRef(false);

  const openedId = +searchParams.get("id");
  const shouldScroll = searchParams.get("should-scroll") || !isFirstMountScrolled.current;

  useEffect(() => {
    const node = propertiesRefs.current[openedId];
    
    if (node && shouldScroll) {
      setTimeout(() => {
        window.scrollTo({
          behavior: "smooth",
          top:
            node.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            50
        });
      }, 0);
      isFirstMountScrolled.current = true;
    }
  }, [getPropertiesStatus, openedId, shouldScroll]);

  const handleAddProperty = () => {
    navigate(`${clientRoutes.prefix}${clientRoutes.addProperty}`);
  };

  const handleNavigateToSubmitEvidence = (id) => {
    navigate(`${clientRoutes.prefix}${clientRoutes.submitEvidence}/${id}`);
  };

  useEffect(() => {
    dispatch(getProperties());
  }, []);

  useEffect(() => {
    if (openedId) {
      dispatch(ownerPortalPropertyActions.authedOrNot(openedId));
    }
  }, [openedId]);

  const handleToggleAccordion = (id) => {
    setSearchParams(params => {
      if (id === openedId) {
        params.delete("id");
      } else {
        params.set("id", id);
      }
      params.delete("should-scroll");
      return params;
    });
  };

  if (isLoading(getPropertiesStatus)) {
    return (
      <S.LoaderBox>
        <Loader size={80} />
      </S.LoaderBox>
    );
  }

  return (
    <S.PropertiesScrollBlock>
      <S.PropertiesButtonBlock>
        <PrimaryButton className="add-another-property" eventHandler={handleAddProperty} label="ADD ANOTHER PROPERTY" />
      </S.PropertiesButtonBlock>
      <S.PropertiesLinkWrapper>
        {properties?.map((property) => {
          const isOpen = openedId === property.id;
          return <S.AccordionWrapper key={property.id} ref={ref => propertiesRefs.current[property.id] = ref}>
            <PrimaryAccordion
              bodyClassName="px-4 md:px-10 border border-blue-gray-50 rounded-xl"
              bodyContent={<SingleProperty id={property.id} isOpen={isOpen} />}
              headerClassName="pt-0 border-none"
              headerContent={
                <S.PropertyWidgetWrapper key={property.id}>
                  <PropertyWidget property={property}>
                    <PrimaryButton
                      className="submit-evidence"
                      eventHandler={(e) => {
                        e.stopPropagation();
                        handleNavigateToSubmitEvidence(property.id);
                      }}
                      label="Submit evidence"
                    />
                  </PropertyWidget>
                </S.PropertyWidgetWrapper>
              }
              isOpen={isOpen}
              onToggle={() => handleToggleAccordion(property.id)}
            />
          </S.AccordionWrapper>;
        })}
      </S.PropertiesLinkWrapper>
    </S.PropertiesScrollBlock>
  );
};
